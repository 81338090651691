import React from "react";
import { Form } from "antd";
import Select from "react-select";

export const FormSelect = ({ formName, required, options, label, defaultValue, customOnChange, isClearable }) => {
  return (
    <div style={{ margin: "10px 0px " }}>
      <Form.Item
        initialValue={defaultValue && defaultValue}
        name={formName}
        label={label}
        rules={required && [{ required: required, message: "The field is required" }]}
      >
        <Select
          isClearable={isClearable}
          onChange={customOnChange && customOnChange}
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          defaultValue={defaultValue}
          placeholder={label}
          options={options}
        />
      </Form.Item>
    </div>
  );
};

export default FormSelect;
