import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Button, Tabs } from "antd";
import { Loader, ButtonWithModal } from "components";
import moment from "moment";
import { actions as prospectProfileActions } from "modules/prospectProfile";
import {
  getDashboardProspects,
  getWebsiteLeads,
  postWebsiteLead,
  getWebsiteLeadWithStatus,
} from "services/dashboardProspects";
import { Link } from "react-router-dom";
import { UserOutlined, MailOutlined, MobileOutlined } from "@ant-design/icons";
import { sortStrings, sortDates, dateTimeFormat, withNumberingColumn } from "utils/utils";
import useRoles from "hooks/useRoles";
import { ColorfulTag } from "components";
import "./prospects.scss";

export const Prospects = () => {
  const dispatch = useDispatch();
  const { isTeacher, isStaff, isFranchisee } = useRoles();
  const [prospects, setProspects] = useState([]);
  const [websiteLeads, setWebsiteLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leadsArchived, setLeadsArchived] = useState([]);
  const [leadId, setLeadId] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const todayLessons = prospects?.trial_lessons?.today_lessons || [];
  const afterTodayLessons = prospects?.trial_lessons?.after_today_lessons || [];
  const existStudentToday = prospects?.trial_existing_student?.today_lessons || [];
  const existStudentAfterToday = prospects?.trial_existing_student?.after_today_lessons || [];

  const [paginationWithoutTrial, setPaginationWithoutTrial] = useState(null);
  const [paginationFollowupWith, setPaginationFollowupWith] = useState(null);
  const [paginationInTrial, setPaginationInTrial] = useState(null);
  const [paginationInTrialToday, setPaginationInTrialToday] = useState(null);
  const [paginExistStudentTrials, setPaginExistStudentTrials] = useState(null);
  const [paginExistStudentTrialsToday, setpaginExistStudentTrialsToday] = useState(null);
  const [leadsPagination, setLeadsPagination] = useState(null);
  const [leadsPaginationArchived, setLeadsPaginationArchived] = useState(null);

  const todayAndAfterTodayLessons = afterTodayLessons.concat(todayLessons);
  const todayAndAfterTodayExistStudents = existStudentAfterToday.concat(existStudentToday);

  const { TabPane } = Tabs;

  const handleClick = (leadid, status) => {
    postWebsiteLead(leadid, status);
    setLeadId(leadid);
  };

  useEffect(() => {
    setLoading(true);

    getWebsiteLeads().then(({ data }) => {
      setWebsiteLeads(data);
    });
    getWebsiteLeadWithStatus("archive").then(({ data }) => {
      setLeadsArchived(data);
    });

    getDashboardProspects().then(({ data }) => {
      setProspects(data);
    });
    setLoading(false);
    // eslint-disable-next-line
  }, [leadId]);

  const schoolId = useSelector((state) => state.user.selectedSchool.id);

  const prospectsColumns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => sortStrings(a, b, "first_name"),
      render: (firstName, { last_name, nid }) => {
        return (
          <p>
            <Link
              to={`/dashboard/${schoolId}/prospectProfile/${nid}`}
              onClick={() => dispatch(prospectProfileActions.getProspectProfile(nid))}
            >
              {firstName} {last_name}
            </Link>
          </p>
        );
      },
    },
    {
      title: "Instruments",
      dataIndex: "prospect_instruments_title",
      key: "prospect_instruments_title",
      render: (text) => {
        return <p>{text}</p>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email) => {
        return <p>{email}</p>;
      },
    },
    {
      title: "Telephone",
      dataIndex: "telephone",
      key: "telephone",
      render: (telephone) => {
        return <p>{telephone}</p>;
      },
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      sorter: (a, b) => sortDates(a, b, "created"),
      render: (date) => <span>{moment(date).format(dateTimeFormat)}</span>,
    },
  ];

  const websiteLeadsColumns = [
    {
      title: "Info",
      dataIndex: "field_fname",
      key: "field_fname",
      width: "70%",
      render: (text, record) => {
        return (
          <>
            <ColorfulTag text={record.source} />
            <span>Request received on</span>{" "}
            <span style={{ fontWeight: "bold" }}>{moment(record.request_date).format(dateTimeFormat)}</span>
            <p>
              <MobileOutlined />
              <span style={{ fontWeight: "bold", margin: "0 6px" }}>{record.field_telephone}</span>
              <UserOutlined />
              <span style={{ fontWeight: "bold", margin: "0 6px" }}>
                {record.field_fname} {record.field_lname}
              </span>
              <MailOutlined />
              <span style={{ fontWeight: "bold", margin: "0 6px" }}>{record.field_email}</span>
            </p>
            <p>{record.field_message}</p>
          </>
        );
      },
    },
    {
      title: "",
      key: "actions",
      render: (text, record) => (
        <>
          {activeTab === "1" ? <Button onClick={() => handleClick(record.id, "archive")}>Archive</Button> : null}
          {record.source !== "KlingKlong" &&
            (record.status === "archive" ? (
              <Button
                type="primary"
                danger
                href={`/dashboard/AddProspectFromWebsiteLead/${record.id}/${record.status}`}
              >
                Import
              </Button>
            ) : (
              <Button type="primary" danger href={`/dashboard/AddProspectFromWebsiteLead/${record.id}`}>
                Import
              </Button>
            ))}
          {record.source === "KlingKlong" &&
            (record.status === "archive" ? (
              <Button
                type="primary"
                danger
                href={`/dashboard/AddProspectParentFromWebsiteLead/${record.id}/${record.status}`}
              >
                Import
              </Button>
            ) : (
              <Button type="primary" danger href={`/dashboard/AddProspectParentFromWebsiteLead/${record.id}`}>
                Import
              </Button>
            ))}
        </>
      ),
    },
  ];

  const withoutTrialProspects = [
    {
      title: "Student",
      dataIndex: "prospect_title",
      key: "prospect_title",
      sorter: (a, b) => sortStrings(a, b, "prospect_title"),
      render: (text, { prospectId }) => <Link to={`/dashboard/${schoolId}/prospectProfile/${prospectId}`}>{text}</Link>,
    },
    {
      title: "Instruments",
      dataIndex: "prospect_instruments_title",
      key: "prospect_instruments_title",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Teacher",
      dataIndex: "teacher_title",
      key: "teacher_title",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Trial Date",
      dataIndex: "lesson_date",
      key: "lesson_date",
      sorter: (a, b) => sortDates(a, b, "lesson_date"),
      render: (date) => <span>{date === null ? "-" : moment(date).format(dateTimeFormat)}</span>,
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      sorter: (a, b) => sortDates(a, b, "created"),
      render: (date) => <span>{moment(date).format(dateTimeFormat)}</span>,
    },
  ];

  const existingStudentTials = [
    {
      title: "Student",
      dataIndex: "prospect_title",
      key: "prospect_title",
      sorter: (a, b) => sortStrings(a, b, "prospect_title"),
      render: (text, { profileId }) => <Link to={`/dashboard/${schoolId}/profile/${profileId}`}>{text}</Link>,
    },
    {
      title: "Instruments",
      dataIndex: "prospect_instruments_title",
      key: "prospect_instruments_title",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Teacher",
      dataIndex: "teacher_title",
      key: "teacher_title",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Trial Date",
      dataIndex: "lesson_date",
      key: "lesson_date",
      sorter: (a, b) => sortDates(a, b, "lesson_date"),
      render: (date) => <span>{date === null ? "-" : moment(date).format(dateTimeFormat)}</span>,
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      sorter: (a, b) => sortDates(a, b, "created"),
      render: (date) => <span>{moment(date).format(dateTimeFormat)}</span>,
    },
  ];

  return !loading ? (
    <div style={{ height: "235px", padding: "2px" }} className={"prospectsCard"}>
      <div>
        {prospects && (
          <div>
            <ButtonWithModal
              buttonNumber={prospects?.prospects?.length || 0}
              modalTitle={"Without Trial (in last 2 months)"}
              buttonTitle={"Without Trial (in last 2 months)"}
            >
              <Table
                bordered
                columns={withNumberingColumn(prospectsColumns, paginationWithoutTrial)}
                dataSource={prospects?.prospects ? prospects.prospects : null}
                onChange={(pagination) => {
                  setPaginationWithoutTrial(pagination);
                }}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </ButtonWithModal>
          </div>
        )}
        {prospects?.trial_lessons && (
          <div>
            <ButtonWithModal
              buttonNumber={prospects?.trial_lessons?.before_today_lessons?.length || 0}
              modalTitle={"To Follow up with"}
              buttonTitle={`To Follow up with `}
            >
              <Table
                bordered
                columns={withNumberingColumn(withoutTrialProspects, paginationFollowupWith)}
                onChange={(pagination) => {
                  setPaginationFollowupWith(pagination);
                }}
                dataSource={
                  prospects?.trial_lessons?.before_today_lessons ? prospects?.trial_lessons?.before_today_lessons : null
                }
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </ButtonWithModal>
          </div>
        )}

        {prospects?.trial_lessons && (
          <div>
            <ButtonWithModal
              buttonNumber={
                prospects?.trial_lessons?.after_today_lessons?.length +
                  prospects?.trial_lessons?.today_lessons?.length || 0
              }
              modalTitle={"In Trial lessons"}
              buttonTitle={`In Trial lessons`}
            >
              <Table
                bordered
                columns={withNumberingColumn(withoutTrialProspects, paginationInTrial)}
                dataSource={prospects?.trial_lessons?.after_today_lessons ? todayAndAfterTodayLessons : null}
                onChange={(pagination) => {
                  setPaginationInTrial(pagination);
                }}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </ButtonWithModal>
            <ButtonWithModal
              modalTitle={"Today"}
              buttonTitle={`( ${prospects?.trial_lessons?.today_lessons?.length || 0} Today )`}
            >
              <Table
                bordered
                columns={withNumberingColumn(withoutTrialProspects, paginationInTrialToday)}
                onChange={(pagination) => {
                  setPaginationInTrialToday(pagination);
                }}
                dataSource={prospects?.trial_lessons?.today_lessons ? prospects?.trial_lessons?.today_lessons : null}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </ButtonWithModal>
          </div>
        )}

        {prospects?.trial_existing_student && (
          <div>
            <ButtonWithModal
              buttonNumber={
                prospects?.trial_existing_student?.after_today_lessons?.length +
                  prospects?.trial_existing_student?.today_lessons?.length || 0
              }
              modalTitle={"Existing Student Trials"}
              buttonTitle={`Existing Student Trials`}
            >
              <Table
                bordered
                columns={withNumberingColumn(existingStudentTials, paginExistStudentTrials)}
                dataSource={
                  prospects?.trial_existing_student?.after_today_lessons ? todayAndAfterTodayExistStudents : null
                }
                onChange={(pagination) => {
                  setPaginExistStudentTrials(pagination);
                }}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </ButtonWithModal>
            <ButtonWithModal
              modalTitle={"Today"}
              buttonTitle={`( ${prospects?.trial_existing_student?.today_lessons?.length || 0} Today )`}
            >
              <Table
                bordered
                columns={withNumberingColumn(existingStudentTials, paginExistStudentTrialsToday)}
                onChange={(pagination) => {
                  setPaginationInTrialToday(pagination);
                }}
                dataSource={
                  prospects?.trial_existing_student?.today_lessons
                    ? prospects?.trial_existing_student?.today_lessons
                    : null
                }
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </ButtonWithModal>
          </div>
        )}

        {(!isTeacher || isFranchisee || isStaff) && (
          <div>
            <ButtonWithModal
              buttonNumber={websiteLeads?.length || 0}
              modalTitle={"Website Leads"}
              buttonTitle={`Website Leads`}
            >
              <Tabs defaultActiveKey="1" onChange={(key) => setActiveTab(key)} style={{ marginLeft: "20px" }}>
                <TabPane tab="New" key="1">
                  <Table
                    bordered
                    columns={withNumberingColumn(websiteLeadsColumns, leadsPagination)}
                    dataSource={websiteLeads}
                    onChange={(pagination) => {
                      setLeadsPagination(pagination);
                    }}
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "50", "100"],
                    }}
                  />
                </TabPane>
                <TabPane tab="Archived" key="2">
                  <Table
                    bordered
                    columns={withNumberingColumn(websiteLeadsColumns, leadsPaginationArchived)}
                    onChange={(pagination) => {
                      setLeadsPaginationArchived(pagination);
                    }}
                    dataSource={leadsArchived}
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "50", "100"],
                    }}
                  />
                </TabPane>
              </Tabs>
            </ButtonWithModal>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default Prospects;
