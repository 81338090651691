import { createAction, handleActions } from "redux-actions";
import { getNode as apiGetNode, deleteNode as apiDeleteNode, editNode as apiEditNode } from "services/editNode";
import { openNotification } from "../utils/notification";
import { getErrorMessage } from "modules/process";

/**
 * editNode reducer
 * Handles editNode actions
 */

// ------------------------------------
// Constants
// ------------------------------------

export const GET_NODE_SUCCESS = "GET_NODE_SUCCESS";
export const GET_NODE_FAILED = "GET_NODE_FAILED";
export const GET_NODE_REQUEST = "GET_NODE_REQUEST";

export const EDIT_NODE_SUCCESS = "EDIT_NODE_SUCCESS";
export const EDIT_NODE_FAILED = "EDIT_NODE_FAILED";
export const EDIT_NODE_REQUEST = "EDIT_NODE_REQUEST";

export const DELETE_NODE_REQUEST = "DELETE_NODE_REQUEST";
export const DELETE_NODE_SUCCESS = "DELETE_NODE_SUCCESS";
export const DELETE_NODE_FAILED = "DELETE_NODE_FAILED";
// ------------------------------------
// Actions
// ------------------------------------

const getNodeSuccess = createAction(GET_NODE_SUCCESS);
const getNodeFailed = createAction(GET_NODE_FAILED);
const getNodeRequest = createAction(GET_NODE_REQUEST);

const editNodeSuccess = createAction(EDIT_NODE_SUCCESS);
const editNodeFailed = createAction(EDIT_NODE_FAILED);
const editNodeRequest = createAction(EDIT_NODE_REQUEST);

const deleteNodeRequest = createAction(DELETE_NODE_REQUEST);
const deleteNodeSuccess = createAction(DELETE_NODE_SUCCESS);
const deleteNodeFailed = createAction(DELETE_NODE_FAILED);

const getNode = (id) => {
  return (dispatch) => {
    dispatch(getNodeRequest());
    return apiGetNode(id)
      .then((response) => dispatch(getNodeSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(getNodeFailed(error));
      });
  };
};

const editNode = (data, id) => {
  return (dispatch) => {
    dispatch(editNodeRequest());
    return apiEditNode(data, id)
      .then((response) => {
        dispatch(editNodeSuccess(response.data));
        openNotification("success", "Item edited successfully");
        return response.data;
      })
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(editNodeFailed(error));
      });
  };
};

const deleteNode = (id) => {
  return (dispatch) => {
    dispatch(deleteNodeRequest());
    return apiDeleteNode(id)
      .then((response) => {
        dispatch(deleteNodeSuccess(response.data));
        openNotification("success", "Item deleted successfully");
      })
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(deleteNodeFailed(error));
      });
  };
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  getNode,
  deleteNode,
  editNode,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  editNode: {},
  errors: null,
  loading: false,
};

export default handleActions(
  {
    GET_NODE_REQUEST: (state) => {
      return { ...initialState, loading: true };
    },

    GET_NODE_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        loading: false,
      };
    },

    GET_NODE_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },

    EDIT_NODE_REQUEST: (state) => {
      return { ...initialState, loading: true };
    },

    EDIT_NODE_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        loading: false,
      };
    },

    EDIT_NODE_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },

    DELETE_NODE_REQUEST: (state) => {
      return { ...initialState, loading: true };
    },

    DELETE_NODE_SUCCESS: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    DELETE_NODE_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },
  },
  initialState
);
