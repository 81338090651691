import { createAction, handleActions } from "redux-actions";
import { getProspectInfo as apiGetProspectInfo } from "services/prospectInfo";
import { openNotification } from "../utils/notification";
import { getErrorMessage } from "modules/process";

/**
 * ContractInfo reducer
 * Handles ContractInfo actions
 */

// ------------------------------------
// Constants
// ------------------------------------

export const PROSPECT_INFO_SUCCESS = "PROSPECT_INFO_SUCCESS";
export const PROSPECT_INFO_FAILED = "PROSPECT_INFO_FAILED";
export const PROSPECT_INFO_REQUEST = "PROSPECT_INFO_REQUEST";

// ------------------------------------
// Actions
// ------------------------------------

const prospectInfoSuccess = createAction(PROSPECT_INFO_SUCCESS);
const prospectInfoFailed = createAction(PROSPECT_INFO_FAILED);
const prospectInfoRequest = createAction(PROSPECT_INFO_REQUEST);

const getProspectInfo = (id) => {
  return (dispatch) => {
    dispatch(prospectInfoRequest());
    apiGetProspectInfo(id)
      .then((response) => dispatch(prospectInfoSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(prospectInfoFailed(error));
      });
  };
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  getProspectInfo,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  prospectInfo: {},
  errors: null,
  loading: false,
};

export default handleActions(
  {
    PROSPECT_INFO_REQUEST: (state) => {
      return { ...initialState, loading: true };
    },

    PROSPECT_INFO_SUCCESS: (state, { payload }) => {
      return {
        ...initialState,
        loading: false,
        prospectInfo: payload,
      };
    },

    PROSPECT_INFO_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },
  },
  initialState
);
