import { EditOutlined } from "@ant-design/icons";
import EditTeacherAvailableTarifs from "components/EditTeacherAvailableTarifs";
import { Loader } from "components/index";
import useRoles from "hooks/useRoles";
import { getErrorMessage } from "modules/process";
import React, { useEffect } from "react";
import { Button, Table } from "antd";
import { getTeacherAvailableTarifs } from "services/profile";
import { openNotification } from "utils/notification";

const TeacherAvailableTarifs = ({ profileId }) => {
  const [loading, setLoading] = React.useState(false);
  const [teacherAvailableTarifs, setTeacherAvailableTarifs] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState();
  const [editOpen, setEditOpen] = React.useState(false);
  const { isStaff, isFranchisee } = useRoles();

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await getTeacherAvailableTarifs(profileId);
      setTeacherAvailableTarifs(data?.map((item, index) => ({ ...item, key: index })));
    } catch (error) {
      openNotification("error", getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [profileId]);

  const teacherAvailableTarifsCol = [
    {
      title: "Tarif",
      dataIndex: "title",
      key: "title",
      render: (title) => <span>{title}</span>,
    },
    {
      title: "Suggested Price",
      dataIndex: "suggested_price",
      key: "suggested_price",
      render: (suggested_price) => <span>{suggested_price === null ? "" : suggested_price}</span>,
    },

    {
      title: "Payment Type",
      dataIndex: "teacher_payment_type",
      key: "teacher_payment_type",
      render: (teacher_payment_type) => <span>{teacher_payment_type}</span>,
    },

    {
      title: "",
      dataIndex: "teacher_tarif_nid",
      key: "teacher_tarif_nid",
      render: (teacher_tarif_nid, row) =>
        isStaff || isFranchisee ? (
          <Button
            type={"primary"}
            icon={<EditOutlined />}
            size={"small"}
            onClick={(event) => {
              event.stopPropagation();
              setSelectedItem(row);
              setEditOpen(true);
            }}
          />
        ) : (
          <></>
        ),
    },
  ];
  if (loading) return <Loader />;
  return (
    <>
      <Table columns={teacherAvailableTarifsCol} dataSource={teacherAvailableTarifs} pagination={false} />
      <EditTeacherAvailableTarifs
        setVisible={setEditOpen}
        visible={editOpen}
        item={selectedItem}
        callback={fetchData}
      />
    </>
  );
};

export default TeacherAvailableTarifs;
