import axios from "axios";
import { apiURL } from "utils/config";
import { getAuthorizationHeaders } from "./auth";
import store from "../store";

/**
 * Returns Profile for SelectedSchool
 * @returns {AxiosPromise}
 */

export const getProfile = (profileID) => {
  let schoolID = store.getState().user.selectedSchool.id;
  const url = (schoolId, profileId) => `${apiURL}/school/${schoolId}/profile/${profileId}`;

  return axios({
    url: url(schoolID, profileID),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getTeacherStudents = (profileID) => {
  let schoolID = store.getState().user.selectedSchool.id;
  const url = (schoolId, profileId) => `${apiURL}/school/${schoolId}/profile/${profileId}/teacherStudents`;
  return axios({
    url: url(schoolID, profileID),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getTeacherPayments = (profileID) => {
  let schoolID = store.getState().user.selectedSchool.id;
  const url = (schoolId, profileId) => `${apiURL}/school/${schoolId}/profile/${profileId}/teacherPayments`;

  return axios({
    url: url(schoolID, profileID),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getTeacherAgreements = (profileID) => {
  let schoolID = store.getState().user.selectedSchool.id;
  const url = (schoolId, profileId) => `${apiURL}/school/${schoolId}/profile/${profileId}/teacherAgreements`;
  return axios({
    url: url(schoolID, profileID),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getTeacherAvailableTarifs = (profileId) => {
  let schoolID = store.getState().user.selectedSchool.id;
  const url = (schoolId, profileId) => `${apiURL}/school/${schoolId}/profile/${profileId}/available-tarifs`;
  return axios({
    url: url(schoolID, profileId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getTeacherTrials = (profileID) => {
  let schoolID = store.getState().user.selectedSchool.id;
  const url = (schoolId, profileId) => `${apiURL}/school/${schoolId}/profile/${profileId}/teacherTrials`;
  return axios({
    url: url(schoolID, profileID),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getTeacherLogs = (profileID) => {
  let schoolID = store.getState().user.selectedSchool.id;
  const url = (schoolId, profileId) => `${apiURL}/school/${schoolId}/profile/${profileId}/lessonLogs`;
  return axios({
    url: url(schoolID, profileID),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getSpecificTeacherLog = (profileID, lessonLog) => {
  let schoolID = store.getState().user.selectedSchool.id;
  const url = (schoolId, profileId) => `${apiURL}/school/${schoolId}/profile/${profileId}/lessonLog/${lessonLog}`;
  return axios({
    url: url(schoolID, profileID),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const editTeacherLog = (profileID, lessonLog, data) => {
  let schoolID = store.getState().user.selectedSchool.id;
  const url = (schoolId, profileId) =>
    `${apiURL}/school/${schoolId}/profile/${profileId}/lessonLog/${lessonLog}?_format=json`;
  return axios({
    url: url(schoolID, profileID),
    method: "PATCH",
    data: data,
    headers: getAuthorizationHeaders(),
  });
};

export const getStudentsContract = (profileID) => {
  let schoolID = store.getState().user.selectedSchool.id;
  const url = (schoolId, profileId) => `${apiURL}/school/${schoolId}/profile/${profileId}/studentContracts`;
  return axios({
    url: url(schoolID, profileID),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const postLessonLog = (data, profileId) => {
  let schoolID = store.getState().user.selectedSchool.id;
  return axios({
    url: `${apiURL}/school/${schoolID}/profile/${profileId}/lessonLog?_format=json`,
    method: "POST",
    data: data,
    headers: getAuthorizationHeaders(),
  });
};

export const updateLessonLog = (data, profileId, lessonLogId) => {
  let schoolID = store.getState().user.selectedSchool.id;
  return axios({
    url: `${apiURL}/school/${schoolID}/profile/${profileId}/lessonLog/${lessonLogId}`,
    method: "PATCH",
    data: data,
    headers: getAuthorizationHeaders(),
  });
};
