import { createAction, handleActions } from "redux-actions";
import { getProspectHistory as apiGetProspectHistory } from "services/prospectHistory";
import { openNotification } from "../utils/notification";
import { getErrorMessage } from "modules/process";

/**
 * ContractInfo reducer
 * Handles ContractInfo actions
 */

// ------------------------------------
// Constants
// ------------------------------------

export const PROSPECT_HISTORY_SUCCESS = "PROSPECT_HISTORY_SUCCESS";
export const PROSPECT_HISTORY_FAILED = "PROSPECT_HISTORY_FAILED";
export const PROSPECT_HISTORY_REQUEST = "PROSPECT_HISTORY_REQUEST";

// ------------------------------------
// Actions
// ------------------------------------

const prospectHistorySuccess = createAction(PROSPECT_HISTORY_SUCCESS);
const prospectHistoryFailed = createAction(PROSPECT_HISTORY_FAILED);
const prospectHistoryRequest = createAction(PROSPECT_HISTORY_REQUEST);

const getProspectHistory = (id) => {
  return (dispatch) => {
    dispatch(prospectHistoryRequest());
    apiGetProspectHistory(id)
      .then((response) => dispatch(prospectHistorySuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(prospectHistoryFailed(error));
      });
  };
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  getProspectHistory,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  prospectHistoryInfo: {},
  errors: null,
  loading: false,
};

export default handleActions(
  {
    PROSPECT_HISTORY_REQUEST: (state) => {
      return { ...initialState, loading: true };
    },

    PROSPECT_HISTORY_SUCCESS: (state, { payload }) => {
  return {
        ...state,
        loading: false,
        prospectHistoryInfo: payload,
      };
    },

    PROSPECT_HISTORY_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },
  },
  initialState
);
