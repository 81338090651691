import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { Loader } from "components";
import AuthenticatedRoute from "./AuthenticatedRoute";
import NonAuthenticatedRoute from "./NonAuthenticatedRoute";

// Load Routes dynamically for code splitting. Use webpackChunkName named chunks to better analyze the bundle size after building
const DashboardRoute = lazy(() => import(/* webpackChunkName: "DashboardRoute" */ "routes/Dashboard"));

const AccountRoute = lazy(() => import(/* webpackChunkName: "AccountRoute" */ "routes/Account"));

export const NotFoundRoute = lazy(() => import(/* webpackChunkName: "NotFoundRoute1" */ "routes/NotFound"));

export const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <AuthenticatedRoute path={"/"} exact component={DashboardRoute} />
        <AuthenticatedRoute path={"/dashboard"} component={DashboardRoute} />
        <NonAuthenticatedRoute path={"/account"} component={AccountRoute} />

        <Route component={NotFoundRoute} />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
