import axios from "axios";
import { apiURL, baseURL } from "utils/config";
import { getAuthorizationHeaders } from "./auth";
import getTime from "date-fns/getTime";
import store from "../store";

export const getKlingKlongGroups = () => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (id) => `${apiURL}/school/${id}/kk/course?${getTime(new Date())}`;

  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getKlingKlongGroupsWithQuerry = (querry) => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (id) => `${apiURL}/school/${id}/kk/course?type=${querry}`;

  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getKlingKlongSpecificGroup = (groupId) => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (groupId, id) => `${apiURL}/school/${id}/kk/course/${groupId}`;

  return axios({
    url: url(groupId, schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getParents = () => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (id) => `${apiURL}/school/${id}/kk/parent`;

  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getProspectParents = () => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (id) => `${apiURL}/school/${id}/kk/parent-prospect`;

  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getArchivedParents = () => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (id) => `${apiURL}/school/${id}/kk/parent-prospect?type=archive`;

  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getParentDetails = (parentId) => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (parentId, id) => `${apiURL}/school/${id}/kk/parent/${parentId}/overview`;

  return axios({
    url: url(parentId, schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getParentStudents = (parentId) => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (parentId, id) => `${apiURL}/school/${id}/kk/parent/${parentId}/students`;

  return axios({
    url: url(parentId, schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getParentComments = (parentId) => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (parentId, id) => `${apiURL}/school/${id}/kk/parent/${parentId}/comments`;

  return axios({
    url: url(parentId, schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getParentContracts = (parentId) => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (parentId, id) => `${apiURL}/school/${id}/kk/parent/${parentId}/contracts`;

  return axios({
    url: url(parentId, schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const deleteContractFromParent = (nodeId) => {
  const url = (nodeId) => `${baseURL}/node/${nodeId}?_format=json`;
  return axios({
    url: url(nodeId),
    method: "DELETE",
    headers: getAuthorizationHeaders(),
  });
};

export const getParentBills = (parentId) => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (parentId, id) => `${apiURL}/school/${id}/kk/parent/${parentId}/bills`;

  return axios({
    url: url(parentId, schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const addStudentToGroup = (courseId, studentId, date, flag) => {
  return axios({
    url: `${apiURL}/kk/student-participation`,
    method: "POST",
    data: { courseId, studentId, date, flag },
    headers: getAuthorizationHeaders(),
  });
};

export const removeStudentFromGroup = (courseId, studentId, date) => {
  return axios({
    url: `${apiURL}/kk/student-participation/${courseId}/${studentId}`,
    params: { date },
    method: "DELETE",
    headers: getAuthorizationHeaders(),
  });
};

export const addDateToGroup = (courseId, date) => {
  return axios({
    url: `${apiURL}/kk/date-participation`,
    method: "POST",
    data: { courseId, date },
    headers: getAuthorizationHeaders(),
  });
};

export const removeGroupDate = (courseId, date) => {
  return axios({
    url: `${apiURL}/kk/date-participation/${courseId}/${date}`,
    method: "DELETE",
    headers: getAuthorizationHeaders(),
  });
};

export const addProspectToGroupDate = (courseId, date, resource) => {
  return axios({
    url: `${apiURL}/kk/prospect-participation`,
    method: "POST",
    data: { courseId, date, ...resource },
    headers: getAuthorizationHeaders(),
  });
};

export const addChildrenToParent = (parentID, students) => {
  return axios({
    url: `${apiURL}/school/kk/parent`,
    method: "POST",
    data: { parentID, students },
    headers: getAuthorizationHeaders(),
  });
};
