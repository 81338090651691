import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import "./buttonWithModal.scss";
import { useDispatch } from "react-redux";
import { actions as alertsActions } from "modules/alerts";

export const ButtonWithModal = ({ modalTitle, buttonTitle, children, buttonNumber, isTshirtModal }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const handleWindowSizeChange = () => setWidth(window.innerWidth);

  const makeModalWider = width <= 750;

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    }; // eslint-disable-next-line
  }, []);

  const handleTshirtModal = () => {
    dispatch(alertsActions.getAlerts());
    setModal(false);
  };

  return (
    <>
      {buttonNumber >= 0 && <span className={"buttonWithModal__buttonNumber"}>{buttonNumber}</span>}
      <Button type="dashed" onClick={() => setModal(true)}>
        {buttonTitle}
      </Button>
      <Modal
        title={modalTitle}
        visible={modal}
        onOk={() => (isTshirtModal ? handleTshirtModal() : setModal(false))}
        onCancel={() => (isTshirtModal ? handleTshirtModal() : setModal(false))}
        centered
        width={makeModalWider ? "85%" : "60%"}
        bodyStyle={{ padding: 5, overflow: "scroll" }}
        okButtonProps={{ type: "primary", className: "buttonInModalWithFloatRounded__buttons--floatRight" }}
        cancelButtonProps={{
          className: "buttonInModalWithFloatRounded__buttons--floatLeft",
        }}
      >
        {children}
      </Modal>
    </>
  );
};

export default ButtonWithModal;
