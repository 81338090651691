import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { openNotification } from "utils/notification";
import { getErrorMessage } from "modules/process";
import { Card, Col, Row, Modal } from "antd";
import { CardChart, Loader } from "components";
import { AreaChart, Area, XAxis, Tooltip } from "recharts";
import { EyeOutlined } from "@ant-design/icons";
import { getTeacherTrials } from "services/profile";
import { getStatistics } from "services/statistics";
import moment from "moment";
import "../routes/Dashboard/HQStaff/export.scss";

export const TeacherStatistics = ({ profileId }) => {
  const [dataLoading, setDataLoading] = useState(false);
  const selectedDate = moment().format("YYYY-MM");
  const selectedSchool = useSelector((state) => state?.user?.selectedSchool?.id);

  const [data, setData] = useState([]);
  const [groupRatioCurrent, setGroupRatioCurrent] = useState();
  const [groupEfficiencyCurrent, setGroupEfficiencyCurrent] = useState();
  const [groupRatingCurrent, setGroupRatingCurrent] = useState();
  const [conversionCurrent, setConversionCurrent] = useState();

  const [groupRatioPrevious, setGroupRatioPrevious] = useState();
  const [groupEfficiencyPrevious, setGroupEfficiencyPrevious] = useState();
  const [groupRatingPrevious, setGroupRatingPrevious] = useState();
  const [conversionPrevious, setConversionPrevious] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [teacherTrials, setTeacherTrials] = useState();
  const [signedTrials, setSignedTrials] = useState();

  useEffect(() => {
    let yearStart = selectedDate.split("-");
    let selectedMonthsYear = Number(moment(selectedDate).format("YYYY"));
    let previousMonthsYear = Number(moment(selectedDate).add(-1, "month").format("YYYY"));
    let payload = {
      type: "teacher",
      profile_id: profileId,
      date_start:
        selectedMonthsYear - previousMonthsYear > 0 ? previousMonthsYear.toString() + "-12" : yearStart[0] + "-01",
      date_end: Number(yearStart[0]) < moment().year() ? yearStart[0] + "-12" : moment().format("YYYY-MM"),
    };
    fetchData(payload);
  }, []);

  useEffect(() => {
    getTeacherTrials(profileId).then((res) => {
      setTeacherTrials(res.data);
      let signed = res.data.filter((i) => i.status === "Signed");
      setSignedTrials(signed);
    });
  }, [profileId]);

  const fetchData = async (payload) => {
    setDataLoading(true);
    try {
      let { data } = await getStatistics(selectedSchool, payload);
      //This might have to be done for all tables fixes warning all children in list must have a unique key prop
      let dataKeyed = data?.map((data, index) => {
        return { ...data, key: index };
      });

      setData(dataKeyed);
      setCurrentMonthsData(dataKeyed);
    } catch (error) {
      openNotification("error", getErrorMessage(error));
    } finally {
      setDataLoading(false);
    }
  };

  const setCurrentMonthsData = (data) => {
    let currentData = data.filter((i) => moment(i.date).format("YYYY-MM") === moment(selectedDate).format("YYYY-MM"));
    setGroupRatioCurrent(currentData[0]?.group_ratio_month);
    setGroupEfficiencyCurrent(currentData[0]?.group_efinciency_month);
    setGroupRatingCurrent(currentData[0]?.group_rating_month);
    setConversionCurrent(currentData[0]?.conversion_month);

    let previousData = data.filter(
      (i) => moment(i.date).format("YYYY-MM") === moment(selectedDate).add(-1, "month").format("YYYY-MM")
    );
    console.log(data);
    console.log(previousData);
    setGroupRatioPrevious(previousData[0]?.group_ratio_month);
    setGroupEfficiencyPrevious(previousData[0]?.group_efinciency_month);
    setGroupRatingPrevious(previousData[0]?.group_rating_month);
    setConversionPrevious(previousData[0]?.conversion_month);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const getModalData = (field) => {
    let currentData = data.filter((i) => moment(i.date).format("YYYY-MM") === moment(selectedDate).format("YYYY-MM"));
    switch (field) {
      case "conversion_month":
        setModalData(currentData[0].conversion_month_info);
        setModalTitle("Conversion");
        break;
      case "group_ratio":
        setModalData(currentData[0].group_ratio_month_info);
        setModalTitle("Group Ratio");
        break;
      case "group_efficiency_index":
        setModalData(currentData[0].group_efinciency_month_info);
        setModalTitle("Group Efficiency Index");
        break;
    }
  };

  const getGroupRatioColorStyle = (number) => {
    switch (true) {
      case 0 <= number && number < 40:
        return "threeCard__number red";
      case 40 <= number && number < 60:
        return "threeCard__number orange";
      case 60 <= number && number < 80:
        return "threeCard__number yellow";
      case 80 <= number && number <= 100:
        return "threeCard__number green";
    }
  };

  const getGroupEfficiencyColorStyle = (number) => {
    switch (true) {
      case 0 <= number && number < 1.5:
        return "threeCard__number red";
      case 1.5 <= number && number < 2:
        return "threeCard__number orange";
      case 2 <= number && number < 3:
        return "threeCard__number yellow";
      case 3 <= number && number <= 4:
        return "threeCard__number green";
    }
  };

  const getGroupRatingColorStyle = (number) => {
    switch (true) {
      case 0.0 <= number && number < 0.6:
        return "threeCard__number red";
      case 0.6 <= number && number < 1.2:
        return "threeCard__number orange";
      case 1.2 <= number && number < 2.4:
        return "threeCard__number yellow";
      case 2.4 <= number && number <= 4:
        return "threeCard__number green";
    }
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <Row gutter={[24]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <Card>
            <div style={{ display: "flex" }}>
              <div
                className="viewInfo"
                onClick={() => {
                  getModalData("group_ratio");
                  setIsModalVisible(true);
                }}
              >
                <EyeOutlined />
              </div>
              <p className={"threeCard__title"} style={{ marginBottom: "0px" }}>
                Group Ratio
              </p>
            </div>

            {dataLoading ? (
              <Loader />
            ) : (
              <>
                <div className="stats">
                  <div className={getGroupRatioColorStyle(Number(groupRatioCurrent?.toFixed(2)))}>
                    {groupRatioCurrent?.toFixed(2) + "%"}
                  </div>
                  <div className={"threeCard__vsStats"}> vs {groupRatioPrevious?.toFixed(2) + "%"}</div>
                </div>
                <div
                  className={groupRatioCurrent - groupRatioPrevious > 0 ? "statsDiff__positive" : "statsDiff__negative"}
                >
                  {groupRatioCurrent - groupRatioPrevious > 0 ? "+" : null}
                  {parseFloat(groupRatioCurrent - groupRatioPrevious).toFixed(2) + "%"}
                </div>
              </>
            )}
          </Card>

          <CardChart
            chart={
              <AreaChart data={data}>
                <Area type="monotone" dataKey="group_ratio_month" stroke="#b99022" fill="#b3d9ff" strokeWidth={2} />
                <XAxis dataKey="date" hide />
                <Tooltip cursor={false} />
              </AreaChart>
            }
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <Card>
            <div style={{ display: "flex" }}>
              <div
                className="viewInfo"
                onClick={() => {
                  getModalData("group_efficiency_index");
                  setIsModalVisible(true);
                }}
              >
                <EyeOutlined />
              </div>
              <p className={"threeCard__title"} style={{ marginBottom: "0px" }}>
                Group Efficiency Index
              </p>
            </div>
            {dataLoading ? (
              <Loader />
            ) : (
              <>
                <div className="stats">
                  <div className={getGroupEfficiencyColorStyle(Number(groupEfficiencyCurrent?.toFixed(2)))}>
                    {groupEfficiencyCurrent?.toFixed(2)}
                  </div>
                  <div className={"threeCard__vsStats"}> vs {groupEfficiencyPrevious?.toFixed(2)}</div>
                </div>
                <div
                  className={
                    groupEfficiencyCurrent - groupEfficiencyPrevious > 0 ? "statsDiff__positive" : "statsDiff__negative"
                  }
                >
                  {groupEfficiencyCurrent - groupEfficiencyPrevious > 0 ? "+" : null}
                  {parseFloat(groupEfficiencyCurrent - groupEfficiencyPrevious).toFixed(2)}
                </div>
              </>
            )}
          </Card>

          <CardChart
            chart={
              <AreaChart data={data}>
                <Area
                  type="monotone"
                  dataKey="group_efinciency_month"
                  stroke="#b99022"
                  fill="#b3d9ff"
                  strokeWidth={2}
                />
                <XAxis dataKey="date" hide />
                <Tooltip cursor={false} />
              </AreaChart>
            }
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <Card>
            <p className={"threeCard__title"} style={{ marginBottom: "0px" }}>
              Group Rating
            </p>
            {dataLoading ? (
              <Loader />
            ) : (
              <>
                <div className="stats">
                  <div className={getGroupRatingColorStyle(Number(groupRatingCurrent?.toFixed(2)))}>
                    {groupRatingCurrent?.toFixed(2)}
                  </div>
                  <div className={"threeCard__vsStats"}> vs {groupRatingPrevious?.toFixed(2)}</div>
                </div>
                <div
                  className={
                    groupRatingCurrent - groupRatingPrevious > 0 ? "statsDiff__positive" : "statsDiff__negative"
                  }
                >
                  {groupRatingCurrent - groupRatingPrevious > 0 ? "+" : null}
                  {parseFloat(groupRatingCurrent - groupRatingPrevious).toFixed(2)}
                </div>
              </>
            )}
          </Card>

          <CardChart
            chart={
              <AreaChart data={data}>
                <Area type="monotone" dataKey="group_rating_month" stroke="#b99022" fill="#b3d9ff" strokeWidth={2} />
                <XAxis dataKey="date" hide />
                <Tooltip cursor={false} />
              </AreaChart>
            }
          />
        </Col>
      </Row>

      <Row gutter={[24]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <Card>
            <div style={{ display: "flex" }}>
              <p className={"threeCard__title"}>Conversion</p>
            </div>
            {dataLoading && !signedTrials && !teacherTrials ? (
              <Loader />
            ) : (
              // <>
              //   <div className="stats">
              //     <div className={"threeCard__number"}>{conversionCurrent?.toFixed(2) + "%"}</div>
              //     <div className={"threeCard__vsStats"}> vs {conversionPrevious?.toFixed(2) + "%"}</div>
              //   </div>
              //   <div
              //     className={conversionCurrent - conversionPrevious > 0 ? "statsDiff__positive" : "statsDiff__negative"}
              //   >
              //     {conversionCurrent - conversionPrevious > 0 ? "+" : null}
              //     {parseFloat(conversionCurrent - conversionPrevious).toFixed(2) + "%"}
              //   </div>
              // </>
              <>
                <div className={"statsDiff__positive__large "}>
                  {parseFloat((signedTrials?.length / teacherTrials?.length) * 100).toFixed(2) + "%"}
                </div>
                <div className="stats">
                  <div className={"threeCard__number__small"}>{signedTrials?.length}</div>
                  <div className={"threeCard__vsStats"}>signed out of {teacherTrials?.length} trials</div>
                </div>
              </>
            )}
          </Card>

          {/* <CardChart
            chart={
              <AreaChart data={data}>
                <Area type="monotone" dataKey="conversion_month" stroke="#b99022" fill="#b3d9ff" strokeWidth={2} />
                <XAxis dataKey="date" hide />
                <Tooltip cursor={false} />
              </AreaChart>
            }
          /> */}
        </Col>
      </Row>
      <Modal
        width={"800px"}
        title={modalTitle}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ type: "primary", className: "buttonInModalWithFloatRounded__buttons--floatRight" }}
        cancelButtonProps={{
          className: "buttonInModalWithFloatRounded__buttons--floatLeft",
        }}
      >
        <div className={"statsInfoText"} dangerouslySetInnerHTML={{ __html: modalData }} />
      </Modal>
    </div>
  );
};

export default TeacherStatistics;
