import axios from "axios";
import { apiURL, baseURL } from "utils/config";
import { getAuthorizationHeaders } from "./auth";
import store from "../store";

/**
 * Returns Alerts for contracts
 * @returns {AxiosPromise}
 */

// old way to search
// export const searchText = (text) => {
//   let schoolId = store.getState().user.selectedSchool.id;

//   const url = (text) => `${apiURL}/school/${schoolId}/search?query=${text}`;
//   return axios({
//     url: url(text),
//     method: "GET",
//     headers: getAuthorizationHeaders(),
//   });
// };

export const searchText = (text, id) => {
  let schoolId = store.getState().user.selectedSchool.id;

  return axios({
    url: `${baseURL}/api/v2/${schoolId}/search`,
    method: "POST",
    data: { search: text, teacher_id: id },
    headers: getAuthorizationHeaders(),
  });
};
