import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as formFieldsActions } from "modules/formFields";
import { Form, Input, DatePicker, Button } from "antd";
import moment from "moment";
import { FormSelect } from "components/formComponents/FormSelect";

export const PauseForm = ({ loading }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(formFieldsActions.getFormData("field_cancellation_purpose"));
    // eslint-disable-next-line
  }, []);

  const cancelationPurposeOptions = useSelector((state) => state?.formFields?.field_cancellation_purpose);
  //const cancelationDate = useSelector((state) => state?.studentProfile?.cancelationDate);

  return (
    <>
      {/* This was removed after request */}
      {/* {cancelationDate && (
        <div className={"warningBox"}>
          This contract will be cancelled on {format(new Date(cancelationDate), " E, MMMM dd, yyyy")}
        </div>
      )} */}

      <Form.Item name={["contract_history", "field_contract_history_dates"]} label="Dates" initialValue={moment()}>
        <DatePicker />
      </Form.Item>

      <Form.Item noStyle>
        <FormSelect
          formName={["contract_history", "field_cancellation_purpose"]}
          options={cancelationPurposeOptions}
          label="Cancelation purpose"
        />
      </Form.Item>

      <Form.Item name={["contract_history", "field_contract_history_notes"]} label="Notes">
        <Input.TextArea />
      </Form.Item>

      <Button type="primary" key="submit" htmlType="submit" loading={loading}>
        Submit
      </Button>
    </>
  );
};
export default PauseForm;
