import React from "react";
import { Tag } from "antd";

export const ColorfulTag = ({ text }) => {
  switch (text) {
    case "In Trial":
      return <Tag color="magenta">{text}</Tag>;

    case "Without Trial":
      return <Tag color="geekblue">{text}</Tag>;

    case "Signed":
      return <Tag color="green">{text}</Tag>;

    case "Archived - Not interested":
      return <Tag color="red">{text}</Tag>;

    case "Archived - Data Entry Error":
      return <Tag color="red">{text}</Tag>;

    case "Archived - Personal reasons":
      return <Tag color="red">{text}</Tag>;

    case "Archived - Too Expensive":
      return <Tag color="red">{text}</Tag>;

    case "Archived - Not satisfied":
      return <Tag color="red">{text}</Tag>;

    case "Archived":
      return <Tag color="red">{text}</Tag>;

    case "Follow up":
      return <Tag color="orange">{text}</Tag>;

    case "Follow Up":
      return <Tag color="orange">{text}</Tag>;

    case "ACTIVE":
      return <Tag color="green">{text}</Tag>;

    case "PAUSED":
      return <Tag color="orange">{text}</Tag>;

    case "CANCELED":
      return <Tag color="red">{text}</Tag>;

    case "TO CANCEL":
      return <Tag color="volcano">{text}</Tag>;

    case "COMPLETED":
      return <Tag color="cyan">{text}</Tag>;

    case "FREEZE":
      return <Tag color="cyan">{text}</Tag>;

    case "BLOCKED":
      return <Tag color="red">{text}</Tag>;

    case "NO BILL":
      return <Tag color="red">{text}</Tag>;

    case "MMS":
      return <Tag color="#DD4B39">{text}</Tag>;

    case "KlingKlong":
      return <Tag color="#F89406">{text}</Tag>;

    case "VibrA":
      return <Tag color="#468847">{text}</Tag>;

    default:
      return <Tag>{text}</Tag>;
  }
};
export default ColorfulTag;
