import React from "react";
import { Card } from "antd";

export const CardBasic = ({ heading, value, colorValue = "#b99022", text, cardHeight, subText }) => (
  <Card style={{ height: cardHeight ? cardHeight : "265px" }} className={"cardBasic"}>
    {heading ? (
      <div>
        <p className={"cardBasic--heading"}>{heading}</p>
      </div>
    ) : null}
    <div className={"cardBasic--body"}>
      <p className={"cardBasic--value"} style={colorValue && { color: colorValue }}>
        {value}
      </p>
      <p className={"cardBasic--text"}>{text}</p>
      {subText && <p className={"cardBasic--subText"}>{subText}</p>}
    </div>
  </Card>
);

export default CardBasic;
