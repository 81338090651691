import axios from "axios";
import { apiURL, baseURL } from "utils/config";
import { getAuthorizationHeaders } from "./auth";
import store from "../store";

export const getNotificationsInfo = () => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (schoolId) => `${apiURL}/school/${schoolId}/comments`;
  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const markCommentAsRead = (cid, type, field_user_reference) => {
  return axios({
    url: `${baseURL}/comment/${cid}`,
    method: "PATCH",
    data: { field_read: true, comment_type: type, field_user_reference: field_user_reference },
    headers: getAuthorizationHeaders(),
  });
};

export const getTeacherComments = () => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (schoolId) => `${apiURL}/school/${schoolId}/comments-teacher`;
  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};
