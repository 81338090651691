import axios from "axios";
import { apiURL } from "utils/config";
import { getAuthorizationHeaders } from "./auth";
import store from "../store";

const url = (id) => `${apiURL}/school/${id}/dashboard/alerts`;
const urlTeacherID = (id, teacherID) => `${apiURL}/school/${id}/dashboard/alerts?teacherID=${teacherID}`;

/**
 * Returns Alerts for contracts
 * @returns {AxiosPromise}
 */

export const getAlerts = (teacherID) => {
  let schoolId = store.getState().user.selectedSchool.id;
  return axios({
    url: teacherID ? urlTeacherID(schoolId, teacherID) : url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};
