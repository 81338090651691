import axios from "axios";
import { apiURL } from "utils/config";
import { getAuthorizationHeaders } from "./auth";
import store from "../store";

/**
 * Returns teacher for SelectedSchool
 * @returns {AxiosPromise}
 */

export const getOpenAccounting = () => {
  const url = (id) => `${apiURL}/school/${id}/billingOpenAccounting`;
  let schoolId = store.getState().user.selectedSchool.id;
  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getCollectionProcess = () => {
  const url = (id) => `${apiURL}/school/${id}/billingCollectionProcess`;
  let schoolId = store.getState().user.selectedSchool.id;
  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getMonthlyBillingWithDate = () => {
  let schoolId = store.getState().user.selectedSchool.id;
  let year = store.getState().billing.year;
  let month = store.getState().billing.month;
  const url = (id) => `${apiURL}/school/${id}/billing?date=${year}-${month}`;
  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};
