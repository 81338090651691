import { createAction, handleActions } from "redux-actions";
import { getBirthdays as apiGetBirthdays } from "services/birthdays";
import { openNotification } from "../utils/notification";
import { getErrorMessage } from "modules/process";
import { format } from "date-fns";
import addDays from "date-fns/addDays";

/**
 * Birthdays reducer
 * Handles Birthdays actions
 */

// ------------------------------------
// Constants
// ------------------------------------

export const BIRTHDAYS_SUCCESS = "BIRTHDAYS_SUCCESS";
export const BIRTHDAYS_FAILED = "BIRTHDAYS_FAILED";
export const BIRTHDAYS_REQUEST = "BIRTHDAYS_REQUEST";

// ------------------------------------
// Actions
// ------------------------------------

const birthdaysSuccess = createAction(BIRTHDAYS_SUCCESS);
const birthdaysFailed = createAction(BIRTHDAYS_FAILED);
const birthdaysRequest = createAction(BIRTHDAYS_REQUEST);

const getBirthdays = () => {
  return (dispatch) => {
    dispatch(birthdaysRequest());
    apiGetBirthdays()
      .then((response) => dispatch(birthdaysSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(birthdaysFailed(error));
      });
  };
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  getBirthdays,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  birthdays: [],
  errors: null,
  today: format(new Date(), "yyyyMMdd"),
  oneMonthFromNow: format(new Date(addDays(new Date(), 31)), "yyyyMMdd"),
  loading: false,
};

export default handleActions(
  {
    BIRTHDAYS_REQUEST: (state) => {
      return { ...initialState, loading: true };
    },

    BIRTHDAYS_SUCCESS: (state, { payload }) => {
      // console.table(payload);
      return {
        ...initialState,
        loading: false,
        birthdays: payload,
      };
    },

    BIRTHDAYS_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },
  },
  initialState
);
