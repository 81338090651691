import React from "react";
import { useSelector } from "react-redux";
import { Table } from "antd";
import { ColorfulTag } from "components";
import { CardChart } from "components";
import { PieChart, Pie, Legend, Cell } from "recharts";
import { colors } from "utils/config";
import { Link } from "react-router-dom";
import { sortStrings, withNumberingColumn } from "utils/utils";

const TeacherStudents = () => {
  const schoolId = useSelector((state) => state?.user?.selectedSchool?.id);
  const teacherStudents = useSelector((state) => state.teachersProfile && state.teachersProfile.teacherStudents);
  const teacherStudentsMapped = teacherStudents?.map((student, index) => {
    return {
      order: index,
      title: student.contractStudentFullName,
      status: student.contractStatus,
      instrument: student.instruments,
      tarif: student.contractTarif,
      id: student.contractStudentId,
      key: index,
    };
  });

  const getCalculatedStudents = (data) => {
    let total = 0;
    data.map((studentStatus) => {
      total += studentStatus.value;
    });
    return total;
  };

  const teacherStudentsCol = [
    {
      title: "Student Name",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => sortStrings(a, b, "title"),
      render: (title, { id }) => {
        return <Link to={`/dashboard/${schoolId}/profile/${id}`}>{title}</Link>;
      },
    },

    {
      title: "Tarif",
      dataIndex: "tarif",
      key: "tarif",
      sorter: (a, b) => sortStrings(a, b, "tarif"),
      render: (tarif) => <span>{tarif}</span>,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => sortStrings(a, b, "status"),
      render: (status) => (
        <span>
          <ColorfulTag text={status} />
        </span>
      ),
    },
    {
      title: "Instrument",
      dataIndex: "instrument",
      key: "instrument",
      sorter: (a, b) => sortStrings(a, b, "instrument"),
      render: (instrument) => <span>{instrument}</span>,
    },
  ];
  const pieData = [];
  const statusData = [];
  teacherStudentsMapped &&
    teacherStudentsMapped.reduce(function (res, value) {
      if (!res[value.status]) {
        res[value.status] = { name: value.status, value: 0 };
        if (value.status === "ACTIVE" || value.status === "TO CANCEL" || value.status === "PAUSED") {
          pieData.push(res[value.status]);
          statusData.push(res[value.status]);
        }
      }
      res[value.status].value++;

      return res;
    }, {});

  return (
    <>
      <CardChart
        chart={
          <PieChart>
            <Pie dataKey="value" data={pieData} innerRadius={40} outerRadius={70} paddingAngle={5}>
              {" "}
              {pieData?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index]} />
              ))}
            </Pie>
            <Legend />
          </PieChart>
        }
      />

      <div className={"stats"}>
        {statusData?.map(({ name, value }) => {
          return (
            <div className={"stats__item"}>
              {" "}
              <span className={"stats__item__title"}>
                <span className={"stats__item__title__number"}> {value} </span>
                {name.toLowerCase()} Students{" "}
              </span>{" "}
              <span className={"stats__item__percentage"}>{`${(
                (value / getCalculatedStudents(statusData)) *
                100
              ).toFixed(1)} %`}</span>
            </div>
          );
        })}
      </div>

      <div className={"stats"}></div>
      <Table columns={withNumberingColumn(teacherStudentsCol)} dataSource={teacherStudentsMapped} pagination={false} />
    </>
  );
};

export default TeacherStudents;
