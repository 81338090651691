import axios from "axios";
import { apiURL } from "utils/config";
import { getAuthorizationHeaders } from "./auth";
import store from "../store";

/**
 * Returns Alerts for contracts
 * @returns {AxiosPromise}
 */

export const getDashboardProspects = () => {
  let schoolId = store.getState().user.selectedSchool.id;

  const url = (id) => `${apiURL}/school/${id}/dashboard/prospects`;
  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getWebsiteLeads = (status) => {
  if (status) {
    let schoolId = store.getState().user.selectedSchool.id;
    const url = (id) => `${apiURL}/school/${id}/websiteleads/?status=archive`;
    return axios({
      url: url(schoolId),
      method: "GET",
      headers: getAuthorizationHeaders(),
    });
  } else {
    let schoolId = store.getState().user.selectedSchool.id;
    const url = (id) => `${apiURL}/school/${id}/websiteleads`;
    return axios({
      url: url(schoolId),
      method: "GET",
      headers: getAuthorizationHeaders(),
    });
  }
};

export const postWebsiteLead = (id, status) => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (id) => `${apiURL}/school/${id}/websiteleads`;
  return axios({
    url: url(schoolId),
    method: "POST",
    data: { id: id, status: status },
    headers: getAuthorizationHeaders(),
  });
};

export const getWebsiteLeadWithStatus = (status) => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (id) => `${apiURL}/school/${id}/websiteleads/?status=${status}`;
  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};
