import { createAction, handleActions } from "redux-actions";
import { getProspectProfile as apiGetProspectProfile } from "services/prospectProfile";
import { openNotification } from "../utils/notification";
import { getErrorMessage } from "modules/process";

/**
 * Profile reducer
 * Handles prospectProfiles actions
 */

// ------------------------------------
// Constants
// ------------------------------------
// Profile Constants

export const PROSPECT_PROFILE_REQUEST = "PROSPECT_PROFILE_REQUEST";
export const PROSPECT_PROFILE_SUCCESS = "PROSPECT_PROFILE_SUCCESS";
export const PROSPECT_PROFILE_FAILED = "PROSPECT_PROFILE_FAILED";

// ------------------------------------
// Actions
// ------------------------------------

//Profile Actions

const prospectProfileSuccess = createAction(PROSPECT_PROFILE_SUCCESS);
const prospectProfileFailed = createAction(PROSPECT_PROFILE_FAILED);
const prospectProfileRequest = createAction(PROSPECT_PROFILE_REQUEST);

// types
export const TYPE_USER_LOAD = "user:load";

const getProspectProfile = (id) => {
  return (dispatch) => {
    dispatch(prospectProfileRequest());
    apiGetProspectProfile(id)
      .then((response) => dispatch(prospectProfileSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(prospectProfileFailed(error));
      });
  };
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  getProspectProfile,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  loading: false,
  prospectProfileInfo: {
    firstName: null,
    lastName: null,
    gender: null,
    prospectId: null,
    language: null,
    mobile: null,
    prospectProfileMail: null,
    prospectProfilePhoto: null,
    telephone: null,
    title: null,
    userMail: null,
    roles: [],
    prospectComments: [],
    interview: "",
    dob: null,
  },
};

export default handleActions(
  {
    PROSPECT_PROFILE_REQUEST: () => {
      return { ...initialState, loading: true };
    },

    PROSPECT_PROFILE_SUCCESS: (state, { payload }) => {
      return {
        ...state,

        loading: false,
        prospectProfileInfo: {
          ...payload,
          firstName: payload.firstName,
          lastName: payload.lastName,
          gender: payload.gender,
          age: payload.age,
          prospectId: payload.id,
          mobile: payload.mobile,
          telephone: payload.telephone,
          prospectComments: [...payload.comments],
          email: payload.email,
          interview: payload.prospectInterview,
          title: payload.title,
          dob: payload.dob,
        },
      };
    },

    PROSPECT_PROFILE_FAILED: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    },
  },

  initialState
);
