import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Comments } from "components";
import { Row, Col, Modal, Avatar, Tabs } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { actions as prospectInfoActions } from "modules/prospectInfo";

import "./prospectinfo.scss";
// import { format } from "date-fns";
const { TabPane } = Tabs;

export const ProspectInfo = ({ id, fullName }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.prospectInfo?.loading);
  const {
    email,
    mobile,
    telephone,
    instruments,
    // notes
  } = useSelector((state) => state?.prospectInfo?.prospectInfo);

  useEffect(() => {
    // eslint-disable-next-line
  }, [id]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (id) => {
    setIsModalVisible(true);
    dispatch(prospectInfoActions.getProspectInfo(id));
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return loading ? (
    <a href="/#" onClick={() => showModal(id)}>
      {fullName}
    </a>
  ) : (
    <div>
      <a href="/#" onClick={() => showModal(id)}>
        {fullName}
      </a>
      <Modal
        title="Prospect Information"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"700px"}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className={"prospectInfo__profile"}>
              <Avatar className={"prospectInfo__profile__avatar"} size={100} icon={<UserOutlined />} />

              <div className={"prospectInfo__profile__info"}>
                <div>
                  Name: <span className={"prospectInfo__profile__info--highlighted"}>{fullName}</span>
                </div>
                <div>
                  Email: <span className={"prospectInfo__profile__info--highlighted"}>{email}</span>
                </div>
                <div>
                  Mobile: <span className={"prospectInfo__profile__info--highlighted"}>{mobile ? mobile : "--"}</span>
                </div>
                <div>
                  Telephone:{" "}
                  <span className={"prospectInfo__profile__info--highlighted"}>{telephone ? telephone : "--"}</span>
                </div>

                <div>
                  Instruments:{" "}
                  <span className={"prospectInfo__profile__info--highlighted"}>{instruments ? instruments : "--"}</span>
                </div>
              </div>
            </div>
          </Col>{" "}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Notes" key="1">
                <div className={"prospectInfo__overview__title"}>{`${fullName}`}</div>
                <div className={"prospectInfo__overview__item"}>
                  Notes: <span className={"prospectInfo__overview__item--highlighted"}>{}</span>
                </div>
              </TabPane>
              <TabPane tab="Interview" key="2">
                <div className={"prospectInfo__overview__title"}>Interview</div>
              </TabPane>
              <TabPane tab="Comments" key="3">
                <Comments />
              </TabPane>
            </Tabs>
          </Col>{" "}
        </Row>
      </Modal>
    </div>
  );
};

export default ProspectInfo;
