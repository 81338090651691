import { createAction, handleActions } from "redux-actions";
import {
  getTeacherStudents as apiGetTeacherStudents,
  getTeacherPayments as apiGetTeacherPayments,
  getTeacherTrials as apiGetTeacherTrials,
  getTeacherLogs as apiGetTeacherLogs,
} from "services/profile";
import { openNotification } from "utils/notification";
import { getErrorMessage } from "modules/process";

/**
 * Teacher Profile's reducer
 * Handles Teacher Profile's  actions
 */

// ------------------------------------
// Constants
// ------------------------------------

export const TEACHER_STUDENTS_SUCCESS = "TEACHER_STUDENTS_SUCCESS";
export const TEACHER_STUDENTS_FAILED = "TEACHER_STUDENTS_FAILED";
export const TEACHER_AGREEMENTS_SUCCESS = "TEACHER_AGREEMENTS_SUCCESS";
export const TEACHER_AGREEMENTS_FAILED = "TEACHER_AGREEMENTS_FAILED";
export const TEACHER_PAYMENTS_SUCCESS = "TEACHER_PAYMENTS_SUCCESS";
export const TEACHER_PAYMENTS_FAILED = "TEACHER_PAYMENTS_FAILED";
export const TEACHER_TRIALS_SUCCESS = "TEACHER_TRIALS_SUCCESS";
export const TEACHER_TRIALS_FAILED = "TEACHER_TRIALS_FAILED";
export const TEACHERS_LOGS_SUCCESS = "TEACHERS_LOGS_SUCCESS";
export const TEACHER_LOGS_FAILED = "TEACHER_LOGS_FAILED";
export const GET_STUDENTS_REQUEST = "GET_STUDENTS_REQUEST";
export const RESET_TEACHER_PAYMENTS = "RESET_TEACHER_PAYMENTS";
export const TEACHER_LOGS_REQUEST = "TEACHER_LOGS_REQUEST";

// ------------------------------------
// Actions
// ------------------------------------

//Teacher Actions
const getStudentsRequest = createAction(GET_STUDENTS_REQUEST);
const teacherStudentsSuccess = createAction(TEACHER_STUDENTS_SUCCESS);
const teacherStudentsFailed = createAction(TEACHER_STUDENTS_FAILED);
const teacherPaymentSuccess = createAction(TEACHER_PAYMENTS_SUCCESS);
const teacherPaymentFailed = createAction(TEACHER_PAYMENTS_FAILED);

const teacherTrialsSuccess = createAction(TEACHER_TRIALS_SUCCESS);
const teacherTrialsFailed = createAction(TEACHER_TRIALS_FAILED);
const teacherLogsSuccess = createAction(TEACHERS_LOGS_SUCCESS);
const teachersLogsFailed = createAction(TEACHER_LOGS_FAILED);
const teacherLogsRequest = createAction(TEACHER_LOGS_REQUEST);
const resetTeacherPayments = createAction(RESET_TEACHER_PAYMENTS);

const getTeacherStudents = (id) => {
  return (dispatch) => {
    dispatch(getStudentsRequest());

    apiGetTeacherStudents(id)
      .then((response) => dispatch(teacherStudentsSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(teacherStudentsFailed(error));
      });

    apiGetTeacherTrials(id)
      .then((response) => dispatch(teacherTrialsSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(teacherTrialsFailed(error));
      });
  };
};

const getTeacherPayments = (id) => {
  return (dispatch) => {
    dispatch(resetTeacherPayments());
    apiGetTeacherPayments(id)
      .then((response) => dispatch(teacherPaymentSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(teacherPaymentFailed(error));
      });
  };
};

const getTeacherLogs = (id) => {
  return (dispatch) => {
    dispatch(teacherLogsRequest());
    apiGetTeacherLogs(id)
      .then((response) => dispatch(teacherLogsSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(teachersLogsFailed(error));
      });
  };
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  getTeacherStudents,
  getStudentsRequest,
  getTeacherPayments,
  getTeacherLogs,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  agreementsLoading: false,
  paymentsLoading: false,
  trialsLoading: false,
  logsLoading: false,
  errors: null,
  teacherStudents: [],
  teacherAgreements: [],
  teacherPayments: [],
  teacherTrials: [],
  teacherLogs: [],
};

export default handleActions(
  {
    TEACHER_STUDENTS_SUCCESS: (state, { payload }) => {
      return { ...state, teacherStudents: payload, loading: false };
    },

    TEACHER_LOGS_REQUEST: (state) => {
      return { ...state, logsLoading: false };
    },

    TEACHERS_LOGS_SUCCESS: (state, { payload }) => {
      return { ...state, teacherLogs: payload, logsLoading: false };
    },

    TEACHER_LOGS_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },

    TEACHER_STUDENTS_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },

    TEACHER_AGREEMENTS_SUCCESS: (state, { payload }) => {
      return { ...state, teacherAgreements: payload, agreementsLoading: false };
    },

    TEACHER_AGREEMENTS_FAILED: (state, { payload }) => {
      return { ...state, errors: payload };
    },

    TEACHER_PAYMENTS_SUCCESS: (state, { payload }) => {
      return { ...state, teacherPayments: payload, paymentsLoading: false };
    },

    TEACHER_PAYMENTS_FAILED: (state, { payload }) => {
      return { ...state, errors: payload };
    },

    RESET_TEACHER_PAYMENTS: (state) => {
      return { ...state, teacherPayments: [] };
    },
    TEACHER_TRIALS_SUCCESS: (state, { payload }) => {
      return { ...state, teacherTrials: payload, trialsLoading: false };
    },
    TEACHER_TRIALS_FAILED: (state, { payload }) => {
      return { ...state, errors: payload };
    },
  },

  initialState
);
