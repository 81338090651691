import { useEffect, useState } from "react";

export default function useResponsiveWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => setWidth(window.innerWidth);
  const fullView = width < 700;

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    }; // eslint-disable-next-line
  }, []);

  return { fullView };
}
