import axios from "axios";
import { getAuthorizationHeaders } from "./auth";
import store from "../store";

import { baseURL, apiURL } from "utils/config";
/**
 * Returns Form Fields
 * @returns {AxiosPromise}
 */

export const getFormData = (dataType) => {
  const url = (dataType) => `${apiURL}/list_type/${dataType}`;
  return axios({
    url: url(dataType),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getEntityData = (dataType) => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (dataType) => `${apiURL}/entity_select/${dataType}?school=${schoolId}`;
  return axios({
    url: url(dataType),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const editItem = (data, id) => {
  return axios({
    url: `${baseURL}/node/${id}?_format=json`,
    method: "PATCH",
    data: data,
    headers: getAuthorizationHeaders(),
  });
};

export const deleteItem = (data, id) => {
  return axios({
    url: `${baseURL}/node/${id}?_format=json`,
    method: "DELETE",
    data: data,
    headers: getAuthorizationHeaders(),
  });
};

export const postItem = (data) => {
  return axios({
    url: `${baseURL}/node?_format=json`,
    method: "POST",
    data: data,
    headers: getAuthorizationHeaders(),
  });
};

export const postLetterInformation = (data) => {
  return axios({
    url: `${apiURL}/letter`,
    method: "POST",
    data: data,
    headers: getAuthorizationHeaders(),
  });
};

export const postLetterType = (data) => {
  return axios({
    url: `${apiURL}/letter`,
    method: "POST",
    data: data,
    headers: getAuthorizationHeaders(),
  });
};

export const autocompleteItem = (field, string) => {
  let schoolId = store.getState().user.selectedSchool.id;

  return axios({
    url: `${baseURL}/api/v1/entity_autocomplete`,
    method: "POST",
    headers: getAuthorizationHeaders(),
    data: { school: schoolId, field_name: field, input: string },
  });
};

export const newWayAutoComplete = (field, string) => {
  let schoolId = store.getState().user.selectedSchool.id;
  return axios({
    url: `${baseURL}/api/v1/school/${schoolId}/${field}/${string}`,
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};
