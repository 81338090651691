import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Input, Modal } from "antd";
import { searchText } from "services/search";
import { MobileOutlined, PhoneOutlined, PushpinOutlined, CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import { format } from "date-fns";
import useResponsiveWidth from "hooks/useResizeWidth";
import useRoles from "hooks/useRoles";
import "./globalSearch.scss";

const GlobalSearch = () => {
  const [results, setResults] = useState([]);

  const [searchLoading, setSearchLoading] = useState(false);
  const [isResultsModalOpen, setIsResultsModalOpen] = useState(false);
  const history = useHistory();
  const schoolId = useSelector((state) => state?.user?.selectedSchool?.id);
  const [searchValue, setSearchValue] = useState();
  const [searchVisible, setSearchVisible] = useState(false);
  const { fullView } = useResponsiveWidth();
  const { isTeacher, teacherId, isFranchisee, isHQStaff, isStaff, isKKTeacher } = useRoles();

  const inputRef = useRef();

  useEffect(() => {
    if (searchVisible) {
      let { input } = inputRef.current;
      input.focus();
    }
  }, [searchVisible]);

  let TIMER = null;

  const handleSearch = (typed) => {
    if (typed?.length < 3) {
      setIsResultsModalOpen(false);
      setResults([]);
      setSearchLoading(false);
      return;
    }
    setSearchLoading(true);
    clearTimeout(TIMER);

    TIMER = setTimeout(() => {
      let id = (isTeacher || isKKTeacher) && !isFranchisee && !isHQStaff && !isStaff ? teacherId : null;
      searchText(typed, id).then(({ data }) => {
        setIsResultsModalOpen(true);
        setResults(data);
        setSearchLoading(false);
      });
    }, 300);
  };

  const handleOnFocus = () => {
    setSearchValue();
  };

  const handleClose = () => {
    setIsResultsModalOpen(false);
    setResults([]);
    setSearchLoading(false);
    setSearchValue("");
    setSearchVisible(false);
    return;
  };

  return (
    <>
      <div className="globalSearch_button">
        <Button className="ant-btn-primary btn" onClick={() => setSearchVisible(true)}>
          <SearchOutlined />
        </Button>
      </div>
      {searchVisible && (
        <Modal
          visible={searchVisible}
          onCancel={() => handleClose()}
          bodyStyle={{ padding: "5px 5px 0px 5px" }}
          footer={[null]}
          width="800px"
        >
          <div className={"globalSearch"}>
            <Input
              enterButton
              loading={searchLoading}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              placeholder="Search"
              value={searchValue}
              ref={inputRef}
              onFocus={handleOnFocus}
            />

            <div className={"globalSearch__abso"}>
              {isResultsModalOpen ? (
                <div className={"globalSearch__results"} style={{ color: "black" }}>
                  {results?.map((person) => {
                    return (
                      <div
                        className="person"
                        onClick={() => {
                          if (person.bundle === "kk_parent" || person.bundle === "kk_student") {
                            history.push(`/dashboard/klingKlong/parent/${person?.id}`);
                          } else {
                            history.push(
                              `/dashboard/${schoolId}/${person?.bundle === "profile" ? "profile" : "prospectProfile"}/${
                                person.id
                              }`
                            );
                          }

                          handleClose();
                        }}
                      >
                        <div className={fullView ? "person__infoMobile" : "person__info"}>
                          <div className={fullView ? "person__infoMobile--generic" : "person__info--generic"}>
                            <span className={"person__title"}>{person?.fullname}</span>
                            <div
                              className={
                                fullView ? "person__infoMobile--generic--email" : "person__info--generic--email"
                              }
                            >
                              {person?.email}
                            </div>
                          </div>
                        </div>

                        <div className={fullView ? "person__addressMobile" : "person__address"}>
                          {person?.address && (
                            <div className="person__address--current">
                              <PushpinOutlined style={{ marginRight: "3px" }} />
                              {person?.address}
                            </div>
                          )}
                          {person?.date_of_birth && (
                            <div className="person__address--dob">
                              <CalendarOutlined style={{ marginRight: "3px" }} />
                              {format(new Date(person?.date_of_birth), "dd MMMM Y")}
                            </div>
                          )}
                        </div>
                        <div className={fullView ? "person__contactMobile" : "person__contact"}>
                          {person?.mobile && (
                            <div className="person__contact--mobile">
                              <MobileOutlined style={{ marginRight: "3px" }} />
                              {person?.mobile}
                            </div>
                          )}
                          {person?.telephone && (
                            <div className="person__contact--telephone">
                              <PhoneOutlined style={{ marginRight: "3px" }} />
                              {person?.telephone}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default GlobalSearch;
