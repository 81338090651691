import { createAction, handleActions } from "redux-actions";
import {
  getOpenAccounting as apiGetOpenAccounting,
  getCollectionProcess as apiGetCollectionProcess,
  getMonthlyBillingWithDate as apiGetMonthlyBillingWithDate,
} from "services/billing";
import { format } from "date-fns";
import { openNotification } from "../utils/notification";
import { getErrorMessage } from "modules/process";

/**
 * Billing reducer
 * Handles teachers actions
 */

// ------------------------------------
// Constants
// ------------------------------------

export const OPEN_ACCOUNTING_REQUEST = "OPEN_ACCOUNTING_REQUEST";
export const OPEN_ACCOUNTING_SUCCESS = "OPEN_ACCOUNTING_SUCCESS";
export const OPEN_ACCOUNTING_FAILED = "OPEN_ACCOUNTING_FAILED";

export const COLLECTION_PROCESS_REQUEST = "COLLECTION_PROCESS_REQUEST";
export const COLLECTION_PROCESS_SUCCESS = "COLLECTION_PROCESS_SUCCESS";
export const COLLECTION_PROCESS_FAILED = "COLLECTION_PROCESS_FAILED";

export const MONTHLY_BILLING_REQUEST = "MONTHLY_BILLING_REQUEST";
export const MONTHLY_BILLING_SUCCESS = "MONTHLY_BILLING_SUCCESS";
export const MONTHLY_BILLING_FAILED = "MONTHLY_BILLING_FAILED";

export const CHANGE_DATE = "CHANGE_DATE";

// ------------------------------------
// Actions
// ------------------------------------

const openAccountingRequest = createAction(OPEN_ACCOUNTING_REQUEST);
const openAccountingSuccess = createAction(OPEN_ACCOUNTING_SUCCESS);
const openAccountingFailed = createAction(OPEN_ACCOUNTING_FAILED);

const collectionProcessRequest = createAction(COLLECTION_PROCESS_REQUEST);
const collectionProcessSuccess = createAction(COLLECTION_PROCESS_SUCCESS);
const collectionProcessFailed = createAction(COLLECTION_PROCESS_FAILED);

const monthlyBillingRequest = createAction(MONTHLY_BILLING_REQUEST);
const monthlyBillingSuccess = createAction(MONTHLY_BILLING_SUCCESS);
const monthlyBillingFailed = createAction(MONTHLY_BILLING_FAILED);

const changeDate = createAction(CHANGE_DATE);

const getOpenAccounting = () => {
  return (dispatch) => {
    dispatch(openAccountingRequest());
    apiGetOpenAccounting()
      .then((response) => dispatch(openAccountingSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(openAccountingFailed(error));
      });
  };
};

const getCollectionProcess = () => {
  return (dispatch) => {
    dispatch(collectionProcessRequest());
    apiGetCollectionProcess()
      .then((response) => dispatch(collectionProcessSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(collectionProcessFailed(error));
      });
  };
};

const getMonthlyBilling = () => {
  return (dispatch) => {
    dispatch(monthlyBillingRequest());
    apiGetMonthlyBillingWithDate()
      .then((response) => dispatch(monthlyBillingSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(monthlyBillingFailed(error));
      });
  };
};

const getMonthlyBillingWithDate = (year, month) => {
  return (dispatch) => {
    dispatch(changeDate(year, month));
    dispatch(getMonthlyBilling());
  };
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  getOpenAccounting,
  getCollectionProcess,
  getMonthlyBilling,
  getMonthlyBillingWithDate,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  openAccountingItems: [],
  collectionProcessItems: [],
  monthlyBillingItems: [],
  year: format(new Date(), "yyyy"),
  month: format(new Date(), "MM"),
  errors: null,
  loading: false,
};

export default handleActions(
  {
    LOGOUT_SUCCESS: () => {
      return {
        ...initialState,
      };
    },
    OPEN_ACCOUNTING_REQUEST: (state) => {
      return { ...state, loading: true };
    },

    OPEN_ACCOUNTING_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        openAccountingItems: payload,
      };
    },

    OPEN_ACCOUNTING_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },

    COLLECTION_PROCESS_REQUEST: (state) => {
      return { ...state, loading: true };
    },

    COLLECTION_PROCESS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        collectionProcessItems: payload,
      };
    },

    COLLECTION_PROCESS_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },

    MONTHLY_BILLING_REQUEST: (state) => {
      return { ...state, loading: true };
    },

    MONTHLY_BILLING_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        monthlyBillingItems: payload,
      };
    },

    MONTHLY_BILLING_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },

    CHANGE_DATE: (state, { payload }) => {
      return { ...state, year: payload.year, month: payload.month };
    },
  },
  initialState
);
