import axios from "axios";
import { getAuthorizationHeaders } from "./auth";
import { baseURL } from "utils/config";

/**
 * Returns Contract Info
 * @returns {AxiosPromise}
 * */

export const getNode = (id) => {
  const url = (id) => `${baseURL}/node/${id}?_format=json`;
  return axios({
    url: url(id),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const editNode = (data, id) => {
  const url = (id) => `${baseURL}/node/${id}?_format=json`;
  return axios({
    url: url(id),
    method: "PATCH",
    data: data,
    headers: getAuthorizationHeaders(),
  });
};

export const deleteNode = (nodeId) => {
  const url = (nodeId) => `${baseURL}/node/${nodeId}?_format=json`;
  return axios({
    url: url(nodeId),
    method: "DELETE",
    headers: getAuthorizationHeaders(),
  });
};
