import React from "react";
import { Input } from "antd";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import classNames from "classnames";
import "./Searchbar.scss";

export const Searchbar = ({ searchTerm, handleChange, setSearchTerm, variant, isMobile }) => (
  <div className={classNames("Searchbar", { [`Searchbar--${variant}`]: variant })}>
    <Input
      size={isMobile ? "small" : "large"}
      value={searchTerm}
      onChange={handleChange}
      placeholder="Search"
      prefix={<SearchOutlined />}
      style={{ maxWidth: "300px" }}
      suffix={
        searchTerm === "" ? null : (
          <button
            onClick={() => setSearchTerm("")}
            style={{ backgroundColor: "white", border: "none", outline: "none", cursor: "pointer" }}
          >
            <CloseOutlined />
          </button>
        )
      }
    />
  </div>
);

export default Searchbar;
