import axios from "axios";
import { apiURL } from "utils/config";
import { getAuthorizationHeaders } from "./auth";
import store from "../store";

/**
 * Returns Prospect Info
 * @returns {AxiosPromise}
 * */

export const getProspectInfo = (id) => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (schoolId) => `${apiURL}/school/${schoolId}/prospect/${id}`;
  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};
