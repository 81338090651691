import { actions as processActions } from "modules/process";
import { format } from "date-fns";
import { RRule } from "rrule";
import moment from "moment";

const sizes = {
  xs: 320,
  sm: 600,
  md: 1000,
  lg: 1200,
  xl: 1500,
};

export const dateTimeFormat = "DD MMM, YYYY HH:mm";
export const dateFormat = "DD MMM, YYYY";
export const ROOMPLAN_TIMEOUT = 1500;

export const emitProcess = (processName, processType, msg) => {
  switch (processName) {
    case "loading":
      return processActions.processLoading({
        type: processType,
        message: msg,
      });
    case "success":
      return processActions.processSuccess({
        type: processType,
        message: msg,
      });
    case "error":
      return processActions.processFailure({
        type: processType,
        message: msg,
      });
    default:
      return processActions.processLoading({
        type: processType,
        message: msg,
      });
  }
};

/**
 * Gets the url parameter my name
 * @param name
 * @param url
 * @returns {*}
 */
export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * Gets the hash parameter by name
 * @param name
 * @param url
 * @returns {string|null}
 */
export function getHashParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$#");
  let regex = new RegExp("[#]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * Console log only in non production mode
 * @param msg
 */
export const logToConsole = (msg) => {
  if (process.env.REACT_APP_API_ENV !== "production") {
    console.log(msg);
  }
};

/**
 * Gets the current viewport size (xs, sm, md, lg etc)
 * @returns {string}
 */
export function getCurrentSize() {
  let d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0];

  const windowWidth = window.innerWidth || e.clientWidth || g.clientWidth;

  for (let i in sizes) {
    if (windowWidth <= sizes[i]) return i;
  }
}

/**
 * Format price with sign
 * @param price
 * @returns {string}
 */
export function formatPriceWithSign(price) {
  if (price === null || price === false || isNaN(price)) return "";

  return parseFloat(price).toFixed(2) + "€";
}

export function isMobile() {
  return Boolean(
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        navigator.userAgent.substr(0, 4)
      )
  );
}

export function formatNumInput(str) {
  return str.replace(/[^0-9.]/g, "");
}

export function isEmail(val) {
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(val);
}

export const finalAmountCalculator = (arr) => {
  if (!arr || arr.length === 0) return 0;

  return arr.reduce((acc, val) => {
    return +acc + Number(val?.amount || 0);
  }, 0);
};

export const finalBalanceCalculator = (arr) =>
  arr.reduce((acc, val) => {
    return acc + Number(val.balance);
  }, 0);

export const percentageChange = (oldNumber, newNumber) => {
  if (oldNumber === 0) {
    return newNumber;
  }
  const decreaseValue = oldNumber - newNumber;
  return (decreaseValue / oldNumber) * 100;
};

export const formatNumber = (num) => {
  if (!num || isNaN(Number(num))) {
    return "0.00";
  }

  return Number(num).toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const rruleStringEdited = (date, rrule) => {
  const dateMinusOneDay = moment(date).subtract(1, "days"); // Minus 1 Day
  const formattedDate = format(dateMinusOneDay._d, "yyyyMMdd") + "T" + format(dateMinusOneDay._d, "HHmmss") + "Z"; // Formats based on Rrules Until key Format
  const newUntilRrule = rrule.split("UNTIL="); // Splits "UNTIL" from rrule's string
  return newUntilRrule[0].concat("UNTIL=" + formattedDate); // merge's new Date with Rrule String
};

export const rruleStringAddExclusion = (endDate, rrule) => {
  const formattedDate = format(endDate, "yyyyMMdd") + "T" + format(endDate, "HHmmss") + "Z";

  if (rrule.includes("EXDATE")) {
    return rrule + `,${formattedDate}`;
  } else {
    return rrule + `\nEXDATE:${formattedDate}`;
  }
};

export const dateMergedWithTime = (date, time) => {
  const timeHours = format(time, "HH");
  const timeMinutes = format(time, "mm");
  const mergedDate = moment(date).set({ hour: timeHours, minute: timeMinutes });
  return mergedDate?.toDate?.();
};

export const objectMap = (obj, fn) => Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

export const findRruleFrequency = (freq) => {
  switch (freq) {
    case 0:
      return RRule.YEARLY;
    case 1:
      return RRule.MONTHLY;
    case 2:
      return RRule.WEEKLY;
    case 3:
      return RRule.DAILY;
    default:
      return RRule.DAILY;
  }
};

export const renameArrayKeysToLabelAndValue = (options) =>
  options?.map((elm) => ({ label: elm.title, value: elm.nid }));

const rename = (obj, oldName, newName) => {
  if (!obj || !obj.hasOwnProperty(oldName)) {
    return false;
  }

  obj[newName] = obj[oldName];
  delete obj[oldName];
  return true;
};

export const renameKeys = (obj) => {
  rename(obj, "nid", "value");
  rename(obj, "title", "label");
  return obj;
};

export const sortStrings = (a, b, fieldName) => {
  return a[fieldName] && b[fieldName] ? a[fieldName].localeCompare(b[fieldName]) : null;
};

export const sortStringsIncludingNull = (a, b, fieldName) => {
  if (a) {
    return b[fieldName] ? a[fieldName].localeCompare(b[fieldName]) : -1;
  } else if (b) {
    return a[fieldName] ? b[fieldName].localeCompare(a[fieldName]) : 1;
  }
};

export const sortNumericStrings = (a, b, fieldName) => {
  return a[fieldName] && b[fieldName] ? a[fieldName].localeCompare(b[fieldName], undefined, { numeric: true }) : null;
};
export const sortNumbers = (a, b, fieldName) => {
  return a[fieldName] && b[fieldName] ? a[fieldName] - b[fieldName] : null;
};

export const sortNumberValuesIncludingNull = (a, b) => {
  if (!a && a !== 0) {
    return 1;
  }
  if (!b && b !== 0) {
    return -1;
  }
  if (a === b) {
    return 0;
  }
  return a < b ? -1 : 1;
};

export const sortDates = (a, b, fieldName) => {
  return a?.[fieldName] && b?.[fieldName] ? moment(a[fieldName]) - moment(b[fieldName]) : null;
};

export const sortDatesByMonthDay = (a, b, fieldName) => {
  return a?.[fieldName] && b?.[fieldName]
    ? moment(a[fieldName])
        .format("MM DD")
        .localeCompare(moment(b[fieldName]).format("MM DD"), undefined, { numeric: true })
    : null;
};

export const transformToDateWithoutTimeZone = (date) => {
  if (!date) return null;

  const dateWithTimeZone = new Date(date);

  return moment(dateWithTimeZone).utcOffset(0, true).format();
};

export const transformToDateWithTimeZone = (date) => {
  if (!date) return null;

  const dateWithoutTimeZone = new Date(date);
  const timeZoneOffset = dateWithoutTimeZone.getTimezoneOffset();

  return moment(dateWithoutTimeZone).add(timeZoneOffset, "minutes");
};

export function ifHasValue(value) {
  return value || value === 0 || value === "0";
}

export function getIsEmpty(obj) {
  return Object.values(obj).every((value) => {
    if (!value?.length) {
      return true;
    } else {
      return false;
    }
  });
}

export const withNumberingColumn = (columns, pagination) => {
  return [
    {
      title: "#",
      render: (text, record, index) => {
        const currentPage = pagination?.current ? pagination?.current - 1 : 0;
        const pageSize = pagination?.pageSize || 10;
        return currentPage * pageSize + index + 1;
      },
    },
    ...columns,
  ];
};
