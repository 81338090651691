import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Empty } from "antd";
import { format } from "date-fns";
import { CardHeader, Loader } from "components";
import { actions as upcomingLessonsActions } from "modules/upcomingLessons";
import { Link } from "react-router-dom";
import "./upcomingLessons.scss";

export const UpcomingLessons = () => {
  const dispatch = useDispatch();

  const [lessons, setLessons] = useState([]);
  const schoolId = useSelector((state) => state?.user?.selectedSchool?.id);

  useEffect(() => {
    dispatch(upcomingLessonsActions.getUpcomingLessons()).then((data) => {
      if (data && !data?.error) {
        setLessons(
          data?.payload?.map?.((lesson) => {
            return {
              ...lesson,
              time: lesson?.start,
              room: lesson?.room_title,
              title: lesson?.title,
              contracts: lesson?.lesson_contracts,
            };
          }) || []
        );
      }
    });
    // eslint-disable-next-line
  }, []);

  const loading = useSelector((state) => state.upcomingLessons && state.upcomingLessons.loading);

  return !loading ? (
    <Card style={{ height: "312px" }} className={"upcomingLessonsCard"}>
      <div>
        <CardHeader title={"Upcoming lessons"} />
      </div>
      <div>
        {lessons && lessons.length > 0 ? (
          <ul className={"upcomingLessons"}>
            {lessons?.map(
              ({ time, room, title, type, contracts, teacher, teacherId, prospectStudent, lesson_note }, idx) => {
                return (
                  <li className={"upcomingLessons__lesson"} key={`upcomingLesson-${idx}`}>
                    <div className={"upcomingLessons__lesson__time"}>{format(new Date(time), "HH:mm")} |</div>
                    <div className={"upcomingLessons__lesson__room"}> {room} |</div>
                    <div className={"upcomingLessons__lesson__title"} alt={title}>
                      {type && type === "lesson" && (
                        <div className={"upcomingLessons__indicator upcomingLessons__indicator--trial"} />
                      )}
                      <Link to={`/dashboard/${schoolId}/profile/${teacherId}`}>{teacher}</Link>
                      {" | "}
                      {contracts && contracts.length > 0 ? (
                        contracts?.map((contract, index, contracts) => {
                          return (
                            <>
                              <Link
                                to={`/dashboard/${schoolId}/profile/${contract?.studentId}`}
                                style={{ fontWeight: 400 }}
                              >
                                {contract?.studentName}
                              </Link>
                              {contracts.length === index + 1 ? "" : ", "}
                            </>
                          );
                        })
                      ) : type && type === "lesson" && prospectStudent ? (
                        <Link
                          to={`/dashboard/${schoolId}/prospectProfile/${prospectStudent?.id}`}
                          style={{ fontWeight: 400 }}
                        >
                          {prospectStudent?.firstName} {prospectStudent?.lastName}
                        </Link>
                      ) : (
                        lesson_note
                      )}
                    </div>
                  </li>
                );
              }
            )}
          </ul>
        ) : (
          <Empty />
        )}
      </div>
    </Card>
  ) : (
    <Loader />
  );
};

export default UpcomingLessons;
