import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ColorfulTag, Searchbar } from "components";
import { Table } from "antd";
import { sortDates, dateFormat, withNumberingColumn } from "utils/utils";
import moment from "moment";

export const ContractTable = ({ data }) => {
  const schoolId = useSelector((state) => state?.user?.selectedSchool?.id);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState(data);
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    const results = data && data.filter((item) => item.studentName?.toLowerCase().includes(searchTerm));
    setResults(results); // eslint-disable-next-line
  }, [searchTerm, data]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const columns = [
    {
      title: "Full Name",
      dataIndex: "studentName",
      key: "studentName",
      sorter: (a, b) => (a.studentName && b.studentName ? a.studentName.localeCompare(b.studentName) : null),
      render: (text, student) => <Link to={`/dashboard/${schoolId}/profile/${student.studentId}`}>{text}</Link>,
    },
    {
      title: "Teacher",
      dataIndex: "teacherName",
      key: "teacherName",
      sorter: (a, b) => (a.teacherName && b.teacherName ? a.teacherName.localeCompare(b.teacherName) : null),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Starting Date",
      dataIndex: "startingDate",
      key: "startingDate",
      sorter: (a, b) => sortDates(a, b, "startingDate"),
      render: (date) => <span>{date === null ? "-" : moment(date).format(dateFormat)}</span>,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      sorter: (a, b) => sortDates(a, b, "endDate"),
      render: (date) => <span>{date === null ? "-" : moment(date).format(dateFormat)}</span>,
    },
    {
      title: "Instruments",
      dataIndex: "instruments",
      key: "instruments",
      sorter: (a, b) => (a.instruments && b.instruments ? a.instruments.localeCompare(b.instruments) : null),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Tarif",
      dataIndex: "tarif",
      key: "tarif",
      sorter: (a, b) => (a.tarif && b.tarif ? a.tarif.localeCompare(b.tarif) : null),
      render: (text) => <span>{text}</span>,
    },

    {
      title: "Status",
      dataIndex: "contractStatus",
      key: "contractStatus",
      sorter: (a, b) =>
        a.contractStatus && b.contractStatus ? a.contractStatus.localeCompare(b.contractStatus) : null,
      render: (contractStatus) => <ColorfulTag text={contractStatus} />,
    },
    {
      title: "Pause start",
      dataIndex: "pauseDate",
      key: "pauseDate",
      sorter: (a, b) => sortDates(a, b, "pauseDate"),
      render: (date) => <span>{date === null ? "-" : moment(date).format(dateFormat)}</span>,
    },
    {
      title: "Pause end",
      dataIndex: "outOfPauseDate",
      key: "outOfPauseDate",
      sorter: (a, b) => sortDates(a, b, "outOfPauseDate"),
      render: (date) => <span>{date === null ? "-" : moment(date).format(dateFormat)}</span>,
    },
  ];
  return (
    <>
      <Searchbar searchTerm={searchTerm} handleChange={handleSearch} setSearchTerm={setSearchTerm} />
      <Table
        bordered
        columns={withNumberingColumn(columns, pagination)}
        dataSource={results}
        onChange={(pagination) => {
          setPagination(pagination);
        }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
        }}
      />
    </>
  );
};

export default ContractTable;
