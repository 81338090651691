import React from "react";
import { Spin } from "antd";

export const Loader = ({ spaceSize, spinSize }) => {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }} size={spaceSize}>
      <Spin size={spinSize} />
    </div>
  );
};

Loader.defaultProps = {
  spaceSize: "middle",
  spinSize: "large",
};
export default Loader;
