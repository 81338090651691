import { createAction, handleActions } from "redux-actions";
import {
  getNotificationsInfo as apiGetNotificationsInfo,
  getTeacherComments as apiGetNotificationsInfoForTeacher,
  markCommentAsRead as apiMarkCommentAsRead,
} from "services/notifications";
import { openNotification } from "utils/notification";
import { getErrorMessage } from "modules/process";

/**
 * Notifications reducer
 * Handles Notifications actions
 */

// ------------------------------------
// Constants
// ------------------------------------

export const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";
export const NOTIFICATIONS_FAILED = "NOTIFICATIONS_FAILED";
export const NOTIFICATIONS_REQUEST = "NOTIFICATIONS_REQUEST";
export const NOTIFICATIONS_COMMENT_READ = "NOTIFICATIONS_COMMENT_READ";
export const NOTIFICATIONS_COMMENT_REQUEST = "NOTIFICATIONS_COMMENT_REQUEST";

// ------------------------------------
// Actions
// ------------------------------------

const notificationsSuccess = createAction(NOTIFICATIONS_SUCCESS);
const notificationsFailed = createAction(NOTIFICATIONS_FAILED);
const notificationsRequest = createAction(NOTIFICATIONS_REQUEST);
const notificationsCommentRequest = createAction(NOTIFICATIONS_COMMENT_REQUEST);
const notificationsCommentRead = createAction(NOTIFICATIONS_COMMENT_READ);

const getNotificationsInfo = () => {
  return (dispatch, getState) => {
    const selectedSchoolRoles = getState()?.user?.roles ? getState()?.user.roles : [];
    const isTeacher = selectedSchoolRoles.includes("teacher");
    // check if user role is only teacher then show teacher's comments. In any other case show all comments.
    const apiAction =
      isTeacher && selectedSchoolRoles?.length === 1 ? apiGetNotificationsInfoForTeacher : apiGetNotificationsInfo;
    dispatch(notificationsRequest());
    apiAction()
      .then((response) => dispatch(notificationsSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(notificationsFailed(error));
      });
  };
};

const markCommentAsRead = (cid, type, userId) => {
  return (dispatch) => {
    dispatch(notificationsCommentRequest(cid));
    apiMarkCommentAsRead(cid, type, userId)
      .then(() => {
        dispatch(notificationsCommentRequest(undefined));
        dispatch(notificationsCommentRead(cid));
      })
      .catch((error) => {
        console.log(error);
        dispatch(notificationsCommentRequest());
        openNotification("error", getErrorMessage(error));
      });
  };
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  getNotificationsInfo,
  markCommentAsRead,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  notifications: [],
  errors: null,
  loading: false,
  isReadCommentIdLoading: null,
  count: 0,
};

export default handleActions(
  {
    NOTIFICATIONS_REQUEST: () => {
      return { ...initialState, loading: true };
    },

    NOTIFICATIONS_SUCCESS: (state, { payload }) => {
      return {
        ...initialState,
        loading: false,
        notifications: payload?.data || [],
        count: payload?.total_unread,
      };
    },

    NOTIFICATIONS_COMMENT_READ: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        count: state?.count - 1 || 0,
        notifications: state?.notifications?.map?.((i) => {
          return {
            ...i,
            comments: i?.comments?.map?.((c) => {
              return {
                ...c,
                isRead: c.comment_cid === payload ? true : c.isRead,
                isNew: c.comment_cid === payload ? true : c.isRead,
              };
            }),
          };
        }),
      };
    },

    NOTIFICATIONS_COMMENT_REQUEST: (state, { payload }) => {
      return {
        ...state,
        isReadCommentIdLoading: payload,
      };
    },

    NOTIFICATIONS_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },
  },
  initialState
);
