import axios from "axios";
import { apiURL } from "utils/config";
import { getAuthorizationHeaders } from "./auth";

const url = `${apiURL}/user`;

export const getUser = () => {
  return axios({
    url: `${url}`,
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};
export const resetPassword = (mail) => {
  return axios({
    url: `${url}/password/reset`,
    method: "POST",
    data: { mail },
    headers: getAuthorizationHeaders(),
  });
};

export const requestPasswordChange = (mail) => {
  return axios({
    url: `${apiURL}/password/reset`,
    method: "POST",
    data: { mail },
    headers: getAuthorizationHeaders(),
  });
};
