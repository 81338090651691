import { actions as accountActions } from "modules/account";
import CookieHelper from "utils/cookie";
import {
  clearAuthInfo,
  pushToQueue,
  loggedIn,
  getFirstTimeFail,
  setFirstTimeFail,
  refreshToken,
  setAuthInfo,
  isSavedTokenExpired,
  replayQueuedRequests,
} from "./auth";
import axios from "axios";
import store from "../store";

const clearAuth = () => {
  clearAuthInfo();

  store.dispatch(accountActions.logout());
};

const checkTokenActions = (error, resolve, reject) => {
  let objToReplay = {
    initialRequest: error.config,
    resolve: resolve,
    reject: reject,
  };

  pushToQueue(objToReplay);

  if (!getFirstTimeFail()) {
    setFirstTimeFail(true);
    refreshToken().then(
      function (response) {
        setAuthInfo(response.data);
        setTimeout(() => {
          replayQueuedRequests();
        }, 200);
      },
      () => {
        clearAuth();
      }
    );
  }
};

// Handle API request errors
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return new Promise((resolve, reject) => {
      const authInfo = JSON.parse(CookieHelper.load("auth"));
      if (error && !error.response) {
        if (loggedIn()) {
          clearAuth();
        }
        // Alert.error("Some error happened, please try again later");
      } else if (error.response.status === 401 && !authInfo?.expiresOn) {
        clearAuth();
        if (error.config.showErrors) {
          reject(error);
        }
      } else if (error.response.status === 401 && isSavedTokenExpired()) {
        checkTokenActions(error, resolve, reject);
      } else if (error.response.status === 401) {
        if (loggedIn()) {
          clearAuth();
        } else {
          clearAuth();
          reject(error);
        }
      } else {
        if (error.config.clearCredentialsOnError) {
          clearAuth();
        }

        reject(error);
      }
    });
  }
);
