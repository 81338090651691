import { createAction, handleActions } from "redux-actions";
import { getProfile as apiGetProfile } from "services/profile";
import { openNotification } from "../utils/notification";
import { getErrorMessage } from "modules/process";

/**
 * Profile reducer
 * Handles profiles actions
 */

// ------------------------------------
// Constants
// ------------------------------------
// Profile Constants
export const PROFILE_LOADED = "PROFILE_LOADED";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAILED = "PROFILE_FAILED";
// Teacher Constants
export const GET_STUDENTS_REQUEST = "GET_STUDENTS_REQUEST";
export const PROFILE_REQUEST = "PROFILE_REQUEST";

// ------------------------------------
// Actions
// ------------------------------------

//Profile Actions
const profileLoaded = createAction(PROFILE_LOADED);
const profileSuccess = createAction(PROFILE_SUCCESS);
const profileFailed = createAction(PROFILE_FAILED);
const profileRequest = createAction(PROFILE_REQUEST);

// types
export const TYPE_USER_LOAD = "user:load";

const getProfile = (id) => {
  return (dispatch) => {
    dispatch(profileRequest());
    apiGetProfile(id)
      .then((response) => dispatch(profileSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(profileFailed(error));
      });
  };
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  profileLoaded,
  getProfile,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  loading: false,
  profileInfo: {
    firstName: null,
    lastName: null,
    gender: null,
    id: null,
    address: null,
    language: null,
    instrument: null,
    mobile: null,
    profileMail: null,
    profilePhoto: null,
    telephone: null,
    title: null,
    userMail: null,
    roles: [],
    comments: [],
  },
};

export default handleActions(
  {
    PROFILE_LOADED: (state) => {
      return {
        ...state,
      };
    },

    PROFILE_REQUEST: (state) => {
      return { ...initialState, loading: true };
    },

    PROFILE_SUCCESS: (state, { payload }) => {
      return {
        ...state,

        profileInfo: {
          firstName: payload.firstName,
          lastName: payload.lastName,
          gender: payload.gender,
          id: payload.id,
          address: payload.location,
          language: payload.language,
          instrument: payload.instrument,
          mobile: payload.mobile,
          profileMail: payload.profileMail,
          profilePhoto: payload.profilePhoto,
          telephone: payload.telephone,
          title: payload.title,
          userMail: payload.userMail,
          dob: payload.dob,
          roles: [...payload.roles],
          comments: [...payload.comments],
        },
        loading: false,
      };
    },

    PROFILE_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },
  },

  initialState
);
