import React, { useEffect } from "react";
import { Form, Input, Button } from "antd";

export const LetterForm = ({ loading, letterInformationData, letterMessageData, form, callback }) => {
  useEffect(() => {
    form.setFieldsValue({
      letter_editor: {
        field_contract_letter_subject: letterMessageData.subject,
        field_contract_letter_message: letterMessageData.message,
      },
    });
  }, [letterMessageData]);

  return (
    <>
      <Form.Item name={["letter_editor", "field_contract_letter_subject"]} label="Subject">
        <Input />
      </Form.Item>
      <Form.Item name={["letter_editor", "field_contract_letter_message"]} label="Message">
        <Input.TextArea autoSize={{ minRows: 2, maxRows: 20 }} />
      </Form.Item>
      <div style={{ height: 30 }}>
        <Button
          danger
          key="cancel"
          loading={loading}
          className="buttonWithFloatRounded__buttons--navigation--floatLeft"
          onClick={callback}
        >
          Cancel
        </Button>

        <Button
          type="primary"
          key="submit"
          htmlType="submit"
          loading={loading}
          className="buttonWithFloatRounded__buttons--navigation--floatRight"
        >
          View and print
        </Button>
      </div>
    </>
  );
};
export default LetterForm;
