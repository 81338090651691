import axios from "axios";
import { apiURL } from "utils/config";
import { getAuthorizationHeaders } from "./auth";
import store from "../store";

/**
 * Returns Alerts for contracts
 * @returns {AxiosPromise}
 */

export const getStudentOverview = (id) => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (schoolID, profileId) => `${apiURL}/school/${schoolID}/profile/${profileId}/studentOverview`;
  return axios({
    url: url(schoolId, id),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};
