import { createAction, handleActions } from "redux-actions";
import { getUpcomingLessons as apiGetUpcomingLessons } from "services/upcomingLessons";
import { openNotification } from "../utils/notification";
import { getErrorMessage } from "modules/process";

/**
 * Birthdays reducer
 * Handles Birthdays actions
 */

// ------------------------------------
// Constants
// ------------------------------------

export const UPCOMING_LESSONS_SUCCESS = "UPCOMING_LESSONS_SUCCESS";
export const UPCOMING_LESSONS_FAILED = "UPCOMING_LESSONS_FAILED";
export const UPCOMING_LESSONS_REQUEST = "UPCOMING_LESSONS_REQUEST";

// ------------------------------------
// Actions
// ------------------------------------

const upcomingLessonsSuccess = createAction(UPCOMING_LESSONS_SUCCESS);
const upcomingLessonsFailed = createAction(UPCOMING_LESSONS_FAILED);
const upcomingLessonsRequest = createAction(UPCOMING_LESSONS_REQUEST);

const getUpcomingLessons = () => {
  return (dispatch) => {
    dispatch(upcomingLessonsRequest());
    return apiGetUpcomingLessons()
      .then((response) => dispatch(upcomingLessonsSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(upcomingLessonsFailed(error));
      });
  };
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  getUpcomingLessons,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  errors: null,
  loading: false,
};

export default handleActions(
  {
    UPCOMING_LESSONS_REQUEST: (state) => {
      return { ...initialState, loading: true };
    },

    UPCOMING_LESSONS_SUCCESS: (state) => {
      return {
        ...initialState,
        loading: false,
      };
    },

    UPCOMING_LESSONS_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },
  },
  initialState
);
