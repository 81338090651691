import { EditOutlined } from "@ant-design/icons";
import EditTeacherAgreement from "components/EditTeacherAgreement";
import { Loader } from "components/index";
import useRoles from "hooks/useRoles";
import { getErrorMessage } from "modules/process";
import React, { useEffect } from "react";
import { Button, Table } from "antd";
import { getTeacherAgreements as apiGetTeacherAgreements } from "services/profile";
import { openNotification } from "utils/notification";

const TeacherAgreements = ({ profileId }) => {
  const [loading, setLoading] = React.useState(false);
  const [teacherAgreements, setTeacherAgreements] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState();
  const [editOpen, setEditOpen] = React.useState(false);
  const { isHQAdmin, isHQStaff } = useRoles();

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await apiGetTeacherAgreements(profileId);
      setTeacherAgreements(data?.map((item, index) => ({ ...item, key: index })));
    } catch (error) {
      openNotification("error", getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [profileId]);

  const teacherAgreementsCol = [
    {
      title: "Tarif",
      dataIndex: "title",
      key: "title",
      render: (title) => <span>{title}</span>,
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
      render: (percentage) => <span>{percentage === null ? "" : percentage}</span>,
    },
    {
      title: "Pay",
      dataIndex: "pay",
      key: "pay",
      render: (pay) => <span>{pay}</span>,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
      render: (paymentType) => <span>{paymentType}</span>,
    },

    {
      title: "",
      dataIndex: "teacher_tarif_nid",
      key: "teacher_tarif_nid",
      render: (teacher_tarif_nid, row) =>
        isHQAdmin || isHQStaff ? (
          <Button
            type={"primary"}
            icon={<EditOutlined />}
            size={"small"}
            onClick={(event) => {
              event.stopPropagation();
              setSelectedItem(row);
              setEditOpen(true);
            }}
          />
        ) : (
          <></>
        ),
    },
  ];
  if (loading) return <Loader />;
  return (
    <>
      <Table columns={teacherAgreementsCol} dataSource={teacherAgreements} pagination={false} />
      <EditTeacherAgreement setVisible={setEditOpen} visible={editOpen} item={selectedItem} callback={fetchData} />
    </>
  );
};

export default TeacherAgreements;
