import { createAction, handleActions } from "redux-actions";
import { getContractInfo as apiGetContractInfo } from "services/contractInfo";
import { openNotification } from "../utils/notification";
import { getErrorMessage } from "modules/process";

/**
 * ContractInfo reducer
 * Handles ContractInfo actions
 */

// ------------------------------------
// Constants
// ------------------------------------

export const CONTRACT_INFO_SUCCESS = "CONTRACT_INFO_SUCCESS";
export const CONTRACT_INFO_FAILED = "CONTRACT_INFO_FAILED";
export const CONTRACT_INFO_REQUEST = "CONTRACT_INFO_REQUEST";

// ------------------------------------
// Actions
// ------------------------------------

const contractInfoSuccess = createAction(CONTRACT_INFO_SUCCESS);
const contractInfoFailed = createAction(CONTRACT_INFO_FAILED);
const contractInfoRequest = createAction(CONTRACT_INFO_REQUEST);

const getContractInfo = (id) => {
  return (dispatch) => {
    dispatch(contractInfoRequest());
    apiGetContractInfo(id)
      .then((response) => dispatch(contractInfoSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(contractInfoFailed(error));
      });
  };
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  getContractInfo,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  contractInfo: {},
  errors: null,
  loading: false,
};

export default handleActions(
  {
    CONTRACT_INFO_REQUEST: (state) => {
      return { ...initialState, loading: true };
    },

    CONTRACT_INFO_SUCCESS: (state, { payload }) => {
      return {
        ...initialState,
        loading: false,
        contractInfo: payload,
      };
    },

    CONTRACT_INFO_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },
  },
  initialState
);
