import { baseURL, authKey } from "utils/config";
import CookieHelper from "utils/cookie";
import axios from "axios";
import { addSeconds, compareAsc } from "date-fns";

const loginUrl = baseURL + "/oauth/token";
const logoutUrl = baseURL + "/oauth/access-token";

/*let authObj = {
  isLoggedIn: false,
  access_token: null,
  token_type: null,
  refresh_token: null,
  expires_in: null,
  scope: "",
};*/

let firstTimeFail = false;
let requestsQueue = [];

export let defaultHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: authKey,
};

export let loginHeaders = {
  Accept: "application/json",
  "Content-Type": undefined,
  Authorization: authKey,
};

export const isSavedTokenExpired = (authInfo = JSON.parse(CookieHelper.load("auth"))) => {
  let hasExpired = false;
  let now = new Date();

  if (authInfo && authInfo.expiresOn && compareAsc(now, new Date(authInfo.expiresOn)) === 1) {
    console.log("token has expired. i will refresh it");
    hasExpired = true;
  }

  return hasExpired;
};

export const loggedIn = (authInfo = CookieHelper.load("auth")) => {
  let isLoggedIn = false;
  if (authInfo) {
    isLoggedIn = JSON.parse(authInfo).isLoggedIn;
  }
  return isLoggedIn;
};

export const login = (username, password) => {
  let data = new FormData();

  data.append("username", username);
  data.append("password", password);
  data.append("grant_type", "password");
  //data.append("scope", "write");
  data.append("client_id", process.env.REACT_APP_CLIENT_ID);

  return axios({
    url: loginUrl,
    method: "POST",
    data: data,
    showErrors: true,
    headers: loginHeaders,
  });
};

export const refreshToken = (authInfo = CookieHelper.load("auth")) => {
  let data = new FormData();

  data.append("grant_type", "refresh_token");
  data.append("refresh_token", JSON.parse(authInfo).refresh_token);
  data.append("client_id", process.env.REACT_APP_CLIENT_ID);
  data.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);

  return axios({
    url: loginUrl,
    method: "POST",
    data: data,
    showErrors: true,
    clearCredentialsOnError: true,
    headers: defaultHeaders,
  });
};

export const logout = () => {
  return axios({
    url: logoutUrl,
    method: "DELETE",
    showErrors: true,
    headers: getAuthorizationHeaders(),
  });
};

export const setAuthInfo = (authObj) => {
  authObj.isLoggedIn = true;
  authObj.expiresOn = addSeconds(new Date(), authObj.expires_in);
  CookieHelper.save("auth", JSON.stringify(authObj), 30);
};

export const clearAuthInfo = () => {
  CookieHelper.remove("auth");
};

export const getAuthorizationHeaders = (authInfo = CookieHelper.load("auth")) => {
  let authHeaderValue = null;

  if (authInfo) {
    authHeaderValue = JSON.parse(authInfo).access_token;
  }

  return Object.assign({}, getDefaultHeaders(), {
    Authorization: "Bearer " + authHeaderValue,
  });
};

export const getDefaultHeaders = () => {
  return defaultHeaders;
};

export const pushToQueue = (promise) => {
  requestsQueue.push(promise);
};

export const clearQueue = () => {
  requestsQueue = [];
};

export const replayQueuedRequests = () => {
  let req = requestsQueue;
  for (let i = 0; i < req.length; i++) {
    req[i].initialRequest.headers = getAuthorizationHeaders();
    // Replay request
    axios(req[i].initialRequest)
      .then((response) => {
        req[i].resolve(response);
      })
      .catch((response) => {
        req[i].reject(response);
      });
  }

  clearQueue();
  setFirstTimeFail(false);
};

export const getFirstTimeFail = () => {
  return firstTimeFail;
};

export const setFirstTimeFail = (value) => {
  firstTimeFail = value;
};
