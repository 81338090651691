import { createAction, handleActions } from "redux-actions";
import { getFormData as apiGetFormData, getEntityData as apiGetEntityData } from "services/formFields";
import { openNotification } from "../utils/notification";
import { getErrorMessage } from "modules/process";
import { editItem as apiEditItem, deleteItem as apiDeleteItem, postItem as apiPostItem } from "services/formFields";

/**
 * Form Fields reducer
 * Handles form fields actions
 */

// ------------------------------------
// Constants
// ------------------------------------

export const FORM_FIELDS_SUCCESS = "FORM_FIELDS_SUCCESS";
export const FORM_FIELDS_FAILED = "FORM_FIELDS_FAILED";
export const FORM_FIELDS_REQUEST = "FORM_FIELDS_REQUEST";

export const EDIT_ITEM_SUCCESS = "EDIT_ITEM_SUCCESS";
export const EDIT_ITEM_FAILED = "EDIT_ITEM_FAILED";
export const EDIT_ITEM_REQUEST = "EDIT_ITEM_REQUEST";

export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAILED = "DELETE_ITEM_FAILED";
export const DELETE_ITEM_REQUEST = "DELETE_ITEM_REQUEST";

export const POST_NEW_ITEM_REQUEST = "POST_NEW_ITEM_REQUEST";
export const POST_NEW_ITEM_SUCCESS = "POST_NEW_ITEM_SUCCESS";
export const POST_NEW_ITEM_FAILED = "POST_NEW_ITEM_FAILED";

export const ENTITY_REQUEST = "ENTITY_REQUEST";
export const ENTITY_SUCCESS = "ENTITY_SUCCESS";
export const ENTITY_FAILED = "ENTITY_FAILED";

export const PATCH_ITEM_REQUEST = "PATCH_ITEM_REQUEST";
export const PATCH_ITEM_SUCCESS = "PATCH_ITEM_SUCCESS";
export const PATCH_ITEM_FAILED = "PATCH_ITEM_FAILED";

export const ITEM_CHANGED = "ITEM_CHANGED";

// ------------------------------------
// Actions
// ------------------------------------

const formFieldsSuccess = createAction(FORM_FIELDS_SUCCESS);
const formFieldsFailed = createAction(FORM_FIELDS_FAILED);
const formFieldsRequest = createAction(FORM_FIELDS_REQUEST);

const entityRequest = createAction(ENTITY_REQUEST);
const entitySuccess = createAction(ENTITY_SUCCESS);
const entityFailed = createAction(ENTITY_FAILED);

const editItemSuccess = createAction(EDIT_ITEM_SUCCESS);
const editItemFailed = createAction(EDIT_ITEM_FAILED);
const editItemRequest = createAction(EDIT_ITEM_REQUEST);

const deleteItemSuccess = createAction(DELETE_ITEM_SUCCESS);
const deleteItemFailed = createAction(DELETE_ITEM_FAILED);
const deleteItemRequest = createAction(DELETE_ITEM_REQUEST);

const postNewItemRequest = createAction(POST_NEW_ITEM_REQUEST);
const postNewItemSuccess = createAction(POST_NEW_ITEM_SUCCESS);
const postNewItemFailed = createAction(POST_NEW_ITEM_FAILED);

const editItem = (data, id) => {
  return (dispatch) => {
    dispatch(editItemRequest());
    return apiEditItem(data, id)
      .then((response) => {
        dispatch(editItemSuccess(response.data));
        openNotification("success", "Item edited successfully");
        return response.data;
      })
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(editItemFailed(error));
      });
  };
};

const deleteItem = (data, id) => {
  return (dispatch) => {
    dispatch(deleteItemRequest());
    return apiDeleteItem(data, id)
      .then((response) => {
        dispatch(deleteItemSuccess(response.data));
        openNotification("success", "Item deleted successfully");
      })
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(deleteItemFailed(error));
      });
  };
};

const getFormData = (dataType) => {
  return (dispatch) => {
    dispatch(formFieldsRequest());
    apiGetFormData(dataType)
      .then((response) => dispatch(formFieldsSuccess({ dataType: dataType, data: response.data })))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(formFieldsFailed(error));
      });
  };
};

const getEntityData = (dataType) => {
  return (dispatch) => {
    dispatch(entityRequest());
    apiGetEntityData(dataType)
      .then((response) => dispatch(entitySuccess({ dataType: dataType, data: response.data })))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(entityFailed(error));
      });
  };
};

const postNewItem = (data) => {
  return (dispatch) => {
    dispatch(postNewItemRequest());
    return apiPostItem(data)
      .then((response) => {
        dispatch(postNewItemSuccess(response.data));
        openNotification("success", "New item added successfully");
        return response.data;
      })
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(postNewItemFailed(error));
      });
  };
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  postNewItem,
  getFormData,
  getEntityData,
  editItem,
  deleteItem,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  errors: null,
  loading: false,
};

export default handleActions(
  {
    FORM_FIELDS_REQUEST: (state) => {
      return { ...initialState, loading: true };
    },

    ENTITY_REQUEST: (state) => {
      return { ...initialState, loading: true };
    },

    FORM_FIELDS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        [payload.dataType]: [...payload.data],
      };
    },

    ENTITY_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        [payload.dataType]: [...payload.data],
      };
    },

    FORM_FIELDS_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },
    ENTITY_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },
  },
  initialState
);
