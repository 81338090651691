import axios from "axios";
import { apiURL } from "utils/config";
import { getAuthorizationHeaders } from "./auth";
import store from "../store";

/**
 * Returns Profile for SelectedSchool
 * @returns {AxiosPromise}
 */

export const getProspectProfile = (profileID) => {
  let schoolID = store.getState().user.selectedSchool.id;
  const url = (schoolId, profileId) => `${apiURL}/school/${schoolId}/prospect/${profileId}`;
  return axios({
    url: url(schoolID, profileID),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};
