import { notification } from "antd";

const NOTIFICATION_TITLES = {
  success: "Success",
  info: "Info",
  warning: "Warning",
  error: "Error",
};

/**
 * opens a notification using ant design
 * @param type | success, info, warning, error
 * @param message
 */
export const openNotification = (type, message) => {
  notification[type]({
    message: NOTIFICATION_TITLES[type],
    description: message,
    placement: "bottomRight",
    duration: 2,
  });
};
