import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { ColorfulTag } from "components";
import { CardChart } from "components";
import { PieChart, Pie, Legend, Cell } from "recharts";
import { colors } from "utils/config";
import { dateFormat, sortDates, sortStrings, withNumberingColumn } from "utils/utils";
import moment from "moment";

const TeacherTrials = () => {
  const teacherTrials = useSelector((state) =>
    state?.teachersProfile?.teacherTrials.filter((trial) => trial.status !== "In Process")
  );
  const schoolId = useSelector((state) => state?.user?.selectedSchool?.id);

  const teacherTrialsMapped = teacherTrials.map((trial, index) => {
    return {
      order: index,
      prospectId: trial.prospectId,
      student: trial.student,
      date: trial.date,
      status: trial.status,
      room: trial.room,
      key: index,
    };
  });

  const pieData = [];
  const statusData = [];
  teacherTrialsMapped &&
    teacherTrialsMapped.reduce(function (res, value) {
      if (!res[value.status]) {
        res[value.status] = { name: value.status, value: 0 };
        pieData.push(res[value.status]);
        statusData.push(res[value.status]);
      }
      res[value.status].value++;

      return res;
    }, {});

  // const allArchivedTrials = (accum, currentValue) => {
  //   if (currentValue.name.includes("Archived")) {
  //     return accum + currentValue.value;
  //   } else {
  //     return accum;
  //   }
  // };

  // const allTrials = (accum, currentValue) => {
  //   return accum + currentValue.value;
  // };

  // const totalArchived = pieData.reduce(allArchivedTrials, 0);
  // const totalTrials = pieData.reduce(allTrials, 0);

  const teacherTrialsCol = [
    {
      title: "Student",
      dataIndex: "student",
      key: "student",
      sorter: (a, b) => sortStrings(a, b, "student"),
      render: (student, { prospectId }) => {
        return <Link to={`/dashboard/${schoolId}/prospectProfile/${prospectId}`}>{`${student} `}</Link>;
      },
    },
    {
      title: "Prospect Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => sortStrings(a, b, "status"),
      render: (status) => (
        <p>
          <ColorfulTag text={status} />
        </p>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => sortDates(a, b, "date"),
      render: (date) => {
        return moment(date).format(dateFormat);
      },
    },

    {
      title: "Room",
      dataIndex: "room",
      key: "room",
      sorter: (a, b) => sortStrings(a, b, "room"),
      render: (room) => <p>{room}</p>,
    },
  ];
  return (
    <>
      <CardChart
        chart={
          <PieChart>
            <Pie dataKey="value" data={pieData} innerRadius={40} outerRadius={70} paddingAngle={5}>
              {" "}
              {pieData?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index]} />
              ))}
            </Pie>
            <Legend />
          </PieChart>
        }
      />
      <div className={"stats"}>
        {statusData?.map(({ name, value }) => {
          return (
            <div className={"stats__item"}>
              {" "}
              <span className={"stats__item__title"}>
                <span className={"stats__item__title__number"}> {value} </span>
                {name?.toLowerCase()} Prospects{" "}
              </span>
              <span className={"stats__item__percentage"}>{`${((value / teacherTrialsMapped?.length) * 100).toFixed(
                1
              )} %`}</span>{" "}
            </div>
          );
        })}
      </div>
      <Table columns={withNumberingColumn(teacherTrialsCol)} dataSource={teacherTrialsMapped} pagination={false} />
    </>
  );
};

export default TeacherTrials;
