import React from "react";
import { Button, Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { monthsArr, yearsArr } from "utils/config";
import { isMobile } from "utils/utils";

export const DatePick = ({ month, setMonth, year, setYear, dispatchActionOnClick, isMobile }) => {
  const monthsMenu = (months) => (
    <Menu>
      {months.map((month, index) => (
        <Menu.Item key={index} onClick={() => setMonth(month)}>
          <p>{month}</p>
        </Menu.Item>
      ))}
    </Menu>
  );

  const yearsMenu = (years) => (
    <Menu>
      {years.map((year, index) => (
        <Menu.Item key={index} onClick={() => setYear(year)}>
          <p>{year}</p>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div style={{ margin: "0px 0px" }}>
      <Dropdown overlay={monthsMenu(monthsArr)} trigger={["click"]}>
        <a style={{ margin: "0 10px", fontSize: "1.2em" }} href="/#" onClick={(e) => e.preventDefault()}>
          {month} <DownOutlined />
        </a>
      </Dropdown>
      <Dropdown overlay={yearsMenu(yearsArr)} trigger={["click"]}>
        <a style={{ margin: "0 10px", fontSize: "1.2em" }} href="/#" onClick={(e) => e.preventDefault()}>
          {year} <DownOutlined />
        </a>
      </Dropdown>

      <Button
        style={{ margin: "10px 0" }}
        type="primary"
        onClick={dispatchActionOnClick}
        size={isMobile ? "small" : "large"}
      >
        Search
      </Button>
    </div>
  );
};

export default DatePick;
