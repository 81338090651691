import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ColorfulTag } from "components";
import { Table } from "antd";
import { withNumberingColumn, sortStrings, dateFormat, sortDates } from "utils/utils";
import moment from "moment";

export const KkGroupsAndContractsTable = ({ data, type }) => {
  const [results, setResults] = useState(data);
  const [pagination, setPagination] = useState(null);
  const [paginationContracts, setPaginationContracts] = useState(null);
  const [paginationCompleted, setPaginationCompleted] = useState(null);
  const [paginationToCancel, setPaginationToCancel] = useState(null);
  const [paginationBdayStudent, setPaginationBdaySudent] = useState(null);
  const [paginationBdayParent, setPaginationBdayParent] = useState(null);

  const handleColumnSelection = () => {
    switch (type) {
      case "group":
        return withNumberingColumn(columnsGroups, pagination);
      case "contract":
        return withNumberingColumn(columnsContracts, paginationContracts);
      case "to_cancel_students":
        return withNumberingColumn(columnsToCancel, paginationToCancel);
      case "completed":
        return withNumberingColumn(columnsContracts, paginationCompleted);
      case "bdayStudent":
        return withNumberingColumn(columnsBdayStudent, paginationBdayStudent);
      case "bdayParent":
        return withNumberingColumn(columnsBdayParent, paginationBdayParent);
    }
  };

  const handlePaginationChange = (pagination) => {
    switch (type) {
      case "group":
        return setPagination(pagination);
      case "contract":
        return setPaginationContracts(pagination);
      case "to_cancel_students":
        return setPaginationToCancel(pagination);
      case "completed":
        return setPaginationCompleted(pagination);
      case "bdayStudent":
        return setPaginationBdaySudent(pagination);
      case "bdayParent":
        return setPaginationBdayParent(pagination);
    }
  };

  const columnsGroups = [
    {
      title: "Course title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => sortStrings(a, b, "title"),
      render: (text, { id }) => {
        return <Link to={`/dashboard/klingKlong/group/${id}`}>{text}</Link>;
      },
    },
    {
      title: "Course level",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => sortStrings(a, b, "type"),
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Teacher",
      dataIndex: "teacher",
      key: "teacher",
      sorter: (a, b) =>
        a.teacher?.title && b.teacher?.title ? a.teacher?.title?.localeCompare(b.teacher?.title) : null,
      render: (teacher) => <span>{teacher?.title}</span>,
    },
    {
      title: "Number of children",
      dataIndex: "students",
      key: "students",
      sorter: (a, b) => {
        let studentsA = a.students?.toString();
        let studentsB = b.students?.toString();
        if (studentsA && studentsB) {
          return studentsA?.localeCompare(studentsB, undefined, { numeric: true });
        }
      },
      render: (students) => <span>{students}</span>,
    },
  ];

  const columnsContracts = [
    {
      title: "Student Name",
      dataIndex: "students",
      key: "students",
      sorter: (a, b) =>
        a.students?.fullname && b.students?.fullname ? a.students?.fullname?.localeCompare(b.students?.fullname) : null,
      render: (students) => <span>{students?.fullname}</span>,
    },
    {
      title: "Parent Name",
      dataIndex: "students",
      key: "students",
      sorter: (a, b) =>
        a.students?.parent?.fullname && b.students?.parent?.fullname
          ? a.students?.parent?.fullname?.localeCompare(b.students?.parent?.fullname)
          : null,
      render: (students) => {
        return <Link to={`/dashboard/klingKlong/parent/${students?.parent?.id}`}>{students?.parent?.fullname}</Link>;
      },
    },
    {
      title: "Course",
      dataIndex: "course",
      key: "course",
      sorter: (a, b) => (a.course?.title && b.course?.title ? a.course?.title?.localeCompare(b.course?.title) : null),
      render: (course) => {
        return <Link to={`/dashboard/klingKlong/group/${course?.id}`}>{course?.title}</Link>;
      },
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   sorter: (a, b) => sortStrings(a, b, "status"),
    //   render: (status) => <ColorfulTag text={status} />,
    // },
  ];

  const columnsToCancel = [
    {
      title: "Student Name",
      dataIndex: "fullname",
      key: "fullname",
      sorter: (a, b) => (a?.fullname && b?.fullname ? a?.fullname?.localeCompare(b?.fullname) : null),
      render: (fullname) => <span>{fullname}</span>,
    },
    {
      title: "Parent Name",
      dataIndex: "parent",
      key: "parent",
      sorter: (a, b) =>
        a?.parent?.fullname && b?.parent?.fullname ? a?.parent?.fullname?.localeCompare(b?.parent?.fullname) : null,
      render: (parent) => {
        return <Link to={`/dashboard/klingKlong/parent/${parent?.id}`}>{parent?.fullname}</Link>;
      },
    },
  ];

  const columnsBdayStudent = [
    {
      title: "Student Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => (a.first_name && b.first_name ? a.first_name?.localeCompare(b.first_name) : null),
      render: (first_name, student) => (
        <span>
          {first_name} {student?.last_name}
        </span>
      ),
    },
    {
      title: "Parent Name",
      dataIndex: "parent",
      key: "parent",
      sorter: (a, b) =>
        a.parent?.fullname && b.parent?.fullname ? a.parent?.fullname?.localeCompare(b.parent?.fullname) : null,
      render: (parent) => {
        return <Link to={`/dashboard/klingKlong/parent/${parent?.id}`}>{parent?.fullname}</Link>;
      },
    },
    {
      title: " Birthday",
      dataIndex: "full_dob",
      key: "full_dob",
      sorter: (a, b) => sortDates(a, b, "full_dob"),
      render: (full_dob) => {
        return moment(full_dob).format(dateFormat);
      },
    },
  ];

  const columnsBdayParent = [
    {
      title: "Parent Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => (a.first_name && b.first_name ? a.first_name?.localeCompare(b.first_name) : null),
      render: (first_name, parent) => (
        <Link to={`/dashboard/klingKlong/parent/${parent?.profileId}`}>
          {first_name} {parent?.last_name}
        </Link>
      ),
    },
    {
      title: "Students",
      dataIndex: "students",
      key: "students",
      render: (students) => {
        return students?.map((student) => {
          return `${student.first_name} ${student.last_name}, `;
        });
      },
    },
    {
      title: " Birthday",
      dataIndex: "full_dob",
      key: "full_dob",
      sorter: (a, b) => sortDates(a, b, "full_dob"),
      render: (full_dob) => {
        return moment(full_dob).format(dateFormat);
      },
    },
  ];

  return (
    <>
      <Table
        bordered
        columns={handleColumnSelection()}
        dataSource={results}
        onChange={(pagination) => {
          handlePaginationChange(pagination);
        }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
        }}
      />
    </>
  );
};

export default KkGroupsAndContractsTable;
