/**
 * Combine reducers
 */

import { combineReducers } from "redux";
import account from "./account";
import process from "./process";
import user from "./user";
import contracts from "./contracts";
import teachers from "./teachers";
import profile from "./profile";
import teachersProfile from "./teachersProfile";
import teachersPayments from "./teachersPayments";
import prospects from "./prospects";
import billing from "./billing";
import birthdays from "./birthdays";
import roomplan from "./roomplan";
import upcomingLessons from "./upcomingLessons";
import alerts from "./alerts";
import contractStats from "./contractStats";
import studentProfile from "./studentProfile";
import contractInfo from "./contractInfo";
import billingManagment from "./billingManagment";
import prospectInfo from "./prospectInfo";
import prospectHistory from "./prospectHistory";
import formFields from "./formFields";
import prospectProfile from "./prospectProfile";
import notifications from "./notifications";
import editNode from "./editNode";

export default combineReducers({
  account,
  process,
  user,
  contracts,
  teachers,
  profile,
  teachersProfile,
  teachersPayments,
  prospects,
  billing,
  birthdays,
  roomplan,
  upcomingLessons,
  alerts,
  contractStats,
  studentProfile,
  contractInfo,
  billingManagment,
  prospectInfo,
  prospectHistory,
  formFields,
  prospectProfile,
  notifications,
  editNode,
});
