import { useSelector } from "react-redux";

export default function useRoles() {
  const selectedSchoolRoles = useSelector((state) => (state?.user?.roles ? state.user.roles : []));
  const drupalRoles = useSelector((state) => (state?.user?.drupalRoles ? state.user.drupalRoles : []));
  const teacherId = useSelector((state) => (state?.user?.id ? state.user.id : null));
  const isTeacher = selectedSchoolRoles.includes("teacher");
  const isFranchisee = selectedSchoolRoles.includes("franchisee");
  const isStaff = selectedSchoolRoles.includes("staff");
  const isHQStaff = drupalRoles.includes("hq_staff");
  const isHQAdmin = drupalRoles.includes("hq_admin");
  const isKKTeacher = drupalRoles.includes("kling_klong");

  return { isTeacher, teacherId, isFranchisee, isStaff, isHQStaff, isKKTeacher, isHQAdmin };
}
