import axios from "axios";
import { apiURL } from "utils/config";
import { getAuthorizationHeaders } from "./auth";
import store from "../store";

export const getContracts = () => {
  let schoolId = store.getState().user.selectedSchool.id;
  return axios({
    url: `${apiURL}/school/${schoolId}/contract`,
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getKkContractsGroups = () => {
  let schoolId = store.getState().user.selectedSchool.id;
  return axios({
    url: `${apiURL}/school/${schoolId}/dashboard/klingklong`,
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getKkBirthdaysStudent = () => {
  let schoolId = store.getState().user.selectedSchool.id;
  return axios({
    url: `${apiURL}/school/${schoolId}/dashboard/klingklong-birthdays-student`,
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getKkBirthdaysParent = () => {
  let schoolId = store.getState().user.selectedSchool.id;
  return axios({
    url: `${apiURL}/school/${schoolId}/dashboard/klingklong-birthdays-parent`,
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getContractsWithFilter = (query) => {
  let schoolId = store.getState().user.selectedSchool.id;
  if (query === "active") {
    return axios({
      url: `${apiURL}/school/${schoolId}/contract?status=${query}&dashboard=true`,
      method: "GET",
      headers: getAuthorizationHeaders(),
    });
  } else {
    return axios({
      url: `${apiURL}/school/${schoolId}/contract?status=${query}`,
      method: "GET",
      headers: getAuthorizationHeaders(),
    });
  }
};

export const getExpiredProfiles = () => {
  let schoolId = store.getState().user.selectedSchool.id;
  return axios({
    url: `${apiURL}/school/${schoolId}/expiredProfiles`,
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const removeContractAlert = (data) => {
  return axios({
    url: `${apiURL}/readNotification`,
    method: "POST",
    headers: getAuthorizationHeaders(),
    data: data,
  });
};
