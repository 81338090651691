import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tag, Table, Select } from "antd";
import { actions as formFieldsActions } from "modules/formFields";
import { sortStrings, sortDates, dateTimeFormat, withNumberingColumn } from "utils/utils";
import moment from "moment";

import {
  ColorfulTag,
  //  ProspectInfo
} from "components";
import { Searchbar, Loader } from "components";
import { Link } from "react-router-dom";

export const ProspectsWithStatus = ({ apiCall }) => {
  const { Option } = Select;
  const dispatch = useDispatch();

  const schoolId = useSelector((state) => state?.user?.selectedSchool?.id);
  const [loading, setLoading] = useState(false);
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [prospects, setProspects] = useState([]);
  const [results, setResults] = useState([]);

  const editStatus = (status) => {
    let data = {};

    data["type"] = [{ target_id: "prospect_student" }];
    data["title"] = [{ value: "prospect_student" }];
    data["field_prospect_student_status"] = [{ value: status }];

    return data;
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const getOptions = () => {
    let options = [
      { value: "0", label: "New" },
      { value: "1", label: "Trial" },
      { value: "2", label: "Follow up" },
      { value: "4", label: "Archived - Not interested" },
      { value: "5", label: "Archived - Not satisfied" },
      { value: "6", label: "Archived - Too Expensive" },
      { value: "7", label: "Archived - Personal reasons" },
      { value: "8", label: "Archived - Data Entry Error" },
      { value: "9", label: "In Process" },
    ];
    return options;
  };

  const fetchData = async () => {
    setLoading(true);
    apiCall()?.then(({ data }) => {
      setProspects(
        data.map((prospect) => {
          return {
            id: prospect.id,
            firstName: prospect.firstName,
            lastName: prospect.lastName,
            status: prospect.status,
            createDate: prospect.createdDate,
            lastUpdate: prospect.lastUpdatedDate,
            instruments: prospect.instruments,
            lessons: [...prospect.lessons],
            termsForSearch: `${prospect.firstName}, ${prospect.lastName}, ${prospect.status} ,${prospect.instruments}`,
          };
        })
      );
      setResults(
        data.map((prospect) => {
          return {
            id: prospect.id,
            firstName: prospect.firstName,
            lastName: prospect.lastName,
            status: prospect.status,
            instruments: prospect.instruments,
            lessons: [...prospect.lessons],
            createDate: prospect.createdDate,
            lastUpdate: prospect.lastUpdatedDate,
            termsForSearch: `${prospect.firstName}, ${prospect.lastName}, ${prospect.status} ,${prospect.instruments}`,
          };
        })
      );
      setLoading(false);
      setStatusChangeLoading(false);
    });
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    setResults(prospects.filter((prospect) => prospect.termsForSearch.toLowerCase().includes(searchTerm))); // eslint-disable-next-line
  }, [searchTerm]);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => sortStrings(a, b, "firstName"),
      render: (firstName, { id }) => {
        return <Link to={`/dashboard/${schoolId}/prospectProfile/${id}`}>{`${firstName} `}</Link>;
      },
    },

    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) => sortStrings(a, b, "lastName"),
      render: (lastName, { id }) => {
        return <Link to={`/dashboard/${schoolId}/prospectProfile/${id}`}>{`${lastName}`}</Link>;
      },
    },

    {
      title: "Instruments",
      dataIndex: "instruments",
      key: "instruments",
      sorter: (a, b) => sortStrings(a, b, "instruments"),
      render: (instruments) => <span>{!instruments ? "--" : instruments}</span>,
    },

    {
      title: "Lesson planned on",
      dataIndex: "lessons",
      key: "lessons",
      render: (lessons) => (
        <span>
          {lessons.length === 0
            ? "--"
            : lessons.map((lesson) => <Tag color="blue">{moment(lesson.date).format(dateTimeFormat)}</Tag>)}
        </span>
      ),
    },

    {
      title: "Created Date",
      dataIndex: "createDate",
      key: "createDate",
      defaultSortOrder: "descend",
      sorter: (a, b) => sortDates(a, b, "createDate"),
      render: (date) => <span>{moment(date).format(dateTimeFormat)}</span>,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => sortStrings(a, b, "status"),
      render: (status) => (
        <span>
          <ColorfulTag text={status} />
        </span>
      ),
    },
    {
      title: "Change status to",
      render: (first, prospect) => (
        <Select
          loading={statusChangeLoading}
          disabled={statusChangeLoading}
          onChange={async (actionType) => {
            setStatusChangeLoading(true);
            await dispatch(formFieldsActions.editItem(editStatus(actionType), prospect.id));
            fetchData();
          }}
          style={{ width: 200, opacity: statusChangeLoading ? 0.5 : 1 }}
          placeholder="Choose an Action"
          options={getOptions()}
        />
      ),
    },
  ];

  return loading ? (
    <Loader />
  ) : (
    <>
      <Searchbar
        searchTerm={searchTerm}
        handleChange={handleChange}
        setSearchTerm={setSearchTerm}
        variant={"prospects"}
      />
      <Table bordered columns={withNumberingColumn(columns)} dataSource={results} pagination={false} />
    </>
  );
};

export default ProspectsWithStatus;
