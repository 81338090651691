import axios from "axios";
import { apiURL } from "utils/config";
import { getAuthorizationHeaders } from "./auth";
import store from "../store";

/**
 * Returns Prospects for SelectedSchool
 * @returns {AxiosPromise}
 */

export const getActiveProspects = () => {
  const url = (id) => `${apiURL}/school/${id}/prospect?status=active`;
  let schoolId = store.getState().user.selectedSchool.id;

  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getArchivedProspects = () => {
  const url = (id) => `${apiURL}/school/${id}/prospect?status=archived`;
  let schoolId = store.getState().user.selectedSchool.id;
  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getSignedProspects = () => {
  const url = (id) => `${apiURL}/school/${id}/prospect?status=signed`;
  let schoolId = store.getState().user.selectedSchool.id;
  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

//Teacher Prospects

export const getTeacherActiveProspects = () => {
  const url = (id, teacherId) => `${apiURL}/school/${id}/teacher/${teacherId}/prospect?status=active`;
  let schoolId = store.getState().user.selectedSchool.id;
  let profileId = store.getState().profile.profileInfo.id;
  return axios({
    url: url(schoolId, profileId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getTeacherArchivedProspects = () => {
  const url = (id, teacherId) => `${apiURL}/school/${id}/teacher/${teacherId}/prospect?status=archived`;
  let schoolId = store.getState().user.selectedSchool.id;
  let profileId = store.getState().profile.profileInfo.id;
  return axios({
    url: url(schoolId, profileId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getTeacherSignedProspects = () => {
  const url = (id, teacherId) => `${apiURL}/school/${id}/teacher/${teacherId}/prospect?status=signed`;
  let schoolId = store.getState().user.selectedSchool.id;
  let profileId = store.getState().profile.profileInfo.id;
  return axios({
    url: url(schoolId, profileId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};
