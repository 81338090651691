import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions as formFieldsActions } from "modules/formFields";
import { Radio } from "antd";

export const RadioSelect = ({ isKKStudent, text, id }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(text);

  useEffect(() => {
    setValue(text);
  }, [text]);

  return isKKStudent ? (
    <Radio.Group
      buttonStyle="solid"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        return dispatch(formFieldsActions.editItem({ type: "kk_student", field_received_monkey: e.target.value }, id));
      }}
    >
      <Radio.Button value="Yes">Yes</Radio.Button>
      <Radio.Button value="No">No</Radio.Button>
    </Radio.Group>
  ) : (
    <Radio.Group
      buttonStyle="solid"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        dispatch(formFieldsActions.editItem({ type: "profile", field_tshirt_voucher_status: e.target.value }, id));
      }}
    >
      <Radio.Button value="received">Yes</Radio.Button>
      <Radio.Button value="pending">No</Radio.Button>
    </Radio.Group>
  );
};

export default RadioSelect;
