import axios from "axios";
import { getAuthorizationHeaders } from "./auth";
import { baseURL } from "utils/config";

/**
 * Returns Form Fields
 * @returns {AxiosPromise}
 */

export const postComment = (data) => {
  return axios({
    url: `${baseURL}/comment?_format=json`,
    method: "POST",
    data: data,
    headers: getAuthorizationHeaders(),
  });
};
