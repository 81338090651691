import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Row, Col, Modal, Avatar, Tabs, Button, Form, Select } from "antd";
import { UserOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { actions as contractInfoActions } from "modules/contractInfo";
import "./contractInfo.scss";
import moment from "moment";
import { dateTimeFormat, dateFormat, sortDates, sortStrings, sortNumericStrings } from "utils/utils";
import { useHistory } from "react-router-dom";
import PauseForm from "components/StudentsContracts/PauseForm";
import LetterForm from "components/StudentsContracts/LetterForm";
import { contractHistoryFields } from "utils/contractHistoryFields";
import { actions as formFieldsActions } from "modules/formFields";
import { postLetterInformation, postLetterType } from "services/formFields";
import { actions as editNodeActions } from "modules/editNode";
import { getErrorMessage } from "modules/process";
import { openNotification } from "utils/notification";
import useRoles from "hooks/useRoles";

const { TabPane } = Tabs;
const { Option } = Select;

export const ContractInfo = ({ id }) => {
  const { isHQStaff, isStaff, isFranchisee } = useRoles();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const location = useHistory();
  const loading = useSelector((state) => state?.contractInfo?.loading);
  const contractId = useSelector((state) => state?.contractInfo?.contractInfo?.id);

  const letterInformation = (contractId) => {
    let data = {};
    data["type"] = "information";
    data["id"] = contractId;
    return data;
  };

  const letterType = (selectedLetter, contractId) => {
    let data = {};
    data["type"] = selectedLetter;
    data["id"] = contractId;
    return data;
  };

  const {
    tarif,
    studentFirstName,
    studentLastName,
    studentName,
    teacherName,
    collectionProcess,
    instruments,
    paymentFrequency,
    contractStatus,
    pauseDate,
    outOfPauseDate,
    paymentType,
    startingDate,
    endDate,
    tshirtVoucher,
    nextCancellationDate,
    handlingCharge,
    contractPartner,
    payments,
    hoursBeforeContract,
    studentLocation,
    history,
  } = useSelector((state) => state?.contractInfo?.contractInfo);

  const contractPartnerAddress =
    contractPartner && contractPartner?.street
      ? `${contractPartner?.street} ${contractPartner?.streetNumber ? contractPartner?.streetNumber : ""}, 
      ${contractPartner?.city ? contractPartner.city : ""} 
      ${contractPartner.postalCode ? contractPartner.postalCode : ""}, 
      ${contractPartner.country ? contractPartner.country.label : ""} `
      : "-";

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [pauseModalVisible, setPauseModalVisible] = useState(false);
  const [letterModalVisible, setLetterModalVisible] = useState(false);
  const [letterInformationData, setLetterInformationData] = useState({});
  const [letterMessageData, setLetterMessageData] = useState({});
  const [historyId, setHistoryId] = useState("");
  const [pauseFormloading, setPauseFormLoading] = useState(true);
  const [letterFormloading, setLetterFormLoading] = useState(true);
  const [selectedLetter, setSelectedLetter] = useState("");
  const [enableGenerateButton, setEnableGenerateButton] = useState(false);

  useEffect(() => {
    if (editMode) {
      dispatch(editNodeActions.getNode(historyId)).then(({ payload }) => {
        setPauseFormLoading(false);
        form.setFieldsValue({
          contract_history: {
            field_contract_history_dates:
              payload?.field_contract_history_dates && moment(payload?.field_contract_history_dates),
            field_contract_pause_period: payload?.field_contract_pause_period,
            field_contract_history_notes: payload?.field_contract_history_notes,
          },
        });
      });
    }
  }, [historyId]);

  const showModal = () => {
    dispatch(contractInfoActions.getContractInfo(id));
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showPauseModal = (historyId) => {
    setPauseModalVisible(true);
    setHistoryId(historyId);
    setEditMode(true);
  };

  const handleClosePause = () => {
    setPauseModalVisible(false);
  };

  const handleCloseLetter = () => {
    setLetterModalVisible(false);
  };

  const handleSelection = (actionType) => {
    setEnableGenerateButton(true);
    setSelectedLetter(actionType);
  };

  const handleAction = async () => {
    setLetterFormLoading(true);
    try {
      await postLetterInformation(letterInformation(contractId)).then((data) => {
        setLetterInformationData(data.data);
      });
    } catch (error) {
      openNotification("error", getErrorMessage(error));
    }
    try {
      await postLetterType(letterType(selectedLetter, contractId)).then((data) => {
        setLetterMessageData(data.data);
      });
    } catch (error) {
      openNotification("error", getErrorMessage(error));
    } finally {
      setLetterModalVisible(true);
      setLetterFormLoading(false);
    }
  };

  const renderLetter = (values) => {
    const html = `<div class="letter">
          <div class="letter-header">
            <div class="letter-info-left">
              <div class="header-location">
                <span>
                ${
                  letterInformationData?.internet === "www.modernmusicschool.com"
                    ? `MMS ${letterInformationData?.school?.location?.locality} -`
                    : `VibrA ${letterInformationData?.school?.location?.locality} -`
                } 
                ${letterInformationData.school.location.address_line1}, 
                ${letterInformationData.school.location.postal_code} 
                ${letterInformationData?.school?.location?.locality}
                </span>
              </div>
              <div class="contract-partner">
                <span>
                ${letterInformationData.profile.first_name} ${letterInformationData.profile.last_name}</br>
                </span>
                <span>
                ${letterInformationData.profile.street}</br>
                </span>
                <span>
                ${letterInformationData.profile.postal_code} ${letterInformationData.profile.city} 
                </span>
              </div>
            </div>
            <div class="letter-info-right">
              <span>
                <strong>Tel:</strong></br>  ${letterInformationData.school.telephone}</br>
              </span>
              <span>
                <strong>E-mail:</strong></br>  ${letterInformationData.school.email}</br>
              </span>
              ${
                letterInformationData.school.mobile
                  ? `<span>
                  <strong>Mobile:</strong></br>  ${letterInformationData.school.mobile}</br> 
                </span>`
                  : ``
              }
              <span>
                <strong>Internet:</strong></br>  ${letterInformationData.internet}</br> 
              </span>
              <div class="contract-partner">
                <span>
                ${letterInformationData?.school?.location?.locality}, 
                ${new Date().toLocaleDateString("de-DE", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}</br> 
                </span>
              </div>
            </div>
          </div>
          <div class="letter-main">
            <div class="subject"><span>${values.letter_editor.field_contract_letter_subject}</span></div>
            <div class="message"><span>${values.letter_editor.field_contract_letter_message}</span></div>
          </div>
          ${
            selectedLetter === "welcome"
              ? `<div class="letter-footer">
            <div class="letter-info-left">
              <div class="letter-info-left">
                ${letterInformationData.contract.instrument.label}: </br>
                ${letterInformationData.contract.tarif.label}: </br>
                ${letterInformationData.contract.starting_date.label}: </br>
                ${letterInformationData.contract.hours_before.label}: </br>
                ${letterInformationData.contract.contract_fee.label}: </br>
                ${letterInformationData.contract.hours_before_fee.label}: </br>
                ${letterInformationData.contract.one_time_handling_charge.label}: </br>
              </div>
              <div class="letter-info-mid">
                ${letterInformationData.contract.instrument.value}</br>
                ${letterInformationData.contract.tarif.value}</br>
                ${letterInformationData.contract.starting_date.value}</br>
                ${letterInformationData.contract.hours_before.value || "0"}</br>
                ${letterInformationData.contract.contract_fee.value || "0"} €</br>
                ${letterInformationData.contract.hours_before_fee.value || "0"} €</br>
                ${letterInformationData.contract.one_time_handling_charge.value || "0"} €</br>
              </div>
            </div>
            
            <div class="letter-info-right">
              ${letterInformationData?.internet === "www.modernmusicschool.com" ? "Modern Music School" : "VibrA"} 
              ${letterInformationData?.school?.location?.locality}</br>
              ${letterInformationData?.school?.bank_name}</br>
              BIC ${letterInformationData?.school?.bank_routing_number}</br>
              IBAN ${letterInformationData?.school?.bank_account_number}
            </div>
          </div>`
              : ""
          }
          </div>`;

    const newWindow = window.open("/letters-print.html", "_blank");

    setTimeout(() => {
      newWindow.document.getElementById("jsPrintPage").innerHTML = html;
    }, 1000);
  };

  const historyColumns = [
    {
      title: "Date",
      dataIndex: "historyDates",
      key: "historyDates",
      sorter: (a, b) => sortDates(a, b, "historyDates"),
      render: (date) => <span>{moment(date).format(dateFormat)}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => sortStrings(a, b, "status"),
      render: (status) => <span>{status}</span>,
    },
    {
      title: "Pause Period (Months)",
      dataIndex: "pausePeriod",
      key: "pausePeriod",
      render: (pausePeriod) => <span>{pausePeriod ? pausePeriod : ""}</span>,
    },
    {
      title: "Out Of Pause",
      dataIndex: "outOfPauseDate",
      key: "outOfPauseDate",
      sorter: (a, b) => sortDates(a, b, "outOfPauseDate"),
      render: (outOfPauseDate) => <span>{outOfPauseDate ? moment(outOfPauseDate).format(dateFormat) : ""}</span>,
    },
    {
      title: "Cancelation Reason",
      dataIndex: "cancellationPurpose",
      key: "cancellationPurpose",
      render: (cancellationPurpose, { notes }) => (
        <span style={{ whiteSpace: "pre-wrap" }}>
          {cancellationPurpose ? cancellationPurpose + "\n" : ""}
          {notes ? "notes: " + notes : ""}
        </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "can_edit",
      key: "can_edit",
      render: (can_edit, { historyId }) => {
        return (
          <div>
            <span> {can_edit ? <Button onClick={() => showPauseModal(historyId)}>Edit</Button> : null}</span>
            {isHQStaff ? (
              <DeleteOutlined
                disabled={!isHQStaff}
                style={{ color: "#ff4d4f", marginLeft: "10px" }}
                onClick={() => {
                  dispatch(editNodeActions.deleteNode(historyId));
                  setTimeout(() => {
                    dispatch(contractInfoActions.getContractInfo(id));
                  }, 1000);
                }}
              />
            ) : null}
          </div>
        );
      },
    },
  ];

  const paymentsColumns = [
    {
      title: "Date",
      dataIndex: "billingDate",
      key: "billingDate",
      sorter: (a, b) => sortDates(a, b, "billingDate"),
      render: (date) => <span>{moment(date).format(dateFormat)}</span>,
    },
    {
      title: "Type",
      dataIndex: "billingType",
      key: "billingType",
      sorter: (a, b) => sortStrings(a, b, "billingType"),
      render: (billingType) => <span>{billingType}</span>,
    },
    {
      title: "Amount",
      dataIndex: "billingAmount",
      key: "billingAmount",
      sorter: (a, b) => sortNumericStrings(a, b, "billingAmount"),
      render: (billingAmount) => <span>{billingAmount}</span>,
    },
    {
      title: "Payment Status",
      dataIndex: "billingPaymentStatus",
      key: "billingPaymentStatus",
      sorter: (a, b) => sortStrings(a, b, "billingPaymentStatus"),
      render: (billingPaymentStatus) => <span>{billingPaymentStatus}</span>,
    },
  ];

  return loading ? (
    <Button onClick={showModal}> See Contract</Button>
  ) : (
    <div>
      <Button onClick={showModal}> See Contract</Button>
      <Modal
        title="Contract Information"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"850px"}
        okButtonProps={{ type: "primary", className: "buttonInModalWithFloatRounded__buttons--floatRight" }}
        cancelButtonProps={{
          className: "buttonInModalWithFloatRounded__buttons--floatLeft",
        }}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Overview" key="1">
                <div className={"contractInfo__profile"}>
                  <Avatar className={"contractInfo__profile__avatar"} size={100} icon={<UserOutlined />} />

                  <div className={"contractInfo__profile__info"}>
                    <div>
                      Tarif: <span className={"contractInfo__profile__info--highlighted"}>{tarif}</span>
                    </div>
                    <div>
                      Student: <span className={"contractInfo__profile__info--highlighted"}>{studentName}</span>
                    </div>
                    <div>
                      Teacher: <span className={"contractInfo__profile__info--highlighted"}>{teacherName}</span>
                    </div>
                    <div>
                      Instruments: <span className={"contractInfo__profile__info--highlighted"}>{instruments}</span>
                    </div>
                  </div>
                </div>
                <div className={"contractInfo__overview__title"}>Status</div>
                <div className={"contractInfo__overview__item"}>
                  Payment Frequency:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>{paymentFrequency}</span>
                </div>
                <div className={"contractInfo__overview__item"}>
                  Status: <span className={"contractInfo__overview__item--highlighted"}>{contractStatus}</span>
                </div>
                <div className={"contractInfo__overview__item"}>
                  Pause Start Date:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>
                    {pauseDate ? moment(pauseDate).format(dateFormat) : "--"}
                  </span>
                </div>
                <div className={"contractInfo__overview__item"}>
                  Out of Pause Date:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>
                    {outOfPauseDate ? moment(outOfPauseDate).format(dateFormat) : "--"}{" "}
                  </span>
                </div>
                <div className={"contractInfo__overview__item"}>
                  Collection Process:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>
                    {collectionProcess ? collectionProcess : "--"}
                  </span>
                </div>

                <div className={"contractInfo__overview__title"}>Charges and Prices</div>
                <div className={"contractInfo__overview__item"}>
                  Received Payment: <span className={"contractInfo__overview__item--highlighted"}>{paymentType}</span>
                </div>

                <div className={"contractInfo__overview__item"}>
                  Hours before contract:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>{hoursBeforeContract}</span>
                </div>

                <div className={"contractInfo__overview__item"}>
                  Contract Starting:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>
                    {startingDate ? moment(startingDate).format(dateFormat) : "--"}
                  </span>
                </div>

                <div className={"contractInfo__overview__item"}>
                  Ending Date:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>
                    {endDate ? moment(endDate).format(dateFormat) : "--"}
                  </span>
                </div>

                <div className={"contractInfo__overview__item"}>
                  Next Cancelation Date:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>
                    {nextCancellationDate ? moment(nextCancellationDate).format(dateFormat) : "--"}
                  </span>
                </div>

                <div className={"contractInfo__overview__item"}>
                  T-shirt voucher Redeemed:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>{tshirtVoucher}</span>
                </div>

                <div className={"contractInfo__overview__item"}>
                  One Time Handling Charge:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>{handlingCharge}</span>
                </div>
              </TabPane>
              <TabPane tab="Contract Partner" key="2">
                <div className={"contractInfo__overview__title"}>Information</div>
                <div className={"contractInfo__overview__item"}>
                  Name:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>
                    {contractPartner?.firstName} {contractPartner?.lastName}
                  </span>
                </div>
                <div className={"contractInfo__overview__item"}>
                  Gender: <span className={"contractInfo__overview__item--highlighted"}>{contractPartner?.gender}</span>
                </div>

                <div className={"contractInfo__overview__item"}>
                  Telephone:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>{contractPartner?.telephone}</span>
                </div>

                <div className={"contractInfo__overview__item"}>
                  Mobile: <span className={"contractInfo__overview__item--highlighted"}>{contractPartner?.mobile}</span>
                </div>

                <div className={"contractInfo__overview__item"}>
                  Email: <span className={"contractInfo__overview__item--highlighted"}>{contractPartner?.email}</span>
                </div>

                <div className={"contractInfo__overview__item"}>
                  Date of Birth:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>
                    {contractPartner?.dob ? moment(contractPartner?.dob).format(dateFormat) : "--"}
                  </span>
                </div>
              </TabPane>
              <TabPane tab="Payments" key="3">
                {(isStaff || isHQStaff || isFranchisee) && (
                  <Button
                    type={"primary"}
                    icon={<EditOutlined />}
                    style={{ marginBottom: "15px" }}
                    onClick={() => location.push(`/dashboard/BillingManagment/${id}`)}
                  >
                    Edit Payments
                  </Button>
                )}

                <Table bordered columns={paymentsColumns} dataSource={payments && payments} />
              </TabPane>
              <TabPane tab="History" key="4">
                <Table bordered columns={historyColumns} dataSource={history && history} />
              </TabPane>
              <TabPane tab="Letter" key="5">
                <div>
                  <Select
                    onChange={(actionType) => handleSelection(actionType)}
                    style={{ width: 220, marginRight: 10 }}
                    placeholder="Choose a letter to generate"
                  >
                    <Option value="welcome">Welcome</Option>
                    <Option value="cancellation">Cancellation</Option>
                    <Option value="2_months">2 Months</Option>
                    <Option value="8_months">8 Months</Option>
                    <Option value="pause">Pause</Option>
                  </Select>
                  <Button type={"primary"} disabled={!enableGenerateButton} onClick={() => handleAction()}>
                    Generate letter
                  </Button>
                </div>
                <div className={"contractInfo__overview__title"}>Letter information</div>
                <div className={"contractInfo__overview__item"}>
                  First Name: <span className={"contractInfo__overview__item--highlighted"}>{studentFirstName}</span>
                </div>
                <div className={"contractInfo__overview__item"}>
                  Last Name: <span className={"contractInfo__overview__item--highlighted"}>{studentLastName}</span>
                </div>
                <div className={"contractInfo__overview__item"}>
                  Guardian First Name:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>{contractPartner?.firstName}</span>
                </div>
                <div className={"contractInfo__overview__item"}>
                  Guardian Last Name:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>{contractPartner?.lastName}</span>
                </div>
                <div className={"contractInfo__overview__item"}>
                  Guardian Mobile:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>{contractPartner?.mobile}</span>
                </div>
                <div className={"contractInfo__overview__item"}>
                  Guardian Telephone:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>{contractPartner?.telephone}</span>
                </div>
                <div className={"contractInfo__overview__item"}>
                  Guardian address:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>{contractPartnerAddress}</span>
                </div>
                <div className={"contractInfo__overview__item"}>
                  Student address:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>{studentLocation}</span>
                </div>
                <div className={"contractInfo__overview__item"}>
                  Contract: <span className={"contractInfo__overview__item--highlighted"}>{tarif}</span>
                </div>
                <div className={"contractInfo__overview__item"}>
                  Hours before:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>{hoursBeforeContract}</span>
                </div>
                <div className={"contractInfo__overview__item"}>
                  Start date:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>
                    {moment(startingDate).format(dateTimeFormat)}
                  </span>
                </div>
                <div className={"contractInfo__overview__item"}>
                  End date:{" "}
                  <span className={"contractInfo__overview__item--highlighted"}>
                    {moment(endDate).format(dateFormat)}
                  </span>
                </div>
                <div className={"contractInfo__overview__item"}>
                  Instrument: <span className={"contractInfo__overview__item--highlighted"}>{instruments}</span>
                </div>
                <div className={"contractInfo__overview__title"}>Payments table </div>
                <Table bordered columns={paymentsColumns} dataSource={payments && payments} />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Modal>
      <Modal
        footer={null}
        title="Pause"
        visible={pauseModalVisible}
        onOk={handleClosePause}
        onCancel={handleClosePause}
      >
        <Form
          form={form}
          id="pauseForm"
          style={{ padding: "0px 10px" }}
          layout="vertical"
          size="large"
          name="nest-messages"
          onFinish={async (values) => {
            await dispatch(formFieldsActions.editItem(contractHistoryFields(values, "PAUSED", id), historyId));
            handleClosePause();
            dispatch(contractInfoActions.getContractInfo(id));
          }}
          onFinishFailed={({ errorFields }) => form.scrollToField(errorFields[0].name)}
          scrollToFirstError
        >
          <PauseForm loading={pauseFormloading} contractHistoryId={historyId} editMode />
        </Form>
      </Modal>
      <Modal
        footer={null}
        title={`Edit ${selectedLetter} letter`}
        visible={letterModalVisible}
        onOk={handleCloseLetter}
        onCancel={handleCloseLetter}
      >
        <Form
          form={form}
          id="LetterForm"
          style={{ padding: "0px 10px" }}
          layout="vertical"
          size="large"
          name="letter-editor"
          onFinish={(values) => renderLetter(values)}
          onFinishFailed={({ errorFields }) => form.scrollToField(errorFields[0].name)}
          scrollToFirstError
        >
          <LetterForm
            loading={letterFormloading}
            letterInformationData={letterInformationData}
            letterMessageData={letterMessageData}
            form={form}
            callback={handleCloseLetter}
          />
        </Form>
      </Modal>
    </div>
  );
};

export default ContractInfo;
