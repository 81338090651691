import { createAction, handleActions } from "redux-actions";
import { getBillingManagment as apiGetBillingManagment } from "services/billingManagment.js";
import { openNotification } from "../utils/notification";
import { getErrorMessage } from "modules/process";

/**
 * Billing Managment reducer
 * Handles billingManagment actions
 */

// ------------------------------------
// Constants
// ------------------------------------

export const BILLING_MANAGMENT_SUCCESS = "BILLING_MANAGMENT_SUCCESS";
export const BILLING_MANAGMENT_FAILED = "BILLING_MANAGMENT_FAILED";
export const BILLING_MANAGMENT_REQUEST = "BILLING_MANAGMENT_REQUEST";

// ------------------------------------
// Actions
// ------------------------------------

const billingManagmentSuccess = createAction(BILLING_MANAGMENT_SUCCESS);
const billingManagmentFailed = createAction(BILLING_MANAGMENT_FAILED);
const billingManagmentRequest = createAction(BILLING_MANAGMENT_REQUEST);

const getBillingManagment = (contractId) => {
  return (dispatch) => {
    dispatch(billingManagmentRequest());
    apiGetBillingManagment(contractId)
      .then((response) => dispatch(billingManagmentSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(billingManagmentFailed(error));
      });
  };
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  getBillingManagment,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  billingPayments: [],
  errors: null,
  loading: false,
  changes: false,
};

export default handleActions(
  {
    BILLING_MANAGMENT_REQUEST: (state) => {
      return { ...initialState, loading: true };
    },

    BILLING_MANAGMENT_SUCCESS: (state, { payload }) => {
      return {
        ...initialState,
        loading: false,
        billingPayments: payload?.payments,
      };
    },

    BILLING_MANAGMENT_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },

    ITEM_CHANGED: (state, { payload }) => {
      return {
        ...state,
        changes: true,
        errors: payload,
      };
    },
  },
  initialState
);
