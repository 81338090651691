import React, { useEffect } from "react";
import { Loader } from "components";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "antd";
import { format } from "date-fns";
import "styles/_global.scss";
import { actions as teacherActions } from "modules/teachersProfile";
import { useParams } from "react-router-dom";

const TeacherLogs = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const teacherLogs = useSelector((state) => state?.teachersProfile?.teacherLogs);
  const loading = useSelector((state) => state?.teachersProfile?.logsLoading);

  useEffect(() => {
    dispatch(teacherActions.getTeacherLogs(params.profileId));
    // eslint-disable-next-line
  }, [params.profileId]);

  const columns = [
    {
      title: "Lesson Name",
      dataIndex: "lessonName",
      key: "lessonDate",
    },

    {
      title: "Start date",
      dataIndex: "start_date",
      key: "start_date",
      render: (start_date) => (
        <span>
          <div>{format(new Date(start_date), "dd MMM, yyyy - hh:mm a")}</div>
        </span>
      ),
    },

    {
      title: "End date",
      dataIndex: "end_date",
      key: "end_date",
      render: (end_date) => (
        <span>
          <div>{format(new Date(end_date), "dd MMM, yyyy - hh:mm a")}</div>
        </span>
      ),
    },

    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },

    {
      title: "Contract",
      dataIndex: "students_entries",
      key: "students_entries",
      render: (students_entries) => (
        <div>
          {students_entries.map(({ contract }) => (
            <p>{contract}</p>
          ))}
        </div>
      ),
    },
  ];

  return loading ? <Loader /> : <Table bordered columns={columns} dataSource={teacherLogs} pagination={false} />;
};

export default TeacherLogs;
