import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { Loader } from "components";
import { format } from "date-fns";

import "./studentOverview.scss";

import { getStudentOverview } from "services/studentOverview";

export const StudentOverview = ({ type }) => {
  const location = useLocation();

  const [studentOverviewData, setStudentOverviewData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const profileId = location.pathname.split("profile/").pop();
    setLoading(true);
    getStudentOverview(profileId).then(({ data }) => {
      setStudentOverviewData(data);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className={"studentOverview"}>
      <div className={"studentOverview__coming"}>
        {studentOverviewData?.nextLesson ? studentOverviewData?.nextLesson : "No information"}
      </div>
      <div className={"studentOverview__booksOwned"}>
        Books owned
        <div className={"studentOverview__booksOwned__books"}>
          {studentOverviewData?.booksOwned?.length > 0
            ? studentOverviewData?.booksOwned?.map((item, index) => {
                if (studentOverviewData?.booksOwned?.length - 1 === index) {
                  return `${item.title}`;
                }

                return `${item.title}, `;
              })
            : "No books owned"}
        </div>
      </div>
      <div className={"studentOverview__photoRelease"}>
        Photo release signed:
        {studentOverviewData?.photoReleaseSigned && studentOverviewData?.photoReleaseSigned === "0" ? "No" : "Yes"}
      </div>
      <div className={"studentOverview__startDate"}>
        MMS Start date:
        {`${studentOverviewData?.mmsStartDate && format(new Date(studentOverviewData?.mmsStartDate), "dd/MM/yyyy")}`}
      </div>

      {type === "student" && (
        <div className={"studentOverview__flexAmounts"}>
          <div className={"studentOverview__flexAmounts__card"}>
            <div className={"studentOverview__flexAmounts__card--number"}>
              {studentOverviewData?.billing?.openAccountingOverdueAmount}
            </div>
            <div className={"studentOverview__flexAmounts__card--title"}>Open Accounting Amount</div>
            <div className={"studentOverview__flexAmounts__card--detail"}>(Overdue)</div>
          </div>
          <div className={"studentOverview__flexAmountscard"}>
            <div className={"studentOverview__flexAmounts__card--number"}>
              {studentOverviewData?.billing?.openAccountingAmount}
            </div>
            <div className={"studentOverview__flexAmounts__card--title"}>Open Accounting Amount</div>
            <div className={"studentOverview__flexAmounts__card--detail"}>(This month)</div>
          </div>
          <div className={"studentOverview__flexAmounts__card"}>
            <div className={"studentOverview__flexAmounts__card--number"}>
              {studentOverviewData?.billing?.monthlyAccountingAmount}
            </div>
            <div className={"studentOverview__flexAmounts__card--title"}>Monthly Accounting Amount</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentOverview;
