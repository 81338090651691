import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as formFieldsActions } from "modules/formFields";
import { Form, Input, DatePicker, Button } from "antd";
import { FormSelect } from "components/formComponents/FormSelect";

export const PauseForm = ({ loading }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(formFieldsActions.getFormData("field_contract_pause_period"));
    // eslint-disable-next-line
  }, []);

  const pausePeriodOptions = useSelector((state) => state?.formFields?.field_contract_pause_period);

  return (
    <>
      <Form.Item
        name={["contract_history", "field_contract_history_dates"]}
        label="Dates"
        rules={[
          {
            required: true,
            message: "Invalid Date",
          },
        ]}
      >
        <DatePicker format={"DD/MM/YYYY"} />
      </Form.Item>

      <Form.Item noStyle>
        <FormSelect
          required
          formName={["contract_history", "field_contract_pause_period"]}
          options={pausePeriodOptions}
          label="Pause Period (months)"
        />
      </Form.Item>

      <Form.Item name={["contract_history", "field_contract_history_notes"]} label="Notes">
        <Input.TextArea />
      </Form.Item>

      <Button type="primary" key="submit" htmlType="submit" loading={loading}>
        Submit
      </Button>
    </>
  );
};
export default PauseForm;
