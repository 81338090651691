import { createAction, handleActions } from "redux-actions";
import { getAlerts as apiGetAlerts } from "services/alerts";
import { openNotification } from "../utils/notification";
import { getErrorMessage } from "modules/process";

/**
 * Birthdays reducer
 * Handles Birthdays actions
 */

// ------------------------------------
// Constants
// ------------------------------------

export const ALERTS_SUCCESS = "ALERTS_SUCCESS";
export const ALERTS_FAILED = "ALERTS_FAILED";
export const ALERTS_REQUEST = "ALERTS_REQUEST";

// ------------------------------------
// Actions
// ------------------------------------

const alertsSuccess = createAction(ALERTS_SUCCESS);
const alertsFailed = createAction(ALERTS_FAILED);
const alertsRequest = createAction(ALERTS_REQUEST);

const getAlerts = (teacherID) => {
  return (dispatch) => {
    dispatch(alertsRequest());
    apiGetAlerts(teacherID)
      .then((response) => dispatch(alertsSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(alertsFailed(error));
      });
  };
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  getAlerts,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  alerts: null,
  errors: null,
  loading: false,
};

export default handleActions(
  {
    ALERTS_REQUEST: (state) => {
      return { ...initialState, loading: true };
    },

    ALERTS_SUCCESS: (state, { payload }) => {
      // console.table(payload);
      return {
        ...initialState,
        loading: false,
        alerts: payload,
      };
    },

    ALERTS_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },
  },
  initialState
);
