import axios from "axios";
import { baseURL } from "utils/config";
import { getAuthorizationHeaders } from "./auth";

export const getStatistics = (school_id, resource) => {
  return axios({
    url: `${baseURL}/api/v2/${school_id}/statistics`,
    method: "POST",
    data: resource,
    headers: getAuthorizationHeaders(),
  });
};
