/**
 * User reducer
 * Handles user actions
 */

import { createAction, handleActions } from "redux-actions";
import { getUser as apiGetUser } from "services/user";
import { actions as processActions } from "modules/process";

// ------------------------------------
// Constants
// ------------------------------------
export const USER_SUCCESS = "USER_SUCCESS";
export const SET_DEVICE_ID = "SET_DEVICE_ID";
export const SET_QUICK_LOGIN = "SET_QUICK_LOGIN";
export const USER_CLEAR = "USER_CLEAR";
export const CHANGE_SCHOOL_FROM_UI = "CHANGE_SCHOOL_FROM_UI";

// ------------------------------------
// Actions
// ------------------------------------
const userSuccess = createAction(USER_SUCCESS);
const clearUser = createAction(USER_CLEAR);
const setDeviceId = createAction(SET_DEVICE_ID);
const setQuickLogin = createAction(SET_QUICK_LOGIN);
const changeSchoolFromUi = createAction(CHANGE_SCHOOL_FROM_UI);

// types
export const TYPE_USER_LOAD = "user:load";

/**
 * Load user
 * @returns {function(*)}
 */
const getUser = () => {
  return (dispatch) => {
    processActions.processLoading({
      type: TYPE_USER_LOAD,
    });

    apiGetUser()
      .then((response) => {
        processActions.processSuccess({
          type: TYPE_USER_LOAD,
        });
        return dispatch(userSuccess(response.data));
      })
      .catch((error) => {
        return dispatch(
          processActions.processFailure({
            type: TYPE_USER_LOAD,
            message: error,
          })
        );
      });
  };
};

const changedSchool = (payload) => {
  return (dispatch) => {
    dispatch(changeSchoolFromUi(payload));
  };
};

export const actions = {
  getUser,
  userSuccess,
  clearUser,
  setDeviceId,
  setQuickLogin,
  changedSchool,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  lang: "el",
  auth: false,
  profile: {},
  schools: [],
  selectedSchool: "",
  roles: "",
};

export default handleActions(
  {
    LOGOUT_SUCCESS: () => {
      return {
        ...initialState,
      };
    },
    LOGIN_SUCCESS: (state) => {
      return { ...state, auth: true };
    },
    SET_DEVICE_ID: (state) => {
      return { ...state };
    },
    SET_QUICK_LOGIN: (state) => {
      return { ...state };
    },
    USER_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        ...payload.userProfile,
        username: payload?.username,
        email: payload?.email,
        drupalRoles: payload.drupalRoles,
        profile: { ...payload.userProfile },
        teacherProfile: payload.teacherProfile,
        schools: payload.mySchools,
        selectedSchool: payload.mySchools[0],
        defaultValue: {
          value: payload?.mySchools[0]?.id,
          label: payload?.mySchools[0]?.title,
          roles: payload?.mySchools[0]?.roles,
        },
        roles: payload.mySchools[0].roles,
      };
    },
    CHANGE_SCHOOL_FROM_UI: (state, { payload }) => {
      return {
        ...state,
        selectedSchool: { id: payload.value, title: payload.label },
        defaultValue: payload,
        roles: payload.roles,
      };
    },
    USER_CLEAR: () => {
      return {
        ...initialState,
      };
    },
  },
  initialState
);
