import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ColorfulTag } from "components";
import { Table } from "antd";
import { sortDates, dateFormat, withNumberingColumn, sortStrings } from "utils/utils";
import { actions as profileActions } from "modules/profile";
import moment from "moment";

export const ContractAndByTarifTable = ({ data }) => {
  const dispatch = useDispatch();
  const schoolId = useSelector((state) => state?.user?.selectedSchool?.id);
  const [results, setResults] = useState(data);
  const [pagination, setPagination] = useState(null);

  const columns = [
    {
      title: "Student Name",
      dataIndex: "studentFirstName",
      key: "studentFirstName",
      sorter: (a, b) => sortStrings(a, b, "studentFirstName"),
      render: (text, student) => {
        return (
          <Link
            to={`/dashboard/${schoolId}/profile/${student.studentId}`}
            onClick={() => dispatch(profileActions.getProfile(student.studentId))}
          >
            {text} {student.studentLastName}
          </Link>
        );
      },
    },
    {
      title: "Teacher",
      dataIndex: "teacherName",
      key: "teacherName",
      sorter: (a, b) => sortStrings(a, b, "teacherName"),
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Tarif",
      dataIndex: "tarif",
      key: "tarif",
      sorter: (a, b) => sortStrings(a, b, "tarif"),
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Status",
      dataIndex: "contractStatus",
      key: "contractStatus",
      sorter: (a, b) => sortStrings(a, b, "contractStatus"),
      render: (contractStatus) => <ColorfulTag text={contractStatus} />,
    },
    {
      title: "Starting Date",
      dataIndex: "startingDate",
      key: "startingDate",
      sorter: (a, b) => sortDates(a, b, "startingDate"),
      render: (date) => <span>{date === null ? "-" : moment(date).format(dateFormat)}</span>,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      sorter: (a, b) => sortDates(a, b, "endDate"),
      render: (date) => <span>{date === null ? "-" : moment(date).format(dateFormat)}</span>,
    },
  ];
  return (
    <>
      <Table
        bordered
        columns={withNumberingColumn(columns, pagination)}
        dataSource={results}
        onChange={(pagination) => {
          setPagination(pagination);
        }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
        }}
      />
    </>
  );
};

export default ContractAndByTarifTable;
