import { createAction, handleActions } from "redux-actions";
import {
  getTeacherActiveProspects as apiGetTeacherActiveProspects,
  getTeacherArchivedProspects as apiGetTeacherArchivedProspects,
  getTeacherSignedProspects as apiGetTeacherSignedProspects,
} from "services/prospects";
import { openNotification } from "../utils/notification";
import { getErrorMessage } from "modules/process";

/**
 * Contracts reducer
 * Handles contracts actions
 */

// ------------------------------------
// Constants
// ------------------------------------

//Prospects

export const PROSPECTS_ACTIVE_REQUEST = "PROSPECTS_ACTIVE_REQUEST";
export const PROSPECTS_ACTIVE_SUCCESS = "PROSPECTS_ACTIVE_SUCCESS";
export const PROSPECTS_ACTIVE_FAILED = "PROSPECTS_ACTIVE_FAILED";

export const PROSPECTS_ACHIVED_REQUEST = "PROSPECTS_ACHIVED_REQUEST";
export const PROSPECTS_ACHIVED_SUCCESS = "PROSPECTS_ACHIVED_SUCCESS";
export const PROSPECTS_ACHIVED_FAILED = "PROSPECTS_ACHIVED_FAILED";

export const PROSPECTS_SIGNED_REQUEST = "PROSPECTS_SIGNED_REQUEST";
export const PROSPECTS_SIGNED_SUCCESS = "PROSPECTS_SIGNED_SUCCESS";
export const PROSPECTS_SIGNED_FAILED = "PROSPECTS_SIGNED_FAILED";

//Teacher Prospects

export const PROSPECTS_TEACHER_ACTIVE_REQUEST = "PROSPECTS_TEACHER_ACTIVE_REQUEST";
export const PROSPECTS_TEACHER_ACTIVE_SUCCESS = "PROSPECTS_TEACHER_ACTIVE_SUCCESS";
export const PROSPECTS_TEACHER_ACTIVE_FAILED = "PROSPECTS_TEACHER_ACTIVE_FAILED";

export const PROSPECTS_TEACHER_ACHIVED_REQUEST = "PROSPECTS_TEACHER_ACHIVED_REQUEST";
export const PROSPECTS_TEACHER_ACHIVED_SUCCESS = "PROSPECTS_TEACHER_ACHIVED_SUCCESS";
export const PROSPECTS_TEACHER_ACHIVED_FAILED = "PROSPECTS_TEACHER_ACHIVED_FAILED";

export const PROSPECTS_TEACHER_SIGNED_REQUEST = "PROSPECTS_TEACHER_SIGNED_REQUEST";
export const PROSPECTS_TEACHER_SIGNED_SUCCESS = "PROSPECTS_TEACHER_SIGNED_SUCCESS";
export const PROSPECTS_TEACHER_SIGNED_FAILED = "PROSPECTS_TEACHER_SIGNED_FAILED";

// ------------------------------------
// Actions
// ------------------------------------

//Prospects

// const prospectsActiveRequest = createAction(PROSPECTS_ACTIVE_REQUEST);
// const prospectsActiveSuccess = createAction(PROSPECTS_ACTIVE_SUCCESS);
// const prospectsActiveFailed = createAction(PROSPECTS_ACTIVE_FAILED);

// const prospectsAchivedRequest = createAction(PROSPECTS_ACHIVED_REQUEST);
// const prospectsAchivedSuccess = createAction(PROSPECTS_ACHIVED_SUCCESS);
// const prospectsAchivedFailed = createAction(PROSPECTS_ACHIVED_FAILED);

// const prospectsSignedRequest = createAction(PROSPECTS_SIGNED_REQUEST);
// const prospectsSignedSuccess = createAction(PROSPECTS_SIGNED_SUCCESS);
// const prospectsSignedFailed = createAction(PROSPECTS_SIGNED_FAILED);

//Teacher Prospects

const prospectsTeacherActiveRequest = createAction(PROSPECTS_TEACHER_ACTIVE_REQUEST);
const prospectsTeacherActiveSuccess = createAction(PROSPECTS_TEACHER_ACTIVE_SUCCESS);
const prospectsTeacherActiveFailed = createAction(PROSPECTS_TEACHER_ACTIVE_FAILED);

const prospectsTeacherAchivedRequest = createAction(PROSPECTS_TEACHER_ACHIVED_REQUEST);
const prospectsTeacherAchivedSuccess = createAction(PROSPECTS_TEACHER_ACHIVED_SUCCESS);
const prospectsTeacherAchivedFailed = createAction(PROSPECTS_TEACHER_ACHIVED_FAILED);

const prospectsTeacherSignedRequest = createAction(PROSPECTS_TEACHER_SIGNED_REQUEST);
const prospectsTeacherSignedSuccess = createAction(PROSPECTS_TEACHER_SIGNED_SUCCESS);
const prospectsTeacherSignedFailed = createAction(PROSPECTS_TEACHER_SIGNED_FAILED);

const getTeacherProspects = () => {
  return (dispatch) => {
    dispatch(prospectsTeacherActiveRequest());
    dispatch(prospectsTeacherAchivedRequest());
    dispatch(prospectsTeacherSignedRequest());

    apiGetTeacherActiveProspects()
      .then((response) => dispatch(prospectsTeacherActiveSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(prospectsTeacherActiveFailed(error));
      });

    apiGetTeacherArchivedProspects()
      .then((response) => dispatch(prospectsTeacherAchivedSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(prospectsTeacherAchivedFailed(error));
      });

    apiGetTeacherSignedProspects()
      .then((response) => dispatch(prospectsTeacherSignedSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(prospectsTeacherSignedFailed(error));
      });
  };
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  getTeacherProspects,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  prospectsActive: [],
  prospectsAchived: [],
  prospectsSigned: [],

  prospectsTeacherActive: [],
  prospectsTeacherAchived: [],
  prospectsTeacherSigned: [],

  errors: null,
  loading: false,
};

export default handleActions(
  {
    PROSPECTS_ACTIVE_REQUEST: (state) => {
      return { ...state, loading: true };
    },

    PROSPECTS_ACTIVE_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        prospectsActive: payload,
      };
    },

    PROSPECTS_ACTIVE_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },

    PROSPECTS_ACHIVED_REQUEST: (state) => {
      return { ...state, loading: true };
    },

    PROSPECTS_ACHIVED_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        prospectsAchived: payload,
      };
    },

    PROSPECTS_ACHIVED_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },

    PROSPECTS_SIGNED_REQUEST: (state) => {
      return { ...state, loading: true };
    },

    PROSPECTS_SIGNED_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        prospectsSigned: payload,
      };
    },

    PROSPECTS_SIGNED_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },

    PROSPECTS_TEACHER_ACTIVE_REQUEST: (state) => {
      return { ...state, loading: true };
    },

    PROSPECTS_TEACHER_ACTIVE_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        prospectsTeacherActive: payload,
      };
    },

    PROSPECTS_TEACHER_ACTIVE_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },

    PROSPECTS_TEACHER_ACHIVED_REQUEST: (state) => {
      return { ...state, loading: true };
    },

    PROSPECTS_TEACHER_ACHIVED_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        prospectsTeacherAchived: payload,
      };
    },

    PROSPECTS_TEACHER_ACHIVED_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },

    PROSPECTS_TEACHER_SIGNED_REQUEST: (state) => {
      return { ...state, loading: true };
    },

    PROSPECTS_TEACHER_SIGNED_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        prospectsTeacherSigned: payload,
      };
    },

    PROSPECTS_TEACHER_SIGNED_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },
  },
  initialState
);
