import { getErrorMessage } from "modules/process";
import React, { useEffect } from "react";

import { Form, InputNumber, Button, Row, Col, Modal } from "antd";
import { editItem as apiEditItem } from "services/formFields";
import { openNotification } from "utils/notification";

export const EditTeacherAgreement = ({ item, setVisible, visible, callback }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    form.setFieldsValue({
      field_tarif_location_teacher_pay: Number(item?.pay),
      field_tarif_teacher_percentage: Number(item?.percentage),
    });
  }, [item]);

  return (
    <Modal
      title={"Edit Agreement"}
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      centered
      // width={makeModalWider ? "85%" : "60%"}
      bodyStyle={{ padding: 5 }}
      footer={[null]}
    >
      <div className={"PaymentEdit"}>
        <Form
          style={{ padding: "0px 10px" }}
          layout="vertical"
          size="large"
          name="nest-messages"
          onFinish={async (val) => {
            setLoading(true);
            try {
              await apiEditItem({ title: "teacher_tarif", type: "teacher_tarif", ...val }, item.teacher_tarif_nid);
              if (callback) callback();
              openNotification("success", "Item edited successfully");
              setVisible(false);
            } catch (error) {
              openNotification("error", getErrorMessage(error));
            } finally {
              setLoading(false);
            }

            /*dispatch(formFieldsActions.editItem(paymentItemFields(val, paymentId), editItem.paymentId))
                .then(() => {
                  if (callback) callback();
                })
                .finally(() => {
                  setLoading(false);
                  setVisible(false);
                });*/
          }}
          form={form}
          onFinishFailed={({ errorFields }) => form.scrollToField(errorFields[0].name)}
        >
          <Row>
            {item?.percentage ||
              (item?.percentage === 0 && (
                <Col span={12}>
                  <Form.Item
                    name={["field_tarif_teacher_percentage"]}
                    label="Percentage"
                    rules={[
                      {
                        type: "number",
                        min: 0,
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                </Col>
              ))}

            {(item?.pay || item?.pay === 0) && (
              <Col span={12}>
                <Form.Item
                  name={["field_tarif_location_teacher_pay"]}
                  label="Pay"
                  rules={[
                    {
                      type: "number",
                      min: 0,
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item>
                <div className="form-button-right">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                    className={"buttonWithFloatRounded__buttons--navigation--floatRight"}
                  >
                    Submit
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default EditTeacherAgreement;
