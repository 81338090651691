import React from "react";
import { Breadcrumb } from "antd";
import { HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

export const BreadCrumb = ({ current }) => (
  <div className={"breadcrumb"} >
    <Breadcrumb>
      <Breadcrumb.Item key="home">
        <Link to="/"><HomeOutlined className={"icon icon__home"} /></Link>
      </Breadcrumb.Item>

      <Breadcrumb.Item key={"current"}><strong>{current}</strong></Breadcrumb.Item>
    </Breadcrumb>
  </div>
);

export default BreadCrumb;
