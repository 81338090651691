import formatISO from "date-fns/formatISO";

export const contractHistoryFields = (formData, status, contractId) => {
  let data = {};

  const contractHistoryInfo = formData[Object.keys(formData)[0]];

  data["type"] = [{ target_id: Object.getOwnPropertyNames(formData)[0] }];
  data["title"] = [{ value: Object.getOwnPropertyNames(formData)[0] }];
  data["field_contract_status"] = [{ value: status }];
  data["field_contract_history_nid"] = [{ target_id: contractId }];

  Object.fromEntries(
    Object.entries(contractHistoryInfo).map(([key, value]) => {
      if (value && key === "field_contract_history_dates") {
        data[key] = [{ value: formatISO(new Date(value._d)) }];
      }

      if (value && key === "field_contract_history_notes") {
        data[key] = [{ value: value }];
      }
      if (value && key === "field_contract_pause_period") {
        data[key] = [{ value: value.value }];
      }
      if (value && key === "field_cancellation_purpose") {
        data[key] = [{ value: value.value }];
      }

      return data;
    })
  );

  return data;
};
