import { getErrorMessage } from "modules/process";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { Form, InputNumber, Button, Row, Col, Modal } from "antd";
import { postItem as apiPostItem } from "services/formFields";
import { openNotification } from "utils/notification";

export const EditTeacherAvailableTarifs = ({ item, setVisible, visible, callback }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const schoolId = useSelector((state) => state?.user?.selectedSchool?.id);

  const editPay = (val) => {
    let data = {};

    data["type"] = [{ target_id: "teacher_tarif" }];
    data["title"] = [{ value: "teacher_tarif" }];
    data["field_current_location"] = [{ target_id: schoolId }];
    data["field_tarif_location_teacher_pay"] = [{ value: val.field_tarif_location_teacher_pay }];
    data["field_tarif_reference_teacher"] = [{ target_id: item?.id }];
    data["field_tarif_teacher"] = [{ target_id: item?.profile_id }];

    return data;
  };

  useEffect(() => {
    form.setFieldsValue({
      field_tarif_location_teacher_pay: Number(item?.suggested_price),
    });
  }, [item]);

  return (
    <Modal
      title={"Edit Available tarif"}
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      centered
      // width={makeModalWider ? "85%" : "60%"}
      bodyStyle={{ padding: 5 }}
      footer={[null]}
    >
      <div className={"PaymentEdit"}>
        <Form
          style={{ padding: "0px 10px" }}
          layout="vertical"
          size="large"
          name="nest-messages"
          onFinish={async (val) => {
            setLoading(true);
            try {
              await apiPostItem(editPay(val));
              if (callback) callback();
              openNotification("success", "Item edited successfully");
              setVisible(false);
            } catch (error) {
              openNotification("error", getErrorMessage(error));
            } finally {
              setLoading(false);
            }
          }}
          form={form}
          onFinishFailed={({ errorFields }) => form.scrollToField(errorFields[0].name)}
        >
          <Row>
            {(item?.suggested_price || item?.suggested_price === 0) && (
              <Col span={12}>
                <Form.Item
                  name={["field_tarif_location_teacher_pay"]}
                  label="Pay"
                  rules={[
                    {
                      type: "number",
                      min: 0,
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item>
                <div className="form-button-right">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                    className={"buttonWithFloatRounded__buttons--navigation--floatRight"}
                  >
                    Submit
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default EditTeacherAvailableTarifs;
