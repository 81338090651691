import { createAction, handleActions } from "redux-actions";
import { getStudentsContract as apiGetStudentsContract } from "services/profile";
import { openNotification } from "../utils/notification";
import { getErrorMessage } from "modules/process";

/**
 * Contracts reducer
 * Handles contracts actions
 */

// ------------------------------------
// Constants
// ------------------------------------

export const STUDENTS_PROFILE_CONTRACTS_SUCCESS = "STUDENTS_PROFILE_CONTRACTS_SUCCESS";
export const STUDENTS_PROFILE_CONTRACTS_FAILED = "STUDENTS_PROFILE_CONTRACTS_FAILED";
export const STUDENTS_PROFILE_CONTRACTS_REQUEST = "STUDENTS_PROFILE_CONTRACTS_REQUEST";
export const CANCELATION_DATE = "CANCELATION_DATE";

// ------------------------------------
// Actions
// ------------------------------------

const studentsProfileContractsSuccess = createAction(STUDENTS_PROFILE_CONTRACTS_SUCCESS);
const studentsProfileContractsFailed = createAction(STUDENTS_PROFILE_CONTRACTS_FAILED);
const studentsProfileContractsRequest = createAction(STUDENTS_PROFILE_CONTRACTS_REQUEST);

const cancelationDate = createAction(CANCELATION_DATE);

// types
export const TYPE_USER_LOAD = "user:load";

const getStudentsContract = (id) => {
  return (dispatch) => {
    dispatch(studentsProfileContractsRequest());
    apiGetStudentsContract(id)
      .then((response) => dispatch(studentsProfileContractsSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(studentsProfileContractsFailed(error));
      });
  };
};

const setCancelationDate = (date) => {
  return (dispatch) => dispatch(cancelationDate(date));
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = { getStudentsContract, setCancelationDate };

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  studentsContracts: [],
  cancelationDate: null,
  errors: null,
  loading: false,
};

export default handleActions(
  {
    STUDENTS_PROFILE_CONTRACTS_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    STUDENTS_PROFILE_CONTRACTS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        studentsContracts: payload,
        loading: false,
      };
    },

    STUDENTS_PROFILE_CONTRACTS_FAILED: (state, { payload }) => {
      return {
        ...initialState,
        errors: payload,
      };
    },

    CANCELATION_DATE: (state, { payload }) => {
      return {
        ...state,
        cancelationDate: payload,
      };
    },
  },
  initialState
);
