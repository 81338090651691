import useRoles from "hooks/useRoles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Table, Select, Modal, Form } from "antd";
import { Loader, ColorfulTag, ContractInfo } from "components";
import { format } from "date-fns";
import { contractHistoryFields } from "utils/contractHistoryFields";
import formatISO from "date-fns/formatISO";

import { actions as studentsProfileActions } from "modules/studentProfile";
import { actions as formFieldsActions } from "modules/formFields";

import PauseForm from "./PauseForm";
import CancelForm from "./CancelForm";

const { Option } = Select;

export const StudentsContracts = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);

  const [form] = Form.useForm();
  const schoolId = useSelector((state) => state?.user?.selectedSchool?.id);
  const studentsContracts = useSelector((state) => state?.studentProfile?.studentsContracts);
  const loading = useSelector((state) => state?.studentProfile?.loading);
  const [isPauseModal, setPauseModal] = useState(false);
  const [isCancelModal, setCancelModal] = useState(false);
  const [cancelContractId, setCancelContractId] = useState("");
  const [pauseContractId, setPauseContractId] = useState("");
  const { isStaff, isFranchisee } = useRoles();
  const canEdit = isStaff || isFranchisee;

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    return await dispatch(studentsProfileActions.getStudentsContract(params.profileId));
  };

  const showCancelModal = (contractId) => {
    setCancelContractId(contractId);
    setCancelModal(true);
  };

  const handleOkCancel = () => {
    setCancelModal(false);
  };

  const handleCloseCancel = () => {
    setCancelModal(false);
  };

  const showPauseModal = (contractId) => {
    setPauseContractId(contractId);
    setPauseModal(true);
  };

  const handleOkPause = () => {
    setPauseModal(false);
  };

  const handleClosePause = () => {
    setPauseModal(false);
  };

  const editStatus = (status, schoolID) => {
    let data = {};

    data["type"] = [{ target_id: "contract" }];
    data["title"] = [{ value: "contract" }];
    data["field_lesson_franchise"] = [{ target_id: schoolID }];
    data["field_contract_status"] = [{ value: status }];
    data["field_tshirt_voucher"] = [{ value: "no" }];

    return data;
  };

  const blockData = (contractId) => {
    let data = {};
    data["type"] = [{ target_id: "contract_history" }];
    data["title"] = [{ value: "contract_history" }];
    data["field_contract_status"] = [{ value: "BLOCKED" }];
    data["field_contract_history_nid"] = [{ target_id: contractId }];
    data["field_contract_history_dates"] = [{ value: formatISO(new Date()) }];
    return data;
  };

  const unBlockData = (schoolID) => {
    let data = {};
    data["type"] = [{ target_id: "contract" }];
    data["title"] = [{ value: "contract" }];
    data["field_contract_status"] = [{ value: "ACTIVE" }];
    data["field_lesson_franchise"] = [{ target_id: schoolID }];
    data["field_contract_block"] = [{ value: "" }];
    return data;
  };

  const onAction = async (typeOfAction, contractId, cancelationDate) => {
    switch (typeOfAction) {
      case "Edit":
        return history.push(`/dashboard/EditContract/${contractId}`);
      case "Billing":
        return history.push(`/dashboard/BillingManagment/${contractId}`);
      // case "Freeze":
      //   return dispatch(formFieldsActions.editItem(editStatus("FREEZE", schoolId), contractId));
      case "Block":
        setStatusChangeLoading(true);
        try {
          await dispatch(formFieldsActions.postNewItem(blockData(contractId)));
          await dispatch(studentsProfileActions.getStudentsContract(params.profileId));
        } finally {
          setStatusChangeLoading(false);
        }

        break;
      case "Unblock":
        try {
          await dispatch(formFieldsActions.editItem(unBlockData(schoolId), contractId));
          await dispatch(studentsProfileActions.getStudentsContract(params.profileId));
        } finally {
          setStatusChangeLoading(false);
        }

        break;
      case "Unfreeze":
        try {
          await dispatch(formFieldsActions.editItem(editStatus("ACTIVE", schoolId), contractId));
          await dispatch(studentsProfileActions.getStudentsContract(params.profileId));
        } finally {
          setStatusChangeLoading(false);
        }

        break;
      case "Unpause":
        try {
          await dispatch(formFieldsActions.editItem(editStatus("REACTIVATE", schoolId), contractId));
          await dispatch(studentsProfileActions.getStudentsContract(params.profileId));
        } finally {
          setStatusChangeLoading(false);
        }

        break;
      case "Cancel":
        dispatch(studentsProfileActions.setCancelationDate(cancelationDate));
        return showCancelModal(contractId);
      case "Pause":
        return showPauseModal(contractId);
      default:
        break;
    }
  };

  // title,type,date

  const studentsContractsMapped = studentsContracts?.map((student) => {
    return {
      id: student.id,
      tarif: student.tarif,
      teacherName: student.teacherName,
      instruments: student.instruments,
      contractStarting: student.startingDate,
      status: student.contractStatus,
      cancelDate: student.nextCancellationDate,
    };
  });

  const columns = [
    {
      title: "Tarif",
      dataIndex: "tarif",
      key: "tarif",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Teacher",
      dataIndex: "teacherName",
      key: "teacherName",
      render: (teacherName) => <span>{teacherName}</span>,
    },
    {
      title: "Instruments",
      dataIndex: "instruments",
      key: "instruments",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Contract Starting",
      dataIndex: "contractStarting",
      key: "contractStarting",
      render: (date) => <span>{format(new Date(date), "MMMM dd, yyyy")}</span>,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => (a.status && b.status ? a.status.localeCompare(b.status) : null),
      render: (status) => (
        <span>
          <ColorfulTag text={status} />
        </span>
      ),
    },
    {
      title: "Actions",
      render: (first, student) => (
        <Select
          disabled={statusChangeLoading}
          onChange={(actionType) => onAction(actionType, student.id, student.cancelDate)}
          style={{ width: 160, opacity: statusChangeLoading ? 0.5 : 1 }}
          placeholder="Choose an Action"
        >
          {renderOptionsBasedOnStatus(student.status)}
        </Select>
      ),
    },

    {
      title: "See",
      render: (e, { id }) => {
        return (
          <>
            <ContractInfo id={id} />
          </>
        );
      },
    },
  ];

  const columnsWithPermissions = canEdit ? columns : columns.filter((column) => column.title !== "Actions");

  const renderOptionsBasedOnStatus = (status) => {
    switch (status) {
      case "ACTIVE":
        return (
          <>
            <Option value="Edit">Edit</Option>
            <Option value="Billing">Billing</Option>
            <Option value="Pause">Pause</Option>
            <Option value="Cancel">Cancel</Option>
            <Option value="Block">Block</Option>
          </>
        );
      case "TO CANCEL":
        return (
          <>
            <Option value="Edit">Edit</Option>
            <Option value="Billing">Billing</Option>
            <Option value="Pause">Pause</Option>
            <Option value="Block">Block</Option>
            <Option value="Unpause">Reactivate</Option>
          </>
        );
      case "BLOCKED":
        return (
          <>
            <Option value="Edit">Edit</Option>
            <Option value="Billing">Billing</Option>
            <Option value="Pause">Pause</Option>
            <Option value="Cancel">Cancel</Option>
            <Option value="Unblock">Unblock</Option>
          </>
        );
      case "PAUSED":
        return (
          <>
            <Option value="Edit">Edit</Option>
            <Option value="Billing">Billing</Option>
            <Option value="Cancel">Cancel</Option>
            <Option value="Unpause">Unpause</Option>
          </>
        );
      case "CANCELED":
        return (
          <>
            <Option value="Edit">Edit</Option>
            <Option value="Billing">Billing</Option>
            <Option value="Pause">Pause</Option>
            <Option value="Block">Block</Option>
            <Option value="Unpause">Reactivate</Option>
          </>
        );
      case "FREEZE":
        return (
          <>
            <Option value="Edit">Edit</Option>
            <Option value="Billing">Billing</Option>
            <Option value="Pause">Pause</Option>
            <Option value="Block">Block</Option>
            <Option value="Unpause">Reactivate</Option>
          </>
        );
      case "NO BILL":
        return (
          <>
            <Option value="Edit">Edit</Option>
            <Option value="Billing">Billing</Option>
          </>
        );
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <div className={"contracts__table"}>
        <Table
          bordered
          columns={columnsWithPermissions}
          dataSource={studentsContractsMapped?.length > 0 ? studentsContractsMapped : []}
          pagination={false}
        />
      </div>
      <Modal footer={null} title="Pause" visible={isPauseModal} onOk={handleOkPause} onCancel={handleClosePause}>
        <Form
          form={form}
          id="pauseForm"
          style={{ padding: "0px 10px" }}
          layout="vertical"
          size="large"
          name="nest-messages"
          onFinish={async (values) => {
            try {
              setStatusChangeLoading(true);
              await dispatch(formFieldsActions.postNewItem(contractHistoryFields(values, "PAUSED", pauseContractId)));
              handleClosePause();
              await dispatch(studentsProfileActions.getStudentsContract(params.profileId));
            } finally {
              setStatusChangeLoading(false);
            }
          }}
          onFinishFailed={({ errorFields }) => form.scrollToField(errorFields[0].name)}
          scrollToFirstError
        >
          <PauseForm loading={statusChangeLoading} />
        </Form>
      </Modal>

      <Modal footer={null} title="Cancel" visible={isCancelModal} onOk={handleOkCancel} onCancel={handleCloseCancel}>
        <Form
          form={form}
          id="pauseForm"
          style={{ padding: "0px 10px" }}
          layout="vertical"
          size="large"
          name="nest-messages"
          onFinish={async (values) => {
            try {
              setStatusChangeLoading(true);
              await dispatch(
                formFieldsActions.postNewItem(contractHistoryFields(values, "TO CANCEL", cancelContractId))
              );
              handleClosePause();
              handleCloseCancel();
              await dispatch(studentsProfileActions.getStudentsContract(params.profileId));
            } finally {
              setStatusChangeLoading(false);
            }
          }}
          onFinishFailed={({ errorFields }) => form.scrollToField(errorFields[0].name)}
          scrollToFirstError
        >
          <CancelForm loading={statusChangeLoading} />
        </Form>
      </Modal>
    </div>
  );
};

export default StudentsContracts;
