import { createAction, handleActions } from "redux-actions";
import { getContractStats as apiGetContractStats } from "services/contractStats";
import { openNotification } from "../utils/notification";
import { getErrorMessage } from "modules/process";


/**
 * ContractStats reducer
 * Handles ContractStats actions
 */

// ------------------------------------
// Constants
// ------------------------------------

export const CONTRACT_STATS_SUCCESS = "CONTRACT_STATS_SUCCESS";
export const CONTRACT_STATS_FAILED = "CONTRACT_STATS_FAILED";
export const CONTRACT_STATS_REQUEST = "CONTRACT_STATS_REQUEST";

// ------------------------------------
// Actions
// ------------------------------------

const contractStatsSuccess = createAction(CONTRACT_STATS_SUCCESS);
const contractStatsFailed = createAction(CONTRACT_STATS_FAILED);
const contractStatsRequest = createAction(CONTRACT_STATS_REQUEST);

const getContractStats = () => {
  return (dispatch) => {
    dispatch(contractStatsRequest());
    apiGetContractStats()
      .then((response) => dispatch(contractStatsSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(contractStatsFailed(error));
      });
  };
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  getContractStats,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  contractStats: [],
  errors: null,
  loading: false,
};

export default handleActions(
  {
    CONTRACT_STATS_REQUEST: (state) => {
      return { ...initialState, loading: true };
    },

    CONTRACT_STATS_SUCCESS: (state, { payload }) => {
      // console.table(payload);
      return {
        ...initialState,
        loading: false,
        contractStats: payload,
      };
    },

    CONTRACT_STATS_FAILED: (state, { payload }) => {
      return {
        ...state,
        errors: payload,
      };
    },
  },
  initialState
);
