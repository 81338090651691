import axios from "axios";
import { apiURL } from "utils/config";
import { getAuthorizationHeaders } from "./auth";
import store from "../store";

const url = (id) => `${apiURL}/school/${id}/teacher`;

/**
 * Returns teacher for SelectedSchool
 * @returns {AxiosPromise}
 */

export const getTeachers = () => {
  let schoolId = store.getState().user.selectedSchool.id;
  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};
