import React from "react";
import { Typography } from "antd";

export const Title = ({ text }) => (
  <div style={{ margin: "0 10px" }}>
    <Typography.Title level={3}>{text}</Typography.Title>
  </div>
);

export default Title;
