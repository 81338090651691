import { createAction, handleActions } from "redux-actions";
import {
  getTeachersPayments as apiGetTeachersPayments,
  getTeacherPayment as apiGetTeacherPayment,
} from "services/teacherPayments";
import { format } from "date-fns";
import { openNotification } from "../utils/notification";
import { getErrorMessage } from "modules/process";

/**
 * Contracts reducer
 * Handles contracts actions
 */

// ------------------------------------
// Constants
// ------------------------------------
//All Payments
export const TEACHERS_PAYMENTS_REQUEST = "TEACHERS_PAYMENTS_REQUEST";
export const TEACHERS_PAYMENTS_SUCCESS = "TEACHERS_PAYMENTS_SUCCESS";
export const TEACHERS_PAYMENTS_FAILED = "TEACHERS_PAYMENTS_FAILED";

//Specific Payments
export const TEACHER_PAYMENT_REQUEST = "TEACHER_PAYMENT_REQUEST";
export const TEACHER_PAYMENT_SUCCESS = "TEACHER_PAYMENT_SUCCESS";
export const TEACHER_PAYMENT_FAILED = "TEACHER_PAYMENT_FAILED";
export const CHANGE_DATE = "CHANGE_DATE";
// ------------------------------------
// Actions
// ------------------------------------
//All payments
const teachersPaymentsRequest = createAction(TEACHERS_PAYMENTS_REQUEST);
const teachersPaymentsSuccess = createAction(TEACHERS_PAYMENTS_SUCCESS);
const teachersPaymentsFailed = createAction(TEACHERS_PAYMENTS_FAILED);

//Specific Payment
const teacherPaymentRequest = createAction(TEACHER_PAYMENT_REQUEST);
const teacherPaymentSuccess = createAction(TEACHER_PAYMENT_SUCCESS);
const teacherPaymentFailed = createAction(TEACHER_PAYMENT_FAILED);
const changeDate = createAction(CHANGE_DATE);
// types
export const TYPE_USER_LOAD = "user:load";

const getTeachersPayments = () => {
  return (dispatch) => {
    dispatch(teachersPaymentsRequest());
    apiGetTeachersPayments()
      .then((response) => dispatch(teachersPaymentsSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(teachersPaymentsFailed(error));
      });
  };
};

const getTeacherPayment = (id) => {
  return (dispatch) => {
    dispatch(teacherPaymentRequest());
    apiGetTeacherPayment(id)
      .then((response) => dispatch(teacherPaymentSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(teacherPaymentFailed(error));
      });
  };
};

const getTeachersPaymentWithDate = (year, month) => {
  return (dispatch) => {
    dispatch(changeDate(year, month));
    dispatch(getTeachersPayments());
  };
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  getTeachersPayments,
  getTeacherPayment,
  getTeachersPaymentWithDate,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  year: format(new Date(), "yyyy"),
  month: format(new Date(), "MM"),
  teachersPayments: [],
  errors: null,
  loading: false,
  payment: {},
  paymentLoading: false,
};

export default handleActions(
  {
    TEACHERS_PAYMENTS_REQUEST: (state) => {
      return { ...state, loading: true, teachersPayments: [] };
    },

    TEACHERS_PAYMENTS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        teachersPayments: payload,
        loading: false,
      };
    },

    TEACHERS_PAYMENTS_FAILED: (state, { payload }) => {
      return {
        ...initialState,
        errors: payload,
      };
    },

    TEACHER_PAYMENT_REQUEST: (state, { payload }) => {
      return {
        ...state,
        payment: {},
        paymentLoading: true,
      };
    },

    TEACHER_PAYMENT_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        payment: payload,
        paymentLoading: false,
      };
    },

    TEACHER_PAYMENT_FAILED: (state, { payload }) => {
      return {
        ...initialState,
        errors: payload,
      };
    },

    CHANGE_DATE: (state, { payload }) => {
      return { ...state, year: payload.year, month: payload.month };
    },
  },
  initialState
);
