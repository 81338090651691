import axios from "axios";
import { apiURL } from "utils/config";
import { getAuthorizationHeaders } from "./auth";
import store from "../store";

/**
 * Returns Billing Managment for contracts
 */

export const getBillingManagment = (contractId, billingType) => {
  let schoolId = store.getState().user.selectedSchool.id;
  if (billingType) {
    const url = (schoolID, contractID) => `${apiURL}/school/${schoolID}/contract/${contractID}/?type=kk_contract`;
    return axios({
      url: url(schoolId, contractId),
      method: "GET",
      headers: getAuthorizationHeaders(),
    });
  } else {
    const url = (schoolID, contractID) => `${apiURL}/school/${schoolID}/contract/${contractID}`;
    return axios({
      url: url(schoolId, contractId),
      method: "GET",
      headers: getAuthorizationHeaders(),
    });
  }
};
