import axios from "axios";
import { apiURL } from "utils/config";
import { getAuthorizationHeaders } from "./auth";
import store from "../store";

/**
 * Returns Contract Info
 * @returns {AxiosPromise}
 * */

export const getContractInfo = (contractID) => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (schoolId) => `${apiURL}/school/${schoolId}/contract/${contractID}`;
  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};
