import React, { useEffect} from "react";
import { useLocation, useParams  } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { actions as prospectHistoryActions } from "modules/prospectHistory";
import "./prospectHistory.scss";
import "routes/Dashboard/ProspectProfile/prospectProfile.scss";

export const ProspectHistory = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const profileId = params.profileId;
  
  useEffect(() => {
    dispatch(prospectHistoryActions.getProspectHistory(profileId));
    // eslint-disable-next-line
  }, [location]);

  const  interview  = useSelector((state) => state?.prospectHistory?.prospectHistoryInfo?.prospectInterview);

  const loading = useSelector((state) => state?.prospectHistory?.loading);
  const { firstName, lastName, status, instruments, gender } = useSelector(
    (state) => state?.prospectHistory?.prospectHistoryInfo
  );

  return loading ? (
    <></>
  ) : (
    <div>
      <div className={"prospectHistory__item"}>
        Prospect details: <span className={"prospectHistory__item--highlighted"}>{firstName} {lastName} / {status} / {gender} / {instruments ? instruments : "--"}</span>
      </div>
      <div className={"prospectHistory__title"}>Prospect Interview:</div>
      <div className={"interview"}>
      
      {interview ? interview.map(({question, answer}) => {
        return (
          <>
            <div className={"interview__question"}>{question}</div>
        
            {answer ? (
              typeof answer === "string" ? (
                <div className={"interview__answer__normal"}>{answer}</div>
              ) : (
                <div className={"interview__answer__array"}>
                  {answer.length !== 0 ? (
                    answer.toString()
                  ) : (
                    <div>" "</div>
                  )}
                </div>
              )
            ) : (
              <div className={"interview__answer__normal"}> " "</div>
            )}
          </>
        );
      }) : null }
    </div>
    </div>
  );
};

export default ProspectHistory;