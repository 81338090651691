import React from "react";
import { Card } from "antd";
import { ResponsiveContainer } from "recharts";

export const CardChart = ({ heading, chart, cardHeight, chartHeight, extraContent }) => (
  <Card style={{ height: cardHeight ? cardHeight : "265px" }} className={"cardChart"}>
    <div>
      <p className={"cardChart--heading"}>{heading}</p>
    </div>
    <div style={{ width: "100%", height: chartHeight ? chartHeight : 180 }}>
      <ResponsiveContainer>{chart}</ResponsiveContainer>
    </div>

    {extraContent ? extraContent : null}
  </Card>
);

export default CardChart;
