/**
 * Account reducer
 * Handles login/logout actions
 */

import { createAction, handleActions } from "redux-actions";
import { login as apiLogin, setAuthInfo, clearAuthInfo } from "services/auth";
import { actions as processActions } from "modules/process";
import { actions as userActions } from "modules/user";

// ------------------------------------
// Constants
// ------------------------------------
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// ------------------------------------
// Actions
// ------------------------------------
const loginLoading = createAction(LOGIN_LOADING);
const loginSuccess = createAction(LOGIN_SUCCESS);
const logoutSuccess = createAction(LOGOUT_SUCCESS);
const loginFailure = createAction(LOGIN_FAILURE);

/**
 * Login
 * Login and dispatch user load
 * @param username
 * @param password
 * @returns {function(*): *}
 */
const login = (username, password) => {
  return (dispatch) => {
    dispatch(loginLoading());

    dispatch(
      processActions.processLoading({
        type: "login",
      })
    );

    return apiLogin(username, password).then(
      (response) => {
        setAuthInfo({
          ...response.data,
        });

        //dispatch(userActions.userSuccess(response.data.user));
        dispatch(loginSuccess({ auth: true }));

        dispatch(
          processActions.processSuccess({
            type: "login",
          })
        );

        return dispatch(userActions.getUser());
      },
      (err) => {
        dispatch(
          processActions.processFailure({
            type: "login",
            message: err,
          })
        );
        return dispatch(loginFailure);
      }
    );
  };
};

/**
 * Logout
 * @returns {function(*): *}
 */
const logout = () => {
  return (dispatch) => {
    dispatch(loginLoading(true));
    dispatch(userActions.clearUser());
    clearAuthInfo();
    return dispatch(logoutSuccess());
  };
};

export const actions = {
  login,
  logout,
  loginLoading,
  loginFailure,
  logoutSuccess,
  loginSuccess,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isLoggedIn: false,
  loading: false,
  errors: null,
  data: {},
};

export default handleActions(
  {
    LOGOUT_SUCCESS: () => {
      return {
        ...initialState,
      };
    },

    LOGIN_LOADING: (state) => {
      return {
        ...state,
        loading: !state.loading,
        errors: null,
      };
    },

    LOGIN_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        auth: true,
        isLoggedIn: true,
        loading: false,
        data: payload,
        errors: null,
      };
    },

    LOGIN_FAILURE: (state, { payload }) => {
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
        errors: payload,
      };
    },
  },
  initialState
);
