import React from "react";
import { Comment, List } from "antd";
import { format, fromUnixTime } from "date-fns";
import { CommentAvatar } from "utils/constants";

export const CommentList = ({ comments }) => (
  <>
    <List
      dataSource={comments}
      header={`${comments.length} ${comments.length > 1 ? "replies" : "reply"}`}
      itemLayout="horizontal"
      renderItem={({ user, body, created }) => (
        <li>
          <Comment
            author={user?.name}
            avatar={CommentAvatar}
            content={body === undefined ? body : <pre>{body}</pre>}
            datetime={format(new Date(fromUnixTime(created)), "dd MMM yyyy, HH:mm")}
          />
        </li>
      )}
    />
  </>
);

export default CommentList;
