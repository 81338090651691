import React from "react";
import { useSelector } from "react-redux";
import { Route, useHistory } from "react-router-dom";

export const AuthenticatedRoute = (props) => {
  const history = useHistory();
  const user = useSelector((state) => state.user);

  React.useEffect(() => {
    if (!user.id) {
      history.push("/account/login");
    }
  }, [user.id, history]);
  return <Route {...props} />;
};

export default AuthenticatedRoute;
