import { createAction, handleActions } from "redux-actions";
import {
  getContracts as apiGetContracts,
  getContractsWithFilter as apiGetContractsWithFilter,
  getExpiredProfiles as apiGetExpiredProfiles,
} from "services/contracts";
import { openNotification } from "utils/notification";
import { getErrorMessage } from "modules/process";

/**
 * Contracts reducer
 * Handles contracts actions
 */

// ------------------------------------
// Constants
// ------------------------------------

export const CONTRACTS_LOADED = "CONTRACTS_LOADED";
export const CONTRACTS_REQUEST = "CONTRACTS_REQUEST";
export const CONTRACTS_SUCCESS = "CONTRACTS_SUCCESS";
export const CONTRACTS_FAILED = "CONTRACTS_FAILED";

export const CONTRACTS_WITH_FILTER_REQUEST = "CONTRACTS_WITH_FILTER_REQUEST";
export const CONTRACTS_WITH_FILTER_SUCCESS = "CONTRACTS_WITH_FILTER_SUCCESS";
export const CONTRACTS_WITH_FILTER_FAILED = "CONTRACTS_WITH_FILTER_FAILED";

// ------------------------------------
// Actions
// ------------------------------------

const contractsLoaded = createAction(CONTRACTS_LOADED);
const contractsSuccess = createAction(CONTRACTS_SUCCESS);
const contractsFailed = createAction(CONTRACTS_FAILED);
const contractsRequest = createAction(CONTRACTS_REQUEST);

const contractsWithFilterSuccess = createAction(CONTRACTS_WITH_FILTER_SUCCESS);
const contractsWithFilterFailed = createAction(CONTRACTS_WITH_FILTER_FAILED);
const contractsWithFilterRequest = createAction(CONTRACTS_WITH_FILTER_REQUEST);

// types
export const TYPE_USER_LOAD = "user:load";

const getContracts = () => {
  return (dispatch) => {
    dispatch(contractsRequest());
    apiGetContracts()
      .then((response) => dispatch(contractsSuccess(response.data)))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(contractsFailed(error));
      });
  };
};

const getContractsWithFilter = (query) => {
  return (dispatch) => {
    dispatch(contractsWithFilterRequest());
    apiGetContractsWithFilter(query)
      .then((response) => dispatch(contractsWithFilterSuccess({ name: query, contracts: response.data })))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(contractsWithFilterFailed(error));
      });
  };
};

const getExpiredContracts = () => {
  return (dispatch) => {
    dispatch(contractsRequest());
    apiGetExpiredProfiles()
      .then((response) => dispatch(contractsWithFilterSuccess({ name: "expired", contracts: response.data })))
      .catch((error) => {
        openNotification("error", getErrorMessage(error));
        return dispatch(contractsFailed(error));
      });
  };
};

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  contractsLoaded,
  getContracts,
  getContractsWithFilter,
  getExpiredContracts,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  students: null,
  errors: null,
  loading: false,
  contractFilterLoading: false,
};

export default handleActions(
  {
    CONTRACTS_LOADED: (state) => {
      return {
        ...state,
      };
    },

    CONTRACTS_REQUEST: (state) => {
      return { ...state, loading: true };
    },

    CONTRACTS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        students: payload,
        loading: false,
      };
    },

    CONTRACTS_FAILED: (state, { payload }) => {
      return {
        ...initialState,
        errors: payload,
      };
    },

    CONTRACTS_WITH_FILTER_REQUEST: (state) => {
      return { ...state, contractFilterLoading: true };
    },

    CONTRACTS_WITH_FILTER_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        [payload.name]: payload.contracts,
        contractFilterLoading: false,
      };
    },

    CONTRACTS_WITH_FILTER_FAILED: (state, { payload }) => {
      return {
        ...initialState,
        errors: payload,
      };
    },
  },
  initialState
);
