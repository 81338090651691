import { Loader } from "components/index";
import { getErrorMessage } from "modules/process";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "antd";
import { format } from "date-fns";
import "styles/_global.scss";
import { getTeacherPayments as apiGetTeacherPayments } from "services/profile";
import { openNotification } from "utils/notification";
import { formatNumber } from "utils/utils";
import { actions as teachersPaymentsActions } from "modules/teachersPayments";
import { InfoCircleOutlined } from "@ant-design/icons";

const TeacherPayment = ({ profileId }) => {
  const dispatch = useDispatch();
  //const teacherPayments = useSelector((state) => state?.teachersProfile?.teacherPayments);
  const schooId = useSelector((state) => state?.user?.selectedSchool?.id);
  const [loading, setLoading] = React.useState(false);
  const [teacherPayments, setTeacherPayments] = React.useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await apiGetTeacherPayments(profileId);
      setTeacherPayments(data?.map((item, index) => ({ ...item, key: index })));
    } catch (error) {
      openNotification("error", getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [profileId]);

  const teacherPaymentsCol = [
    {
      title: "Payment ID (System)",
      dataIndex: "paymentID",
      key: "paymentID",
      width: 200,
      render: (paymentID) => (
        <Link
          data-id={paymentID}
          to={`/dashboard/${schooId}/teacherPayment/${paymentID}`}
          onClick={() => {
            dispatch(teachersPaymentsActions.getTeacherPayment(paymentID));
          }}
        >
          <InfoCircleOutlined style={{ fontSize: "2em", width: "100%" }} />
        </Link>
      ),
    },

    {
      title: "Month Of Payment",
      dataIndex: "monthOfPayment",
      key: "monthOfPayment",
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a?.monthOfPayment).getTime() - new Date(b?.monthOfPayment).getTime(),
      render: (monthOfPayment) => <span>{format(new Date(monthOfPayment), "MMMM yyyy")}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => (a.amount && b.amount ? a.amount.localeCompare(b.amount, undefined, { numeric: true }) : null),
      render: (amount) => <span className={"amount"}>{formatNumber(amount)}€</span>,
    },
  ];

  if (loading) return <Loader />;

  return <Table pagination={false} columns={teacherPaymentsCol} dataSource={teacherPayments} />;
};

export default TeacherPayment;
