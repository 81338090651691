import { createAction, handleActions } from "redux-actions";

/**
 * RoomPlan reducer
 * Handles Roomplan actions
 */

// ------------------------------------
// Constants
// ------------------------------------

export const EVENT_DETAILS = "EVENT_DETAILS";
export const ROOMPLAN_FORM_SELECTED = "ROOMPLAN_FORM_SELECTED";
export const SET_SLOT_TIMES = "SET_SLOT_TIMES";
export const CLEAR_ROOMPLAN_FORM = "CLEAR_ROOMPLAN_FORM";
export const SET_CURRENT_ROOM = "SET_CURRENT_ROOM";
export const SET_CURRENT_DATE = "SET_CURRENT_DATE";

// ------------------------------------
// Actions
// ------------------------------------

const eventDetails = createAction(EVENT_DETAILS);
const roomplanFormSelected = createAction(ROOMPLAN_FORM_SELECTED);
const setSlotTimes = createAction(SET_SLOT_TIMES);
const clearRoomplanForm = createAction(CLEAR_ROOMPLAN_FORM);
const setCurrentRoom = createAction(SET_CURRENT_ROOM);
const setCurrentDate = createAction(SET_CURRENT_DATE);

// ------------------------------------
// All actions
// ------------------------------------

export const actions = {
  eventDetails,
  roomplanFormSelected,
  setSlotTimes,
  clearRoomplanForm,
  setCurrentRoom,
  setCurrentDate,
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  events: [],
  errors: null,
  loading: false,
  selectedEvent: null,
  roomplanForm: null,
  slotStart: null,
  slotEnd: null,
  currentRoom: {},
  currentDate: new Date(),
};

export default handleActions(
  {
    LOGOUT_SUCCESS: () => {
      return {
        ...initialState,
      };
    },
    SET_CURRENT_DATE: (state, { payload }) => {
      return { ...state, currentDate: new Date(payload) };
    },
    SET_SLOT_TIMES: (state, { payload }) => {
      return { ...state, slotStart: payload.start, slotEnd: payload.end };
    },
    CLEAR_ROOMPLAN_FORM: (state) => {
      return { ...state, roomplanForm: null };
    },
    ROOMPLAN_FORM_SELECTED: (state, { payload }) => {
      return { ...state, roomplanForm: payload };
    },
    EVENT_DETAILS: (state, { payload }) => {
      return { ...state, selectedEvent: payload };
    },
    SET_CURRENT_ROOM: (state, { payload }) => {
      return { ...state, currentRoom: payload };
    },
  },
  initialState
);
