import React from "react";
import { withRouter } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

export const BackButton = ({ history }) => (
  <div onClick={() => history.goBack()}>
    <ArrowLeftOutlined
      style={{ backgroundColor: "#D9363E", padding: 5, margin: "5px 0 2px 0", borderRadius: "100%", color: "white" }}
    />
  </div>
);

export default withRouter(BackButton);
