import axios from "axios";
import { apiURL, baseURL } from "utils/config";
import { getAuthorizationHeaders } from "./auth";
import store from "../store";

/**
 * Returns teacher for SelectedSchool
 * @returns {AxiosPromise}
 */

export const getTeachersPayments = () => {
  let schoolId = store.getState().user.selectedSchool.id;
  let year = store.getState().teachersPayments.year;
  let month = store.getState().teachersPayments.month;
  const url = (id) => `${apiURL}/school/${id}/payment?date=${year}-${month}`;
  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const getTeacherPayment = (paymentId) => {
  let schoolId = store.getState().user.selectedSchool.id;
  const url = (id) => `${apiURL}/school/${id}/payment/${paymentId}`;
  return axios({
    url: url(schoolId),
    method: "GET",
    headers: getAuthorizationHeaders(),
  });
};

export const recalculateAmount = (paymentId) => {
  return axios({
    url: `${baseURL}/api/v1/teacherPayments`,
    method: "POST",
    data: { teacher_payment_id: paymentId },
    headers: getAuthorizationHeaders(),
  });
};
